import { getAuth, updateProfile } from 'firebase/auth';
import {
  doc,
  query,
  collection,
  where,
  addDoc,
  getDoc,
  getDocs,
  updateDoc
} from 'firebase/firestore';

import { getDB } from 'firebaseUtil/db';

const newUserProfile = {
  fullName: '',
  phoneNumber: '',
  sex: -1,
  business: {}
};

const newBusinessProfile = {
  businessName: '',
  phoneNumber: '',
  registrationNumber: '',
  website: ''
};

let __userId;

export async function initProfile(userId) {
  __userId = userId;

  const db = getDB();
  const profileRef = collection(db, 'userProfile');
  const businessRef = collection(db, 'businessProfile');
  try {
    const q = query(profileRef, where('userId', 'array-contains', __userId));
    const querySnapshot = await getDocs(q);
    let userProfile;
    let userProfileId;
    let businessProfileId;
    if (!querySnapshot.empty) {
      userProfileId = querySnapshot.docs[0].id;

      const { business, ...rest } = querySnapshot.docs[0].data();
      userProfile = rest;

      // TODO: righ now, we always use the first business profile
      businessProfileId = Object.keys(business).at(0);
    } else {
      const ref = await addDoc(profileRef, { ...newUserProfile, userId: [userId, 'superuser'] });
      userProfileId = ref.id;
      userProfile = { ...newUserProfile };
    }

    if (!businessProfileId) {
      const ref = await addDoc(businessRef, { ...newBusinessProfile, userId: [userId, 'superuser'] });
      businessProfileId = ref.id;

      const userDocRef = doc(db, 'userProfile', userProfileId);
      await updateDoc(userDocRef, { [`business.${businessProfileId}`]: true });
    }

    const businessDocSnap = await getDoc(doc(db, 'businessProfile', businessProfileId));
    const businessProfile = businessDocSnap.data();

    const { phoneNumber: personalPhoneNo, ...restUserProfile } = userProfile;
    const { phoneNumber: businessPhoneNo, ...restBusinessProfile } = businessProfile;
    return {
      userId,
      userProfileId,
      personalPhoneNo,
      businessProfileId,
      businessPhoneNo,
      ...restUserProfile,
      ...restBusinessProfile
    };
  } catch (err) {
    console.error('error init profile:', err);
    return {
      profileError: 30001
    };
  }
}

export function reset() {
  __userId = undefined;
}

export async function updatePersonalProfile(
  userProfileId,
  fullName,
  sex,
  phoneNumber,
  nickname,
  userCallback
) {
  try {
    const db = getDB();
    const docRef = doc(db, 'userProfile', userProfileId);
    await updateDoc(docRef, {
      sex,
      fullName: fullName ?? '',
      phoneNumber: phoneNumber ?? ''
    });

    if (nickname) {
      const auth = getAuth();
      await updateProfile(auth.currentUser, { displayName: nickname });
      if (userCallback) {
        userCallback(nickname);
      }
    }

    return {
      sex,
      fullName: fullName ?? '',
      personalPhoneNo: phoneNumber ?? '',
      profileError: 0
    };
  } catch (err) {
    console.error('error update personal profile:', err);
    return {
      profileError: 30002
    };
  }
}

export async function updateBusinessProfile(
  businessProfileId,
  businessName,
  registrationNumber,
  phoneNumber,
  website
) {
  try {
    const db = getDB();
    const docRef = doc(db, 'businessProfile', businessProfileId);
    const data = {
      businessName: businessName ?? '',
      registrationNumber: registrationNumber ?? '',
      phoneNumber: phoneNumber ?? '',
      website: website ?? ''
    };

    await updateDoc(docRef, data);
    return { profileError: 0, ...data };
  } catch (err) {
    console.error('error update business profile:', err);
    return {
      profileError: 30004
    };
  }
}
