import * as React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu } from '@mui/material';
import { Add as AddIcon, MoreHoriz as MoreIcon } from '@mui/icons-material';

import { IconMenuItem } from 'components/inputs/buttons';

const StockDetailHeaderMenu = ({ onAddVariation, onAddTransaction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        color="primary"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreIcon />
      </IconButton>
      <Menu disableScrollLock open={!!anchorEl} anchorEl={anchorEl} onClose={onCloseMenu}>
        <IconMenuItem
          label="Tambah Variasi"
          icon={AddIcon}
          onClick={(e) => {
            e.stopPropagation();
            onCloseMenu();
            onAddVariation();
          }}
        />
        <IconMenuItem
          noDivider
          label="Tambah Transaksi"
          icon={AddIcon}
          onClick={(e) => {
            e.stopPropagation();
            onCloseMenu();
            onAddTransaction();
          }}
        />
      </Menu>
    </>
  );
};

StockDetailHeaderMenu.propTypes = {
  onAddVariation: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired
};

export default StockDetailHeaderMenu;
