import * as React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Box, Menu, Typography } from '@mui/material';
import { Delete, Download, Edit } from '@mui/icons-material';

import { EnhancedTable } from 'components/data-display/table';
import { IconMenuItem } from 'components/inputs/buttons';

import { downloadDocument } from 'stux/data-managers/stockCard';

import ComfirmDeleteDialog from './ComfirmDeleteDialog';

const formatter = new Intl.NumberFormat('en-MY', { useGrouping: 'always', minimumFractionDigits: 2 });
const getCurrency = (val) => (
  val !== '' ? formatter.format(val) : ''
);

const getClassname = (val) => (
  Math.sign(val) === -1 ? 'negativeValue' : ''
);

const tableProps = {
  sx: {
    '& .negativeValue': {
      color: 'error.light'
    },
    '& .balance': {
      fontWeight: 'bold'
    },
    '& .summary': {
      background: 'whitesmoke !important'
    }
  }
};

const TransactionDataGrid = ({ data, onChangeTransaction, onDeleteTransaction }) => {
  const stockData = React.useRef(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openConfirmDelete, setOpenComfirmDelete] = React.useState(false);
  const columns = React.useRef([
    {
      rowRefPropName: 'id',
      prop: 'transactionDate',
      label: 'Tarikh',
      align: 'left',
      getValue: (val) => (val ? format(new Date(val), 'dd/MM/yyyy') : '')
    },
    { rowRefPropName: 'id', prop: 'transaction', label: 'Transaksi', align: 'left' },
    {
      rowRefPropName: 'id',
      prop: 'document',
      label: 'Dokumen',
      align: 'left',
      getClassnameFromValue: (val) => (val !== 'Baki' ? 'undefined' : 'balance')
    },
    {
      rowRefPropName: 'id',
      prop: 'quantity',
      label: 'Kuantiti',
      align: 'right',
      getClassnameFromValue: getClassname
    },
    {
      rowRefPropName: 'id',
      prop: 'unitPrice',
      label: 'Harga Seunit (RM)',
      align: 'right',
      getClassnameFromValue: getClassname,
      getValue: getCurrency
    },
    {
      rowRefPropName: 'id',
      prop: 'total',
      label: 'Jumlah (RM)',
      align: 'right',
      getClassnameFromValue: getClassname,
      getValue: getCurrency
    },
    {
      rowRefPropName: 'id',
      prop: 'balance',
      label: 'Baki (RM)',
      align: 'right',
      getClassnameFromValue: getClassname,
      getValue: getCurrency
    }
  ]);

  const onCloseMenu = (keepData = false) => {
    if (!keepData) stockData.current = undefined;
    setAnchorEl(null);
  };

  const getRowClassNameFromData = React.useCallback((val) => (
    val.document === 'Baki' ? 'summary' : undefined
  ), []);

  const onClickStartAction = React.useCallback((e, value) => {
    stockData.current = value;
    setAnchorEl(e.currentTarget);
  }, []);

  return (
    <>
      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{ mt: 0.5 }}
      >
        Senarai Transaksi
      </Typography>
      {
        !data?.length ? (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography>Tiada senarai transaksi untuk dipaparkan</Typography>
          </Box>
        ) : (
          <>
            <Box sx={{ maxWidth: 'calc(100vw - 32px)' }}>
              <EnhancedTable
                pinStart
                stickyHeader
                noWrapHeaderText
                alternateColor="#edf4ff"
                rows={data}
                columns={columns.current}
                tableProps={tableProps}
                totalExcludedRowFromPagination={1}
                getRowClassNameFromData={getRowClassNameFromData}
                onClickStartAction={onClickStartAction}
              />
            </Box>
            <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onCloseMenu} sx={{ py: 0 }}>
              <IconMenuItem
                label="Pinda Maklumat Transaksi"
                icon={Edit}
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeTransaction(stockData.current?.realData);
                  onCloseMenu();
                }}
              />
              {
                stockData.current?.realData?.documentHash && (
                  <IconMenuItem
                    label="Muat Turun Dokumen"
                    icon={Download}
                    sx={{ py: 1 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      const docName = stockData.current?.realData?.documentName;
                      if (docName) {
                        downloadDocument(docName);
                      }
                      onCloseMenu();
                    }}
                  />
                )
              }
              <IconMenuItem
                noDivider
                label="Hapus Transaksi"
                icon={Delete}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenComfirmDelete(true);
                  onCloseMenu(true);
                }}
              />
            </Menu>
            <ComfirmDeleteDialog
              open={openConfirmDelete}
              type={stockData.current?.transaction ?? ''}
              date={stockData.current?.transactionDate ?? 0}
              onOk={() => {
                onDeleteTransaction({ ...stockData.current.realData });
                setOpenComfirmDelete(false);
                stockData.current = undefined;
              }}
              onCancel={() => {
                setOpenComfirmDelete(false);
                stockData.current = undefined;
              }}
            />
          </>
        )
      }
    </>
  );
};

TransactionDataGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onDeleteTransaction: PropTypes.func.isRequired,
  onChangeTransaction: PropTypes.func.isRequired
};

TransactionDataGrid.defaultProps = {
  data: []
};

export default TransactionDataGrid;
