import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

export default function useAuthentication(requestedLocation) {
  const isMounted = React.useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isIdentified, isAuthenticated } = useStux(UserStore, 'isIdentified', 'isAuthenticated');

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (isMounted.current && isIdentified && isAuthenticated) {
      const from = requestedLocation || location.state?.from?.pathname || '/';
      navigate(from, { replace: false });
    }
  }, [isIdentified, isAuthenticated]);

  return isAuthenticated;
}

// import * as React from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';

// import UserStore from 'stux/stores/UserStore';

// // TODO: define valid path
// const validPaths = ['/products', '/profile'];
// const defaultPath = { unauthenticatedPath: '/login', authenticatedPath: '/' };

// export default function useAuthentication(options = defaultPath) {
//   const isMounted = React.useRef(false);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [isAuthenticated, setIsAuthenticated] = React.useState(UserStore.state.isAuthenticated);

//   React.useEffect(() => {
//     const onStateUpdate = () => {
//       if (isMounted.current) {
//         setIsAuthenticated(UserStore.state.isAuthenticated);
//       }
//     };

//     UserStore.on(UserStore.STATE_UPDATE, onStateUpdate);
//     isMounted.current = true;

//     return () => {
//       isMounted.current = false;
//       UserStore.off(UserStore.STATE_UPDATE, onStateUpdate);
//     };
//   }, []);

//   React.useEffect(() => {
//     if (isMounted.current) {
//       const { pathname } = location;
//       const {
//         authenticatedPath = defaultPath.authenticatedPath,
//         unauthenticatedPath = defaultPath.unauthenticatedPath
//       } = options;
//       const hasValidPath = validPaths.some((path) => pathname.includes(path));
//       const loc = hasValidPath ? pathname : authenticatedPath;
//       navigate(isAuthenticated ? loc : unauthenticatedPath, { replace: true });
//     }
//   }, [isAuthenticated]);

//   return isAuthenticated;
// }
