import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  CircularProgress
} from '@mui/material';

import { Slide } from 'components/transitions';

const CommonDialog = ({
  title,
  subtitle,
  cancelLabel,
  saveLabel,
  thirdActionLabel,
  formId,
  contentSX,
  isSubmitting,
  onClose,
  onSave,
  onThirdAction,
  children,
  ...props
}) => (
  <Dialog
    {...props}
    TransitionComponent={Slide}
    onClose={!isSubmitting ? onClose : undefined}
  >
    <DialogTitle>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {title}
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
    </DialogTitle>
    <DialogContent sx={contentSX}>
      {children}
    </DialogContent>
    <DialogActions sx={{ justifyContent: 'flex-end' }}>
      {
        !isSubmitting && (
          <>
            {
              !!onThirdAction && (
                <Button color="error" sx={{ position: 'absolute', left: 8 }} onClick={onThirdAction}>
                  <strong>{thirdActionLabel}</strong>
                </Button>
              )
            }
            <Button color="error" onClick={onClose}>
              <strong>{cancelLabel}</strong>
            </Button>
          </>
        )
      }
      <Button
        type="submit"
        form={formId}
        variant={isSubmitting ? 'outlined' : 'text'}
        sx={{ pointerEvents: isSubmitting ? 'none' : 'auto' }}
        onClick={onSave}
      >
        <strong style={{ visibility: !isSubmitting ? 'visible' : 'hidden' }}>{saveLabel}</strong>
        {isSubmitting && <CircularProgress color="inherit" sx={{ position: 'absolute' }} size={24} />}
      </Button>
    </DialogActions>
  </Dialog>
);

CommonDialog.propTypes = {
  ...Dialog.propTypes,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  saveLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  thirdActionLabel: PropTypes.string,
  formId: PropTypes.string,
  contentSX: Dialog.propTypes.sx,
  isSubmitting: PropTypes.bool,
  onSave: PropTypes.func,
  onThirdAction: PropTypes.func
};

CommonDialog.defaultProps = {
  saveLabel: 'Simpan',
  cancelLabel: 'Batal',
  thirdActionLabel: '',
  subtitle: undefined,
  formId: undefined,
  contentSX: undefined,
  isSubmitting: false,
  onSave: undefined,
  onThirdAction: undefined
};

export default CommonDialog;
