import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import UserStore from 'stux/stores/UserStore';
import { getStockDetail, initStockDetail } from 'stux/data-managers/stockDetail';
import { initStockSummaryData } from 'stux/data-managers/stockSummary';
import { getStockGroupList, initStockGroupData } from 'stux/data-managers/stockGroup';

import StockDetailHeader from './header/StockDetailHeader';
import StockVariant from './stock-variant/StockVariant';
import StockDataGrid from './stok-data/StockDataGrid';
import { ErrorPanel } from '../common';

const StockDetailPage = () => {
  const navigate = useNavigate();
  const header = React.useRef(null);
  const { stockListId } = useParams();
  const [isReady, setIsReady] = React.useState(false);
  const [filteredStockCards, setFilteredStockCard] = React.useState([]);
  const selectedVariants = React.useRef([]);

  const { data: stockGroups } = useQuery(
    ['stockGroup', UserStore.state.userId],
    getStockGroupList,
    { enabled: isReady }
  );

  const { data, error, isLoading, isError } = useQuery(
    ['stockDetail', UserStore.state.userId, stockListId],
    getStockDetail,
    { enabled: isReady }
  );

  React.useEffect(() => {
    if (UserStore.state.isAuthenticated) {
      initStockGroupData(UserStore.state.userId);
      initStockDetail(UserStore.state.userId, stockListId);
      initStockSummaryData(UserStore.state.userId);
      setIsReady(true);
    }
  }, []);

  const filterStockCards = () => {
    if (!data) return;

    const { stockCards } = data;
    if (!selectedVariants.current.length) {
      setFilteredStockCard(stockCards);
      return;
    }

    const newStockCards = [];
    const variantIds = selectedVariants.current;
    stockCards.forEach((stockCard) => {
      const { variationCode } = stockCard;
      if (variantIds.some((id) => variationCode.includes(`"${id}"`))) {
        newStockCards.push(stockCard);
      }
    });
    setFilteredStockCard(newStockCards);
  };

  React.useEffect(() => {
    filterStockCards();
  }, [data]);

  const onChangeVariants = (variantId) => {
    const index = selectedVariants.current.indexOf(variantId);
    if (index === -1) {
      selectedVariants.current.push(variantId);
    } else {
      selectedVariants.current.splice(index, 1);
    }
    filterStockCards();
  };

  return (
    <Stack spacing={1.5} sx={{ height: '100%' }}>
      <StockDetailHeader
        ref={header}
        stockListId={stockListId}
        stockCards={data?.stockCards ?? []}
        variations={data?.variations}
        daysBeforeExpired={data?.stockData?.daysBeforeExpired}
        title={data?.stockData?.stockName}
        stockGroup={stockGroups?.find((val) => val.id === data?.stockData?.stockGroupId)?.label}
      />
      {
        isError && (
          <ErrorPanel
            message={error?.message === 'No such record' ? 'Stok tidak wujud' : 'Ralat ketika mengakses stok'}
          />
        )
      }
      {
        !isError && (
          <>
            <StockVariant
              isLoading={isLoading}
              data={data?.variations ?? []}
              onChangeVariants={onChangeVariants}
              onClickStart={() => header.current.openAddVariation()}
            />
            <StockDataGrid
              isLoading={isLoading}
              stockCards={filteredStockCards}
              variations={data?.variations}
              daysBeforeExpired={data?.stockData?.daysBeforeExpired ?? 0}
              unitBeforeRestock={data?.stockData?.unitBeforeRestock}
              onOpenStockCard={(stockCardId) => {
                navigate(`/stocks/${stockListId}/${stockCardId}`);
              }}
            />
          </>
        )
      }
    </Stack>
  );
};

export default StockDetailPage;
