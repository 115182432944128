import * as React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Menu, Typography } from '@mui/material';
import { Add, ReceiptLong } from '@mui/icons-material';

import { useFeedbackMutation } from 'hooks';

import { StockContainer } from 'pages/stock/common';
import { updateTransactionQueries } from 'pages/stock/common/utils';

import { IconMenuItem } from 'components/inputs/buttons';
import { EnhancedTable } from 'components/data-display/table';

import { appendTransaction } from 'stux/data-managers/stockDetail';

import FilterOption from './FilterOption';
import AddTransactionDialog from '../AddTransactionDialog';

const lastColumn = [{
  minWidth: 80,
  maxWidth: 80,
  width: 80,
  label: 'Baki',
  prop: 'balance',
  sortable: true
}, {
  minWidth: 130,
  maxWidth: 130,
  width: 130,
  align: 'right',
  label: 'Hampir Luput',
  prop: 'nearExpiry',
  sortable: true
}, {
  minWidth: 90,
  maxWidth: 90,
  width: 90,
  align: 'right',
  label: 'Luput',
  prop: 'expired',
  sortable: true
}];

const tableProps = {
  sx: {
    '& svg.MuiTableSortLabel-icon': {
      position: 'absolute',
      left: -24
    },
    '.lowstock': {
      backgroundColor: '#f8a298 !important'
    },
    '.lowstock:nth-of-type(odd)': {
      backgroundColor: '#ffc2bb !important'
    }
  }
};

const StockDataGrid = ({
  isLoading,
  stockCards,
  variations,
  daysBeforeExpired,
  unitBeforeRestock,
  onOpenStockCard
}) => {
  const stockData = React.useRef(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterOption, setFilterOption] = React.useState(0);
  const [transactionData, setTransactionData] = React.useState(undefined);
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  const queryClient = useQueryClient();
  const mutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika menambah transaksi. Sila cuba sekali lagi.',
    mutationFn: appendTransaction,
    onSuccessOrError: () => {
      setTransactionData(undefined);
    },
    onSuccess: (newData) => {
      updateTransactionQueries(queryClient, newData);
    }
  });

  React.useEffect(() => {
    const newColumns = [];
    variations.forEach((v, index) => {
      newColumns.push({
        prop: 'parsedVariationCode',
        key: v.variationId,
        label: v.variationName,
        align: index !== 0 ? undefined : 'left',
        getValue: (val) => {
          const variantId = val[v.variationId];
          const variant = v.variants.find((item) => item.variantId === variantId);
          return variant?.variantName ?? '-';
        }
      });
    });
    newColumns.push(lastColumn[filterOption]);

    setColumns(newColumns);
    setRows(stockCards);
  }, [stockCards, variations]);

  const onClickStartAction = React.useCallback((e, value) => {
    stockData.current = value;
    setAnchorEl(e.currentTarget);
  }, []);

  const getRowClassNameFromData = React.useCallback((val) => (
    val.balance <= unitBeforeRestock ? 'lowstock' : undefined
  ), [unitBeforeRestock]);

  const onChangeFilter = (newFilter) => {
    setColumns((prev) => {
      const newColumns = prev.slice(0, -1);
      newColumns.push(lastColumn[newFilter]);
      return newColumns;
    });
    setFilterOption(newFilter);
  };

  const updateTransactionData = (data) => {
    const { stockCardId, unitPrice, parsedVariationCode } = data;
    const variationsData = [];
    variations.forEach(({ variationId, variationName, variants }) => {
      const variantId = parsedVariationCode[variationId];
      if (variantId) {
        const variant = variants.find((item) => item.variantId === variantId);
        variationsData.push({
          variationId,
          variationName,
          variants: [variant],
          selectedIndex: 0
        });
      }
    });

    setTransactionData({
      unitPrice,
      id: stockCardId,
      variations: variationsData
    });
  };

  const onCloseMenu = () => {
    stockData.current = undefined;
    setAnchorEl(null);
  };

  const onAddTransaction = (newTransaction) => {
    mutation.mutate({ ...newTransaction, daysBeforeExpired });
  };

  return (
    <StockContainer fullHeightLoading isLoading={isLoading} style={{ marginBottom: '1rem', flex: '1 1 auto' }}>
      {
        !stockCards?.length ? (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography sx={{ p: 2, border: 'double lightgrey', color: 'grey' }}>
              Tiada senarai kad stok untuk dipaparkan
            </Typography>
          </Box>
        ) : (
          <>
            <FilterOption value={filterOption} onChange={onChangeFilter} />
            <Box sx={{ maxWidth: 'calc(100vw - 32px)' }}>
              <EnhancedTable
                pinStart
                pinEnd
                stickyHeader
                noWrapCellText
                noWrapHeaderText
                alternateColor="#edf4ff"
                // alternateColor="#e4eefe"
                rowRefPropName="stockCardId"
                rows={rows}
                columns={columns}
                tableProps={tableProps}
                getRowClassNameFromData={getRowClassNameFromData}
                onClickStartAction={onClickStartAction}
              />
            </Box>
            <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onCloseMenu}>
              <IconMenuItem
                label="Papar Kad Stok"
                icon={ReceiptLong}
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenStockCard(stockData.current.stockCardId);
                  onCloseMenu();
                }}
              />
              <IconMenuItem
                noDivider
                label="Tambah Transaksi"
                icon={Add}
                onClick={(e) => {
                  e.stopPropagation();
                  updateTransactionData(stockData.current);
                  onCloseMenu();
                }}
              />
            </Menu>
            <AddTransactionDialog
              disableVariations
              open={!!transactionData}
              stockCardId={transactionData?.id}
              variations={transactionData?.variations}
              unitPrice={transactionData?.unitPrice}
              expiredDate={transactionData?.expiredDate}
              onClose={() => setTransactionData(undefined)}
              onAddTransaction={onAddTransaction}
            />
          </>
        )
      }
    </StockContainer>
  );
};

StockDataGrid.propTypes = {
  isLoading: PropTypes.bool,
  daysBeforeExpired: PropTypes.number.isRequired,
  variations: PropTypes.arrayOf(PropTypes.shape({})),
  stockCards: PropTypes.arrayOf(PropTypes.shape({})),
  unitBeforeRestock: PropTypes.number,
  onOpenStockCard: PropTypes.func.isRequired
};

StockDataGrid.defaultProps = {
  isLoading: false,
  variations: [],
  stockCards: [],
  unitBeforeRestock: 10000000
};

export default StockDataGrid;
