function padZero(str, len = 2) {
  const zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export function uintToRGB(colorUint) {
  const color = (colorUint & 0x00FFFFFF).toString(16);
  return `#${padZero(color, 6)}`;
}

export function colorFromString(str) {
  const hash = [...str].reduce((acc, char) => (
    char.charCodeAt(0) + ((acc << 5) - acc)
  ), 0);

  return uintToRGB(hash);
}

export function getReadablColor(color) {
  const colorString = color.replace('#', '');
  const r = parseInt(colorString.slice(0, 2), 16);
  const g = parseInt(colorString.slice(2, 4), 16);
  const b = parseInt(colorString.slice(4, 6), 16);

  const isLight = (r * 0.299 + g * 0.587 + b * 0.114) > 186;
  return isLight ? '#000000' : '#ffffff';
}

export function invertHex(color) {
  const inverted = (Number(`0x1${color}`) ^ 0xFFFFFF).toString(16).substring(1);
  return `#${padZero(inverted, 6)}`;
}
