import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { useFeedbackMutation } from 'hooks';

import { deleteStock } from 'stux/data-managers/stockList';
import { CommonDialog } from 'pages/commons';
import { useQueryClient } from '@tanstack/react-query';

const DeleteStockDialog = ({ stockId, onClose }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const queryClient = useQueryClient();
  const mutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika menghapus stok. Sila cuba lagi.',
    mutationFn: deleteStock,
    onSuccessOrError: () => {
      setIsSubmitting(false);
      onClose();
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['stockList', data.userId],
        (prev) => {
          const index = prev.findIndex((item) => item.stockId === data.stockId);
          if (index !== -1) {
            prev.splice(index, 1);
          }
          return prev;
        }
      );
    }
  });

  return (
    <CommonDialog
      fullWidth
      open={!!stockId}
      title="Hapus Stok"
      saveLabel="Hapus"
      isSubmitting={isSubmitting}
      onClose={onClose}
      onSave={() => {
        if (isSubmitting) return;

        setIsSubmitting(true);
        mutation.mutate(stockId);
      }}
    >
      <Typography>
        Stok yang dihapus tidak lagi dapat dicapai dan kesemua transaksi untuk stok
        ini akan turut terhapus.
      </Typography>
      <Typography sx={{ mt: 2 }}>Adakah anda pasti untuk menghapus stok ini?</Typography>
    </CommonDialog>
  );
};

DeleteStockDialog.propTypes = {
  stockId: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

DeleteStockDialog.defaultProps = {
  stockId: undefined
};

export default DeleteStockDialog;
