import UserAction from 'stux/actions/UserAction';
import { checkAuth, signIn, signUp, signOut, verifyEmail, changePassword } from 'stux/data-managers/authentication';

import StatefulStore from './StatefulStore';
import AppDispatcher from '../dispatchers/AppDispatcher';

// TODO: currently CRA not allows decorator
//       see: https://create-react-app.dev/docs/can-i-use-decorators/
// import { storeName } from '../utils/decorators';
// @storeName('UserStore')
class UserStore extends StatefulStore {
  constructor() {
    super({
      username: '',
      userId: '',
      displayName: '',
      address: '',
      accessToken: '',
      refreshToken: '',
      isIdentified: false,
      isVerifyingEmail: false,
      isEmailVerified: false,
      isAuthenticating: false,
      isAuthenticated: false,
      signInError: 0,
      signupError: 0,
      changePassError: 0
    }, false);
    // TODO: remove the below line and use decorator when CRA allows it
    this.constructor.prototype.storeName = 'UserStore';
    this._subscribe(AppDispatcher);
  }

  _init() {
    this._registerAction(UserAction.SIGN_IN, this.__onSignIn);
    this._registerAction(UserAction.SIGN_UP, this.__onSignUp);
    this._registerAction(UserAction.SIGN_OUT, this.__onSignOut);
    this._registerAction(UserAction.FINISH_EMAIL_VERIFICATION, this.__onFinishEmailVerification);
    this._registerAction(UserAction.UPDATE_DISPLAYNAME, this.__onUpdateDisplayname);
    this._registerAction(UserAction.CHANGE_PASSWORD, this.__onChangePassword);

    checkAuth(this.__onAuthUpdate);
  }

  __onAuthUpdate = (state) => {
    this._updateState(state);
  };

  __onSignIn = ({ email, password }) => {
    this._updateState({
      isAuthenticating: true
    });

    signIn(email, password)
      .then((data) => {
        this._updateState({ ...data, isAuthenticating: false });
      });
  };

  __onSignUp = ({ email, password }) => {
    this._updateState({
      isAuthenticating: true
    });

    signUp(email, password)
      .then((data) => {
        this._updateState({ ...data, isAuthenticating: false });
        return !data.signupError ? verifyEmail() : Promise.resolve();
      });
  };

  __onFinishEmailVerification = () => {
    this._updateState({
      isVerifyingEmail: false
    });
  };

  __onSignOut = () => {
    signOut();
  };

  __onUpdateDisplayname = ({ displayName }) => {
    this._updateState({ displayName });
  };

  __onChangePassword = ({ oldPassword, newPassword }) => {
    this._updateState({
      isAuthenticating: true
    });

    changePassword(this._state.username, oldPassword, newPassword)
      .then((data) => {
        this._updateState({ ...data, isAuthenticating: false });
        if (!data.changePassError) {
          signOut();
        }
      });
  };
}

const userStore = new UserStore();
export default userStore;
