import * as React from 'react';
import { Box, useMediaQuery } from '@mui/material';

import ProfileAction from 'stux/actions/ProfileAction';
import UserStore from 'stux/stores/UserStore';

import ProfileBanner from './ProfileBanner';
import PersonalProfile from './PersonalProfile';
import BusinessProfile from './BusinessProfile';

const ProfilePage = () => {
  const tabContents = React.useRef([PersonalProfile, BusinessProfile]);
  const tabContent = React.useRef(PersonalProfile);
  const [tabIndex, setTabIndex] = React.useState(0);
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const onChangeTab = (index) => {
    tabContent.current = tabContents.current[index];
    setTabIndex(index);
  };

  React.useEffect(() => {
    // init the profile in case it never been initialised
    if (UserStore.state.isAuthenticated) {
      ProfileAction.initProfile(UserStore.state.userId);
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        m: 0,
        mt: matches ? 0 : -1.5,
        '& > *:not(:first-child)': {
          mt: 1.5
        }
      }}
    >
      <ProfileBanner tabIndex={tabIndex} onChangeTab={onChangeTab} />
      <tabContent.current style={{ margin: matches ? '16px 0 0' : '16px 16px 0' }} />
    </Box>
  );
};

export default ProfilePage;
