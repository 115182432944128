import * as React from 'react';

import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

const GetStarted = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useStux(UserStore, 'isAuthenticated');

  if (isAuthenticated) return null;

  return (
    <Stack
      alignItems="center"
      sx={{
        p: 4,
        mb: -2,
        color: 'alternative.main',
        textAlign: 'center',
        backgroundColor: 'secondary.dark'
      }}
    >
      <Typography variant="h4" fontWeight="bold" fontFamily="Epilogue">
        Ready to get involve today?
      </Typography>
      <Typography variant="subtitle1">
        Register now and you will be up and running in no time!
      </Typography>
      <Typography variant="subtitle1">
        <span>If you have any inquery, don&apos;t hesitate to contact us at </span>
        <span style={{ fontWeight: 'bold', fontStyle: 'italic', pointerEvents: 'none' }}>i.asnaf.ACT@gmail.com</span>
      </Typography>
      <Button
        sx={{
          px: 4,
          py: 1.5,
          mt: 3,
          fontSize: '1.15rem',
          fontWeight: 'bold',
          textTransform: 'none',
          backgroundColor: 'white',
          color: 'black',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)'
          }
        }}
        onClick={() => {
          navigate('/sign-up');
        }}
      >
        Register to get started
      </Button>
    </Stack>
  );
};

export default GetStarted;
