import * as React from 'react';
import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { useForceUpdate } from 'hooks';

import UserStore from 'stux/stores/UserStore';
import { getStockGroupList, initStockGroupData } from 'stux/data-managers/stockGroup';
import { getStockList, initStockListData } from 'stux/data-managers/stockList';
import { initStockSummaryData } from 'stux/data-managers/stockSummary';

import StockListHeader from './StockListHeader';
import StockListFilter from './StockListFilter';
import StockListDisplay from './StockListDisplay';
import StockGroupList from './stock-group/StockGroupList';

const StockListPage = () => {
  const header = React.useRef(null);
  const forceUpdate = useForceUpdate();
  const [isReady, setIsReady] = React.useState(false);
  const [filter, setFilter] = React.useState({
    searchName: '',
    stockGroupId: 'all-group',
    isRaw: false,
    isPartial: false,
    isFinished: false
  });

  const { data: stockGroups, isLoading: isLoadingStockGroup } = useQuery(
    ['stockGroup', UserStore.state.userId],
    getStockGroupList,
    { enabled: isReady }
  );

  const { data: stockList, isLoading: isLoadingStockList } = useQuery(
    ['stockList', UserStore.state.userId],
    getStockList,
    { enabled: isReady }
  );

  React.useEffect(() => {
    if (UserStore.state.isAuthenticated) {
      initStockGroupData(UserStore.state.userId);
      initStockListData(UserStore.state.userId);
      initStockSummaryData(UserStore.state.userId);
      setIsReady(true);
    }
  }, []);

  const onChangeGroup = (val) => {
    if (val === filter.stockGroupId) return;

    setFilter((prev) => ({
      ...prev,
      stockGroupId: val
    }));
  };

  const onUpdateStockGroupItems = () => {
    forceUpdate();
  };

  return (
    <Stack spacing={1.5} sx={{ height: '100%' }}>
      <StockListHeader ref={header} stockGroups={stockGroups ?? []} />
      <StockGroupList
        data={stockGroups ?? []}
        isLoading={isLoadingStockGroup}
        onChangeGroup={onChangeGroup}
        onUpdateStockGroupItems={onUpdateStockGroupItems}
      />
      <StockListFilter
        searchName={filter.searchName}
        isRaw={filter.isRaw}
        isPartial={filter.isPartial}
        isFinished={filter.isFinished}
        onFilter={(prop, val) => {
          setFilter((prev) => ({
            ...prev,
            [prop]: val
          }));
        }}
      />
      <StockListDisplay
        data={stockList ?? []}
        stockGroups={stockGroups ?? []}
        filter={filter}
        isLoading={isLoadingStockList}
        onClickStart={() => {
          header.current.openAddStockDialog();
        }}
      />
    </Stack>
  );
};

export default StockListPage;
