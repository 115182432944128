import * as React from 'react';
import ReactDOM from 'react-dom/client';

import ThemeProvider from 'themes';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { initFirebaseApp } from 'firebaseUtil/app';

import { MainPage } from 'pages/main';
import { HomePage } from 'pages/home';
import { ProfilePage } from 'pages/profile';
import { RequireAuth, SignInPage, SignUpPage } from 'pages/authentications';
import { StockListPage } from 'pages/stock/stock-list';
import { StockDetailPage } from 'pages/stock/stock-detail';
import { StockCardPage } from 'pages/stock/stock-card';
import { StockSummaryPage } from 'pages/stock/stock-summary';

import UserStore from 'stux/stores/UserStore';

import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
});

window.onload = () => {
  initFirebaseApp();
  UserStore.init();

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <React.StrictMode>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<SignInPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/" element={<MainPage />}>
                <Route index element={<HomePage />} />
                <Route
                  path="profile"
                  element={(
                    <RequireAuth>
                      <ProfilePage />
                    </RequireAuth>
                  )}
                />
                <Route path="stocks">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <StockListPage />
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path="summary"
                    element={(
                      <RequireAuth>
                        <StockSummaryPage />
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path=":stockListId"
                    element={(
                      <RequireAuth>
                        <StockDetailPage />
                      </RequireAuth>
                    )}
                  />
                  <Route
                    path=":stockListId/:stockCardId"
                    element={(
                      <RequireAuth>
                        <StockCardPage />
                      </RequireAuth>
                    )}
                  />
                </Route>
              </Route>
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
