import * as React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { PlayCircleOutlined, VideocamOutlined } from '@mui/icons-material';

const RecordedLectures = () => (
  <Card
    variant="outlined"
    sx={{
      display: 'flex',
      flex: 1,
      width: '100%',
      maxWidth: '1280px',
      border: 'none',
      flexDirection: { xs: 'column', sm: 'row' }
    }}
  >
    <CardContent sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Typography variant="h5" fontWeight="bold">Recorded Lectures</Typography>
      <Typography variant="subtitle1">
        All lecture sessions will be recorded and posted to a private Facebook group
        (by invitation only). The updated version of the lectures can be accessible by
        the participants who have registered for the training courses.
      </Typography>
    </CardContent>
    <CardContent sx={{ position: 'relative', overflow: 'hidden', maxWidth: '100vw' }}>
      <VideocamOutlined
        sx={{
          color: 'primary.light',
          mr: { xs: 12, sm: 25 },
          fontSize: 300,
          transform: 'rotate(-10deg)'
        }}
      />
      <PlayCircleOutlined
        sx={{
          position: 'absolute',
          right: 40,
          bottom: 30,
          color: 'salmon',
          fontSize: 150
        }}
      />
    </CardContent>
  </Card>
);

export default RecordedLectures;
