import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '@mui/material';

import { stockTypes } from 'pages/constants/transaction';

import { StockContainer } from '../common';
import StockListItemMenu from './StockListItemMenu';
import DeleteStockDialog from './DeleteStockDialog';
import ChangeStocNameDialog from './ChangeStockNameDialog';

const StockListDisplay = ({ data, stockGroups, filter, isLoading, onClickStart }) => {
  const navigate = useNavigate();
  const [workingData, setWorkingData] = React.useState(data);
  const [deletingStockId, setDeletingStockId] = React.useState(undefined);
  const [changingData, setChangingData] = React.useState(undefined);

  const filterData = (currentData) => {
    const { searchName, stockGroupId, isRaw, isPartial, isFinished } = filter;
    const hasFilterType = isRaw || isPartial || isFinished;
    const isAllGroup = stockGroupId === 'all-group';
    if (!searchName && !hasFilterType && isAllGroup) {
      setWorkingData(currentData);
    } else {
      const stockNameLowerCase = searchName.toLowerCase();
      const filteredData = currentData.filter((item) => {
        if (!isAllGroup && item.stockGroupId && item.stockGroupId !== stockGroupId) return false;
        if (!isAllGroup && !item.stockGroupId && stockGroupId !== 'no-group') return false;

        let isValid = true;
        if (searchName) {
          const isInStockName = item.stockName.toLowerCase().includes(stockNameLowerCase);
          const isInStockCode = item.stockCode.toLowerCase().includes(stockNameLowerCase);
          isValid = isInStockName || isInStockCode;
        }

        if (hasFilterType) {
          let filterType = isRaw ? 'R' : '';
          filterType += isPartial ? 'W' : '';
          filterType += isFinished ? 'F' : '';
          isValid = isValid && filterType.includes(item.stockType);
        }

        return isValid;
      });
      setWorkingData(filteredData);
    }
  };

  React.useEffect(() => {
    filterData(data);
  }, [data, filter]);

  const onChangeStockGroup = () => {
    filterData(data);
  };

  return (
    <StockContainer
      fullHeightLoading
      isLoading={isLoading}
      isEmptyList={!workingData.length}
      sx={{ height: '100%' }}
      emptyListProps={{
        onClickStart,
        message: 'Tiada senarai stok',
        buttonLabel: 'Tambah Stok'
      }}
    >
      <List sx={{ height: '100%' }}>
        {
          workingData.map(({
            stockId,
            stockName,
            stockGroupId,
            stockType,
            stockCode,
            daysBeforeExpired,
            unitBeforeRestock
          }) => (
            <ListItem
              divider
              disablePadding
              key={stockId}
              variant="left-action"
              sx={{
                pl: 4,
                '& .MuiListItemSecondaryAction-root': {
                  left: 0,
                  right: 'unset'
                }
              }}
              secondaryAction={(
                <StockListItemMenu
                  onEdit={() => {
                    setChangingData({
                      stockId,
                      stockGroupId,
                      stockName,
                      daysBeforeExpired,
                      unitBeforeRestock
                    });
                  }}
                  onDelete={() => {
                    setDeletingStockId(stockId);
                  }}
                />
              )}
              onClick={() => {
                navigate(`/stocks/${stockId}`);
              }}
            >
              <ListItemButton>
                <ListItemText
                  primary={`${stockCode} ${stockName}`}
                  secondary={stockTypes[stockType]}
                />
              </ListItemButton>
            </ListItem>
          ))
        }
      </List>
      <DeleteStockDialog stockId={deletingStockId} onClose={() => setDeletingStockId(undefined)} />
      <ChangeStocNameDialog
        stockData={changingData}
        stockGroups={stockGroups}
        onChangeStockGroup={onChangeStockGroup}
        onClose={() => setChangingData(undefined)}
      />
    </StockContainer>
  );
};

StockListDisplay.propTypes = {
  filter: PropTypes.shape({
    searchName: PropTypes.string,
    stockGroupId: PropTypes.string,
    isRaw: PropTypes.bool,
    isPartial: PropTypes.bool,
    isFinished: PropTypes.bool
  }).isRequired,
  stockGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClickStart: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default StockListDisplay;
