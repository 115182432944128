import * as React from 'react';
import PropTypes from 'prop-types';
import { Slide as MUISlide } from '@mui/material';

const Slide = React.forwardRef(({ direction, ...props }, ref) => (
  <MUISlide direction={direction} ref={ref} {...props} />
));

Slide.propTypes = {
  direction: PropTypes.oneOf(['up', 'down'])
};

Slide.defaultProps = {
  direction: 'up'
};

Slide.displayName = 'Slide';
export default Slide;
