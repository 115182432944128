import * as React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';

import StockVariantCard from './StockVariantCard';
import { StockContainer } from '../../common';

const StockVariant = ({ isLoading, data, onClickStart, onChangeVariants }) => {
  const lastDataIndex = data.length - 1;
  const [selectedVariants, setSelectedVariants] = React.useState({});

  React.useEffect(() => {
    setSelectedVariants((prev) => {
      const newSelection = { ...prev };
      data.forEach(({ variationId }) => {
        const v = newSelection[variationId];
        if (!v) {
          newSelection[variationId] = [];
        }
      });

      return newSelection;
    });
  }, [data]);

  const onSelectVariant = (variationId, variantId) => {
    const variants = selectedVariants[variationId] ?? [];
    const index = variants.indexOf(variantId);
    if (index === -1) {
      variants.push(variantId);
    } else {
      variants.splice(index, 1);
    }

    onChangeVariants(variantId);
    setSelectedVariants({ ...selectedVariants });
  };

  return (
    <StockContainer
      isLoading={isLoading}
      isEmptyList={!data.length}
      sx={{ pr: 1, overflow: 'hidden' }}
      emptyListProps={{
        onClickStart,
        message: 'Tiada senarai variasi',
        buttonLabel: 'Tambah Variasi'
      }}
    >
      {
        data.map((item, index) => (
          <React.Fragment key={item.variationId}>
            <StockVariantCard
              title={item.variationName}
              variants={item.variants}
              variationId={item.variationId}
              canDelete={item.canDelete}
              selectedVariants={selectedVariants[item.variationId]}
              sx={{
                pt: index !== 0 ? 1 : 0.5,
                pb: index !== lastDataIndex ? 1 : 0.5
              }}
              onSelectVariant={onSelectVariant}
            />
            {
              index !== lastDataIndex && (
                <Divider
                  style={{
                    // need to use style to override stubborn style from Stack
                    marginTop: 0,
                    marginLeft: '-1rem',
                    marginRight: '-1rem',
                    borderBottom: '1px dashed lightgrey'
                  }}
                />
              )
            }
          </React.Fragment>
        ))
      }
    </StockContainer>
  );
};

StockVariant.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onClickStart: PropTypes.func.isRequired,
  onChangeVariants: PropTypes.func.isRequired
};

StockVariant.defaultProps = {
  isLoading: false,
  data: []
};

export default StockVariant;
