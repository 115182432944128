import msLocale from 'date-fns/locale/ms';
import { format } from 'date-fns';

import { documentTypes, transactionTypes } from 'pages/constants/transaction';

const head = `
  <head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">
    <style>
      body {
        font-family: 'Roboto', sans-serif;
        font-size: 8pt;
        background-color: transparent;
      }

      h3 {
        font-size: 10pt;
        padding: 2px;
        margin: 0;
        font-weight: normal;
      }

      table {
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
        max-width: stretch;
        font-size: 8pt;
        page-break-inside: auto;
      }

      tr {
        page-break-inside: avoid;
        page-break-after: auto;
      }

      td {
        text-transform: capitalize;
        border-top: 1px solid lightgrey;
        padding: 4px;
      }

      td:first-child {
        border-left: 1px solid lightgrey;
      }

      tr:last-child td {
        border-bottom: 1px solid lightgrey;
      }

      tr:nth-child(odd) {
        background-color: #f2f2f2;
      }

      tr td:not(first-child) {
        border-right: none;
      }

      tr td:last-child {
        border-right: 1px solid lightgrey;
      }

      .profile {
        display: flex;
        align-items: baseline;
      }

      .variationContainer {
        display: flex;
        flex-direction: column;
      }

      .variation {
        display: flex;
        align-items: center;
      }

    </style>
  </head>
`;

const formatter = new Intl.NumberFormat('en-MY', { useGrouping: 'always', minimumFractionDigits: 2 });
function getCurrency(val) {
  return val !== '' ? formatter.format(val) : '';
}

function getVariants(variations) {
  const content = Object.entries(variations).reduce((acc, curr, index) => {
    const prefix = index !== 0 ? '\n' : '';
    const label = `${prefix}<h3><strong>${curr[0]}:&nbsp;</strong></h3>`;
    const value = `<h3>${curr[1]}</h3>`;
    return `${acc}<div class="variation">${label}${value}</div>`;
  }, '');

  return `
    <div class="variationContainer">
      ${content}
    </div>
  `;
}

const indexStyle = 'style="width: 4.34984%; height: 39.1875px; text-align: center;"';
const dateStyle = 'style="width: 20.6634%; height: 47.5938px; text-align: center;"';
const typeStyle = 'style="width: 12.5066%; height: 47.5938px; text-align: center; text-transform: none;"';
const calcStyle = 'style="width: 12.5066%; height: 47.5938px; text-align: right;"';

function getNumericStyle(data) {
  const isPositive = data > 0;
  const color = isPositive ? '' : ' color: #ff3823;';
  return `style="width: 12.5066%; height: 47.5938px; text-align: right;${color}"`;
}

function getTransactions(transactions, unitPrice) {
  let balance = 0;
  let totalQuantity = 0;

  const rows = transactions.reduce((acc, curr, index) => {
    const no = index + 1;
    const date = format(new Date(curr.transactionDate), 'dd MMM yyyy', { locale: msLocale });
    const transactionType = transactionTypes.find((item) => item.id === curr.transactionType);
    const docType = documentTypes[transactionType.id];
    const docTypeObj = docType.find((item) => item.id === curr.documentType) ?? '';
    const document = {
      primary: docTypeObj && docTypeObj.id !== 'noDoc' ? docTypeObj.name : '',
      secondary: curr.documentRef
    };
    const total = curr.quantity * unitPrice;
    balance += total;
    totalQuantity += curr.quantity;
    return `
      ${acc}
      <tr>
        <td ${indexStyle}>${no}</td>
        <td ${dateStyle}>${date}</td>
        <td ${typeStyle}>${transactionType.name}</td>
        <td ${typeStyle}>
          ${document.primary}${document.primary ? '<br />' : ''}${document.secondary}
        </td>
        <td ${getNumericStyle(curr.quantity)}>${curr.quantity}</td>
        <td ${calcStyle}>${getCurrency(unitPrice)}</td>
        <td ${getNumericStyle(total)}>${getCurrency(total)}</td>
        <td ${getNumericStyle(balance)}>${getCurrency(balance)}</td>
      </tr>
    `;
  }, '');

  const lastRow = `
    <tr>
      <td ${indexStyle} colspan="3">&nbsp;</td>
      <td ${calcStyle}><strong>Baki</strong></td>
      <td ${getNumericStyle(totalQuantity)}>${totalQuantity}</td>
      <td ${calcStyle}>${getCurrency(unitPrice)}</td>
      <td ${getNumericStyle(balance)}>${getCurrency(balance)}</td>
      <td ${calcStyle}>&nbsp;</td>
    </tr>
  `;

  return `${rows}${lastRow}`;
}

function getProfileContent(profile) {
  return `
    <div class="profile">
      ${profile.businessName ? `<h1 style="margin: 0">${profile.businessName}</h1>` : ''}
      ${profile.registrationNumber ? `&nbsp;<span>(${profile.registrationNumber})</span>` : ''}
    </div>
  `;
}

export function getPrintHTML(
  data,
  variations,
  unitPrice,
  businessProfile,
  stockName
) {
  const hasProfile = businessProfile.businessName || businessProfile.registrationNumber;

  return `
    <html>
      ${head}
      <body>
        ${hasProfile ? getProfileContent(businessProfile) : ''}
        ${hasProfile ? '<br />' : ''}

        ${getVariants({ 'Nama Produk': stockName })}
        </br>

        ${getVariants(variations)}
        </br>

        <h3 style="margin-bottom: 4px">
          <strong>Senarai Transaksi</strong>
        </h3>
        <table style="width: 100%">
          <thead>
            <tr style="height: 50px; background-color: #c2e0f4">
              <th style="text-align: center; height: 19.5938px; padding: 4px; white-space: nowrap">No</th>
              <th style="text-align: center; height: 19.5938px; padding: 4px; white-space: nowrap">Tarikh</th>
              <th style="text-align: center; height: 19.5938px; padding: 4px; white-space: nowrap">Transaksi</th>
              <th style="text-align: center; height: 19.5938px; padding: 4px; white-space: nowrap">Dokumen</th>
              <th style="text-align: right; height: 19.5938px; padding: 4px; white-space: nowrap">Kuantiti</th>
              <th style="text-align: right; height: 19.5938px; padding: 4px;">Harga Seunit (RM)</th>
              <th style="text-align: right; height: 19.5938px; padding: 4px; white-space: nowrap">Jumlah (RM)</th>
              <th style="text-align: right; height: 19.5938px; padding: 4px; white-space: nowrap;">Baki (RM)</th>
            </tr>
          </thead>
          <tbody>
            ${getTransactions(data, unitPrice)}
          </tbody>
        </table>
      </body>
    </html>
  `;
}
