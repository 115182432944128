import * as React from 'react';

const BuildWithFirebaseLogo = ({ ...props }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 752 336"
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">
      {`
        .st0{fill:#FFFFFF;}
        .st1{opacity:0.7;}
        .st2{fill:none;}
        .st3{clip-path:url(#SVGID_2_);}
        .st4{fill:#FFA000;}
        .st5{opacity:0.12;fill:url(#SVGID_3_);enable-background:new    ;}
        .st6{fill:#F57C00;}
        .st7{opacity:0.2;fill:url(#SVGID_4_);enable-background:new    ;}
        .st8{fill:#FFCA28;}
        .st9{fill:#FFFFFF;fill-opacity:0.2;}
        .st10{opacity:0.2;fill:#A52714;enable-background:new    ;}
        .st11{fill:url(#SVGID_5_);}
        .st12{opacity:0.54;}
        .st13{clip-path:url(#SVGID_7_);}
        .st14{opacity:0.12;fill:url(#SVGID_8_);enable-background:new    ;}
        .st15{opacity:0.2;fill:url(#SVGID_9_);enable-background:new    ;}
        .st16{fill:url(#SVGID_10_);}
        .st17{display:none;}
        .st18{display:inline;fill:#00FFFF;fill-opacity:0.2;}
      `}
    </style>
    <g id="Layer_3">
      <path
        className="st1"
        d="M740,336H12c-6.6,0-12-5.4-12-12V12C0,5.4,5.4,0,12,0h728c6.6,0,12,5.4,12,12v312C752,330.6,746.6,336,740,336z"
      />
    </g>
    <g id="Layer_1">
      <g>
        <g>
          <path className="st0" d="M257.3,236h-11.8v-91.6h53.5v11.3h-41.7v30.1H295v11h-37.6V236z" />
          <path
            className="st0"
            d="M319.3,157.6c-1.6,1.6-3.6,2.4-5.9,2.4c-2.3,0-4.3-.8-5.9-2.4c-1.6-1.6-2.4-3.6-2.4-5.9s
               .8-4.3,2.4-5.9c1.6-1.6,3.6-2.4,5.9-2.4c2.3,0,4.3.8,5.9,2.4c1.6,1.6,2.4,3.6,2.4,5.9S
               320.9,155.9,319.3,157.6z M319.3,236h-11.8v-62.7h11.8V236z"
          />
          <path
            className="st0"
            d="M343.9,236h-11.8v-62.7h11.3v10.2h0.5c1.2-3.4,3.6-6.2,7.4-8.4c3.7-2.2,7.3-3.3,10.8-3.3
               c3.5,0,6.4,0.5,8.8,1.5l-4.5,11c-1.5-0.6-3.6-0.9-6.3-0.9c-4.3,0-8,1.7-11.3,5.1c-3.3
               3.4-4.9,7.8-4.9,13.2V236z"
          />
          <path
            className="st0"
            d="M399.3,238.1c-9.5,0-17.2-3.2-23.1-9.5c-5.9-6.3-8.9-14.3-8.9-23.9c0-9.1,2.9-17,8.6-23.6
               c5.8-6.6,13.1-9.9,22.1-9.9c9.3,0,16.7,3,22.3,9.1c5.6,6.1,8.4,14.2,8.4,24.3l-0.1,2.2h-49.5
               c.3,6.3,2.5,11.3,6.3,15c3.9,3.7,8.4,5.5,13.6,5.5c8.4,0,14.2-3.6,17.2-10.8l10.5,4.4
               c-2,4.9-5.4,8.9-10.1,12.2S406.1,238.1,399.3,238.1z M416.2,197.1c-.3-3.6-1.9-7-4.9-10.2
               c-3-3.2-7.5-4.9-13.5-4.9c-4.4,0-8.1,1.4-11.3,4.1c-3.2,2.7-5.4,6.4-6.6,11H416.2z"
          />
          <path
            className="st0"
            d="M470.1,238.1c-4.8,0-9.1-1-12.9-3.1c-3.8-2-6.6-4.6-8.5-7.7h-0.5v8.7h-11.3v-91.6h11.8v28.9l-0.5,8.7h0.5
               c1.9-3.1,4.7-5.6,8.5-7.7c3.8-2,8.1-3.1,12.9-3.1c8.1,0,15.1,3.2,21,9.6s8.8,14.3,8.8,23.8c0,9.5-2.9,17.4-8.8,23.8
               C485.2,234.9,478.2,238.1,470.1,238.1z M468.1,227.3c5.5,0,10.2-2.1,14.1-6.2c3.9-4.1,5.9-9.6,5.9-16.4c0-6.8-2-12.3-5.9-16.4
               c-3.9-4.1-8.6-6.2-14.1-6.2c-5.5,0-10.3,2-14.1,6.1s-5.8,9.6-5.8,16.5c0,6.9,1.9,12.4,5.8,16.5
               C457.9,225.3,462.6,227.3,468.1,227.3z"
          />
          <path
            className="st0"
            d="M529.2,238.1c-6.7,0-12.2-1.9-16.7-5.8c-4.5-3.8-6.7-8.9-6.7-15.2c0-6.8,2.6-12.2,7.9-16.1
               c5.3-3.9,11.8-5.8,19.6-5.8c6.9,0,12.6,1.3,17,3.8v-1.8c0-4.6-1.6-8.3-4.7-11.1c-3.2-2.8-7-4.2-11.6-4.2c-3.4,0-6.5,0.8-9.3,2.4
               c-2.8,1.6-4.7,3.8-5.7,6.7l-10.8-4.6c1.5-3.8,4.3-7.2,8.6-10.4c4.3-3.2,9.9-4.8,16.9-4.8c8,0,14.7,2.3,20,7
               c5.3,4.7,7.9,11.3,7.9,19.8V236h-11.3v-8.7h-0.5C545.1,234.5,538.2,238.1,529.2,238.1z M531.1,227.3c4.9,0,9.3-1.8,13.2-5.4
               c4-3.6,6-7.9,6-12.9c-3.3-2.7-8.3-4.1-15-4.1c-5.7,0-10,1.2-12.9,3.7c-2.9,2.5-4.4,5.4-4.4,8.7c0,3.1,1.3,5.5,4,7.3
               C524.7,226.4,527.7,227.3,531.1,227.3z"
          />
          <path
            className="st0"
            d="M594.3,238.1c-7,0-12.8-1.7-17.3-5.1c-4.6-3.4-7.9-7.7-10-12.8l10.5-4.4c3.3,7.9,9,11.8,17,11.8
               c3.7,0,6.7-0.8,9-2.4c2.3-1.6,3.5-3.8,3.5-6.4c0-4.1-2.9-6.9-8.6-8.3l-12.7-3.1c-4-1-7.8-3-11.4-5.8c-3.6-2.9-5.4-6.7-5.4-11.6
               c0-5.5,2.5-10,7.4-13.5c4.9-3.5,10.7-5.2,17.5-5.2c5.5,0,10.5,1.3,14.8,3.8c4.4,2.5,7.5,6.1,9.3,10.8l-10.2,4.2
               c-2.3-5.5-7.1-8.3-14.3-8.3c-3.5,0-6.4,0.7-8.8,2.2c-2.4,1.5-3.6,3.4-3.6,5.9c0,3.6,2.8,6,8.3,7.3l12.4,2.9
               c5.9,1.4,10.2,3.7,13.1,7c2.8,3.3,4.2,7.1,4.2,11.3c0,5.6-2.3,10.3-6.9,14.1S601.6,238.1,594.3,238.1z"
          />
          <path
            className="st0"
            d="M655.8,238.1c-9.5,0-17.2-3.2-23.1-9.5c-5.9-6.3-8.9-14.3-8.9-23.9c0-9.1,2.9-17,8.6-23.6
               c5.8-6.6,13.1-9.9,22.1-9.9c9.3,0,16.7,3,22.3,9.1c5.6,6.1,8.4,14.2,8.4,24.3l-0.1,2.2h-49.5c0.3,6.3,2.5,11.3,6.3,15
               c3.9,3.7,8.4,5.5,13.6,5.5c8.4,0,14.2-3.6,17.2-10.8l10.5,4.4c-2,4.9-5.4,8.9-10.1,12.2C668.4,236.4,662.6,238.1,655.8,238.1z
               M672.7,197.1c-0.3-3.6-1.9-7-4.9-10.2c-3-3.2-7.5-4.9-13.5-4.9c-4.4,0-8.1,1.4-11.3,4.1c-3.2,2.7-5.4,6.4-6.6,11H672.7z"
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_6_"
              d="M176.8,137.4c-0.4-2.2-2.4-3.6-4.6-3.3c-0.8,0.1-1.6,0.5-2.2,1.1L149.2,156l-16.3-31.1
                 c-1-2-3.4-2.7-5.4-1.7c-0.7,0.4-1.3,1-1.7,1.7l-8.9,17l-22.4-41.8c-1-1.9-3.5-2.7-5.4-1.6c-1.1,0.6-1.9,1.7-2.1,2.9L65.3,240
                 l57.9,32.5c3.6,2,8.1,2,11.7,0l58.4-32.5L176.8,137.4z"
            />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_6_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st3">
            <path
              className="st4"
              d="M65.3,240L87,101.4c0.3-2.2,2.4-3.7,4.6-3.3c1.2,0.2,2.3,1,2.9,2.1l22.4,41.8l8.9-17c1-2,3.4-2.7,5.4-1.7
                 c0.7,0.4,1.3,1,1.7,1.7L193.3,240H65.3z"
            />
            <linearGradient
              id="SVGID_3_"
              x1="90.2419"
              y1="137.4384"
              x2="82.2419"
              y2="141.6184"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(1 0 0 -1 0 329.9936)"
            >
              <stop offset="0" style={{ stopColor: '#A52714' }} />
              <stop offset="0.4" style={{ stopColor: '#A52714', stopOpacity: 0.5 }} />
              <stop offset="0.8" style={{ stopColor: '#A52714', stopOpacity: 0 }} />
            </linearGradient>
            <path className="st5" d="M139.3,99l-106-9v192l32-42L139.3,99z" />
            <path className="st6" d="M140.2,186l-23.3-44.1L65.3,240L140.2,186z" />
            <linearGradient
              id="SVGID_4_"
              x1="124.241"
              y1="149.0767"
              x2="120.661"
              y2="152.6567"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(1 0 0 -1 0 329.9936)"
            >
              <stop offset="0" style={{ stopColor: '#A52714', stopOpacity: 0.8 }} />
              <stop offset="0.5" style={{ stopColor: '#A52714', stopOpacity: 0.21 }} />
              <stop offset="1" style={{ stopColor: '#A52714', stopOpacity: 0 }} />
            </linearGradient>
            <path className="st7" d="M33.3,90h192v192h-192V90z" />
            <path
              className="st8"
              d="M193.3,240l-16.6-102.7c-0.4-2.2-2.4-3.6-4.6-3.3c-0.8,0.1-1.6,0.5-2.2,1.1L65.3,240l57.9,32.5
                 c3.6,2,8.1,2,11.7,0L193.3,240z"
            />
            <path
              className="st9"
              d="M176.8,137.4c-0.4-2.2-2.4-3.6-4.6-3.3c-0.8,0.1-1.6,0.5-2.2,1.1L149.2,156l-16.3-31.1
                 c-1-2-3.4-2.7-5.4-1.7c-0.7,0.4-1.3,1-1.7,1.7l-8.9,17l-22.4-41.8c-1-1.9-3.5-2.7-5.4-1.6c-1.1,0.6-1.9,1.7-2.1,2.9L65.3,240
                 h-0.1l0.1,0.1l0.6,0.3l83.3-83.4l20.8-20.8c1.6-1.6,4.1-1.6,5.7,0c0.6,0.6,1,1.3,1.1,2.2l16.4,101.7l0.2-0.1L176.8,137.4z
                 M65.5,239.8L87,102.4c0.3-2.2,2.4-3.7,4.6-3.3c1.2,0.2,2.3,1,2.9,2.1l22.4,41.8l8.9-17c1-2,3.4-2.7,5.4-1.7
                 c0.7,0.4,1.3,1,1.7,1.7l16,30.4L65.5,239.8z"
            />
            <path
              className="st10"
              d="M134.9,271.5c-3.6,2-8.1,2-11.7,0l-57.8-32.4l-0.1,0.9l57.9,32.5c3.6,2,8.1,2,11.7,0l58.4-32.5l-0.1-0.9
                 L134.9,271.5z"
            />
            <linearGradient
              id="SVGID_5_"
              x1="-1079.9131"
              y1="1378.4709"
              x2="-1029.8431"
              y2="1428.541"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(2.67 0 0 2.67 2945.2219 -3551.5447)"
            >
              <stop offset="0" style={{ stopColor: '#FFFFFF', stopOpacity: 0.1 }} />
              <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
            </linearGradient>
            <path
              className="st11"
              d="M176.8,137.4c-0.4-2.2-2.4-3.6-4.6-3.3c-0.8,0.1-1.6,0.5-2.2,1.1L149.2,156l-16.3-31.1
                 c-1-2-3.4-2.7-5.4-1.7c-0.7,0.4-1.3,1-1.7,1.7l-8.9,17l-22.4-41.8c-1-1.9-3.5-2.7-5.4-1.6c-1.1,0.6-1.9,1.7-2.1,2.9L65.3,240
                 l57.9,32.5c3.6,2,8.1,2,11.7,0l58.4-32.5L176.8,137.4z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          className="st0"
          d="M244.7,109.2v-43h15.5c3.4,0,6.3,1.1,8.7,3.3c2.5,2.2,3.7,5,3.7,8.4c0,2-0.5,3.8-1.6,5.4
             c-1.1,1.6-2.5,2.7-4.2,3.5v0.2c2.1,0.7,3.9,2,5.2,3.7c1.4,1.8,2,3.8,2,6.1c0,3.5-1.3,6.4-3.9,8.8c-2.6,2.3-5.6,3.5-9.1,3.5H244.7z
             M250.2,84.7h10c2.1,0,3.8-0.7,5-2.1c1.3-1.4,1.9-2.9,1.9-4.5c0-1.6-0.6-3.1-1.8-4.5c-1.2-1.4-2.8-2-4.8-2h-10.3V84.7z M250.2,104
             h11.1c2.1,0,3.8-0.7,5.2-2.2c1.3-1.5,2-3.1,2-4.9c0-1.8-0.7-3.4-2-4.8c-1.4-1.5-3.1-2.2-5.3-2.2h-10.9V104z"
        />
        <path
          className="st0"
          d="M306.5,109.2h-5.3v-4.1H301c-0.8,1.4-2.1,2.6-3.8,3.6c-1.7,1-3.5,1.5-5.5,1.5c-3.7,0-6.5-1.1-8.4-3.4
             c-1.9-2.2-2.9-5.2-2.9-9v-18h5.5v17.1c0,5.5,2.4,8.2,7.3,8.2c2.3,0,4.1-0.9,5.6-2.8c1.4-1.8,2.2-4,2.2-6.4V79.8h5.5V109.2z"
        />
        <path
          className="st0"
          d="M319.9,72.5c-0.8,0.8-1.7,1.1-2.8,1.1s-2-0.4-2.8-1.1c-0.8-0.8-1.1-1.7-1.1-2.8s0.4-2,1.1-2.8
             c0.8-0.8,1.7-1.1,2.8-1.1s2,0.4,2.8,1.1c0.8,0.8,1.1,1.7,1.1,2.8S320.6,71.7,319.9,72.5z M319.9,109.2h-5.5V79.8h5.5V109.2z"
        />
        <path className="st0" d="M333.2,109.2h-5.5v-43h5.5V109.2z" />
        <path
          className="st0"
          d="M352.9,109.7c-3.1,0-5.4-0.8-7-2.5c-1.6-1.6-2.4-4-2.4-7V84.9h-5.2v-5h5.2v-9h5.5v9h7.2v5h-7.2v15
             c0,3.2,1.3,4.8,4,4.8c1,0,1.8-0.2,2.5-0.5l1.9,4.7C356.1,109.5,354.6,109.7,352.9,109.7z"
        />
        <path
          className="st0"
          d="M410.3,109.2h-5.6l-7.3-22.6l-7.3,22.6h-5.6l-9.5-29.4h5.8l6.5,22.2h0.1l7.3-22.2h5.7l7.3,22.2h0.1l6.5-22.2
             h5.6L410.3,109.2z"
        />
        <path
          className="st0"
          d="M430.1,72.5c-0.8,0.8-1.7,1.1-2.8,1.1s-2-0.4-2.8-1.1c-0.8-0.8-1.1-1.7-1.1-2.8s0.4-2,1.1-2.8
             c0.8-0.8,1.7-1.1,2.8-1.1s2,0.4,2.8,1.1c0.8,0.8,1.1,1.7,1.1,2.8S430.8,71.7,430.1,72.5z M430.1,109.2h-5.5V79.8h5.5V109.2z"
        />
        <path
          className="st0"
          d="M450,109.7c-3.1,0-5.4-0.8-7.1-2.5c-1.6-1.6-2.4-4-2.4-7V84.9h-5.2v-5h5.2v-9h5.5v9h7.2v5H446v15
             c0,3.2,1.3,4.8,4,4.8c1,0,1.8-0.2,2.5-0.5l1.9,4.7C453.2,109.5,451.8,109.7,450,109.7z"
        />
        <path
          className="st0"
          d="M464.7,66.3v13.6l-0.2,4.1h0.2c0.8-1.4,2.1-2.6,3.8-3.6c1.7-1,3.6-1.5,5.5-1.5c3.6,0,6.4,1.1,8.3,3.4
             c1.9,2.2,2.9,5.2,2.9,9v18h-5.5V92.1c0-5.4-2.4-8.2-7.3-8.2c-2.3,0-4.1,0.9-5.6,2.7c-1.4,1.8-2.2,3.9-2.2,6.3v16.3h-5.5v-43H464.7z"
        />
      </g>
    </g>
    <g id="guides" className="st17">
      <rect id="XMLID_76_" x="-0.6" y="272" className="st18" width="753.2" height="64" />
      <rect id="XMLID_66_" x="-0.6" y="0" className="st18" width="753.2" height="64" />
      <rect x="688.5" y="0" className="st18" width="64" height="336" />
      <rect x="196.3" y="0" className="st18" width="48" height="336" />
      <rect x="-0.5" y="0" className="st18" width="64" height="336" />
    </g>
  </svg>
);

export default BuildWithFirebaseLogo;
