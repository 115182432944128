import * as React from 'react';
import { Link } from 'react-router-dom';
import { InputAdornment, TextField, Typography } from '@mui/material';
import {
  PersonAddAlt1 as SignUpIcon,
  Email as EmailIcon,
  Lock as PasswordIcon,
  EnhancedEncryption as ConfirmPasswordIcon
} from '@mui/icons-material';

import LogoImg from 'logo/logo.png';

import { useAuthentication } from 'hooks';

import UserAction from 'stux/actions/UserAction';
import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

import UserError from 'utils/UserError';

import Authentication from './Authentication';
import AuthenticationButton from './AuthenticationButton';
import EmailVerificationDialog from './EmailVerificationDialog';
import { ActionContainer, ItemContainer } from './styles';

const SignUpPage = () => {
  const [email, setEmail] = React.useState('');
  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [pwMatchError, setPwMatchError] = React.useState('');
  const [pwLengthError, setPwLengthEror] = React.useState('');
  const { signupError } = useStux(UserStore, 'signupError');

  useAuthentication();

  const onSignUp = (e) => {
    if (email && password1 && password2) {
      if (password1.length < 8) {
        setPwLengthEror('Katalaluan mestilah tidak kurang dari 8 aksara');
        return false;
      }

      e.preventDefault();
      if (password1 === password2) {
        UserAction.signUp(email, password1);
      } else {
        setPwMatchError('Katalaluan tidak sama');
      }
    }

    return false;
  };

  return (
    <Authentication title="Daftar akaun baharu" logo={LogoImg} icon={SignUpIcon}>
      <ItemContainer>
        <TextField
          required
          fullWidth
          type="email"
          variant="outlined"
          placeholder="Emel"
          value={email}
          error={!!signupError}
          helperText={UserError.getErrorMessage(signupError) || ' '}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            )
          }}
          onChange={(e) => {
            setEmail(e.currentTarget.value);
          }}
        />
        <TextField
          required
          fullWidth
          type="password"
          variant="outlined"
          placeholder="Katalaluan"
          value={password1}
          error={!!pwLengthError}
          helperText={pwLengthError || ' '}
          inputProps={{ minLength: 8 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PasswordIcon />
              </InputAdornment>
            )
          }}
          onChange={(e) => {
            setPassword1(e.currentTarget.value);
            setPwMatchError('');
            setPwLengthEror('');
          }}
        />
        <TextField
          required
          fullWidth
          type="password"
          variant="outlined"
          placeholder="Sahkan Katalaluan"
          value={password2}
          error={!!pwMatchError}
          helperText={pwMatchError || ' '}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ConfirmPasswordIcon />
              </InputAdornment>
            )
          }}
          onChange={(e) => {
            setPassword2(e.currentTarget.value);
            setPwMatchError('');
          }}
        />
        <ActionContainer>
          <AuthenticationButton label="Daftar" onClick={onSignUp} />
          <span>
            <Typography component="span">
              Sudah berdaftar?&nbsp;
            </Typography>
            <Link replace to="/login">Log masuk</Link>
          </span>
        </ActionContainer>
      </ItemContainer>
      <EmailVerificationDialog />
    </Authentication>
  );
};

export default SignUpPage;
