import * as React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const FilterOption = ({ value, onChange }) => (
  <RadioGroup
    row
    value={value}
    onChange={(e) => onChange(Number(e.target.value))}
  >
    <FormControlLabel label="Baki Stok" value={0} control={<Radio />} />
    <FormControlLabel label="Hampiri Tarikh Luput" value={1} control={<Radio />} />
    <FormControlLabel label="Telah Luput" value={2} control={<Radio />} />
  </RadioGroup>
);

FilterOption.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default FilterOption;
