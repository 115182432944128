import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const ReadOnlyToggleTextField = ({ readOnly, InputProps, onChange, ...props }) => (
  <TextField
    {...props}
    readOnly={readOnly}
    InputLabelProps={{ shrink: readOnly ? true : undefined }}
    InputProps={{
      ...InputProps,
      readOnly
    }}
    onChange={!readOnly ? onChange : undefined}
  />
);

ReadOnlyToggleTextField.propTypes = {
  ...TextField.propTypes,
  InputProps: TextField.propTypes.InputProps,
  readOnly: PropTypes.bool
};

ReadOnlyToggleTextField.defaultProps = {
  InputProps: {},
  readOnly: false
};

export default ReadOnlyToggleTextField;
