import {
  doc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
  runTransaction,
  getDoc
} from 'firebase/firestore';
import { deleteObject, ref as bucketRef } from 'firebase/storage';

import { getBucket, getDB, initFirestore } from 'firebaseUtil/db';

import forEachAsync from 'utils/forEachAsync';

let __userId;

export function initStockListData(userId) {
  initFirestore();
  __userId = userId;
}

export function resetStockListData(queryClient) {
  queryClient.resetQueries(['stockList', __userId], { exact: true });
  __userId = undefined;
}

export async function getStockList() {
  const db = getDB();
  const q = query(
    collection(db, 'stockList'),
    where('userId', 'array-contains', __userId)
  );
  const querySnapshot = await getDocs(q);
  const stockList = [];
  querySnapshot.forEach((docData) => {
    // docData.data() is never undefined for query doc snapshots
    const data = docData.data();
    stockList.push({
      stockId: docData.id,
      stockGroupId: data.stockGroupId || '',
      stockName: data.stockName,
      stockCode: data.stockCode,
      stockType: data.stockType,
      daysBeforeExpired: data.daysBeforeExpired,
      unitBeforeRestock: data.unitBeforeRestock
    });
  });

  stockList.sort((item1, item2) => {
    if (item1.stockCode < item2.stockCode) {
      return -1;
    }
    if (item1.stockCode > item2.stockCode) {
      return 1;
    }
    return 0;
  });

  return stockList;
}

export async function addStockList(data) {
  const db = getDB();
  const counterRef = doc(db, 'stockListCounter', __userId);
  const stockListRef = doc(collection(db, 'stockList'));
  const newData = await runTransaction(db, async (transaction) => {
    const docSnap = await transaction.get(counterRef);
    let newCount = 1;
    if (!docSnap.exists()) {
      transaction.set(counterRef, {
        R: 0,
        W: 0,
        F: 0
      });
    } else {
      newCount = docSnap.data()[data.stockType] + 1;
    }

    transaction.update(counterRef, {
      [data.stockType]: newCount
    });

    const stockCode = `${data.stockType}${String(newCount).padStart(3, '0')}`;
    transaction.set(stockListRef, {
      ...data,
      stockCode,
      userId: [__userId, 'superuser']
    });

    return {
      ...data,
      stockCode,
      stockId: stockListRef.id
    };
  });

  return {
    data: newData,
    userId: __userId
  };
}

export async function deleteStock(stockId) {
  const db = getDB();
  const stockRef = doc(db, 'stockList', stockId);
  await deleteDoc(stockRef);

  // delete the variations related to the stockList
  const variationQuery = query(
    collection(db, 'stockVariation'),
    where('userId', 'array-contains', __userId),
    where('stockListId', '==', stockId)
  );
  const variations = [];
  const variationDoc = await getDocs(variationQuery);
  variationDoc.forEach((docData) => {
    variations.push(docData.id);
  });

  if (variations.length) {
    await forEachAsync(variations, async (variationId) => {
      const docRef = doc(db, 'stockVariation', variationId);
      await deleteDoc(docRef);
    });
  }

  // delete the stock cards related to the stockList
  const bucket = getBucket();
  const stockCardQuery = query(
    collection(db, 'stockCard'),
    where('userId', 'array-contains', __userId),
    where('stockListId', '==', stockId)
  );
  const stockCards = [];
  const stockCardDoc = await getDocs(stockCardQuery);
  stockCardDoc.forEach((docData) => {
    stockCards.push(docData.id);
  });

  if (stockCards.length) {
    await forEachAsync(stockCards, async (stockCardId) => {
      const docRef = doc(db, 'stockCard', stockCardId);
      const stockCardDataDoc = await getDoc(docRef);
      if (stockCardDataDoc.exists()) {
        const stockData = stockCardDataDoc.data();
        const { transactions = [] } = stockData;
        await forEachAsync(transactions, async (transaction) => {
          const { documentName } = transaction;
          if (documentName) {
            const fileRef = bucketRef(bucket, `${stockCardId}/${documentName}`);
            try {
              await deleteObject(fileRef);
            } catch (err) {
              if (err.code !== 'storage/object-not-found') {
                throw new Error('Error deleting file');
              }
            }
          }
        });
      }
      await deleteDoc(docRef);
    });
  }

  return { stockId, userId: __userId };
}

export async function changeStockName(data) {
  const db = getDB();
  const stockRef = doc(db, 'stockList', data.stockId);
  await updateDoc(stockRef, {
    stockName: data.stockName,
    stockGroupId: data.stockGroupId,
    daysBeforeExpired: data.daysBeforeExpired,
    unitBeforeRestock: data.unitBeforeRestock
  });

  return {
    ...data,
    userId: __userId
  };
}
