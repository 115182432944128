import * as React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { Add } from '@mui/icons-material';
import { Box, IconButton, Stack, TextField } from '@mui/material';

import { useFeedbackMutation } from 'hooks';

import { CommonDialog } from 'pages/commons';

import { addStockGroup } from 'stux/data-managers/stockGroup';
import { InfoLabel } from 'components/data-display/tooltip';

const tooltip = 'Anda boleh mengkategorikan stok anda mengikut kesesuaian seperti produk, '
 + 'aktiviti perniagaan, stokis, cawangan dan sebagainya.\n\nPilih "Tiada Kategori" '
 + 'sekiranya stok tersebut tidak perlu dikategorikan.';

const StockGroupHeader = ({ stockGroupNames, onNewGroup }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [hasSimilar, setHasSimilar] = React.useState(false);

  const onClose = () => {
    setHasSimilar(false);
    setOpen(false);
  };

  const queryClient = useQueryClient();
  const mutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika menambah kategori. Sila cuba lagi.',
    mutationFn: addStockGroup,
    onSuccessOrError: () => {
      setIsSubmitting(false);
      onClose();
    },
    onSuccess: (newData) => {
      queryClient.setQueryData(
        ['stockGroup', newData.userId],
        (prev) => {
          onNewGroup(newData.data.id);
          return [...prev, newData.data];
        }
      );
    }
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (isSubmitting) return false;

    const data = new FormData(e.target);
    const stockGroup = data.get('stockGroup');

    mutation.mutate({
      label: stockGroup
    });
    return true;
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <InfoLabel
          label="Kategori"
          tooltip={tooltip}
          labelProps={{ color: 'primary', fontWeight: 'bold' }}
        />
        <IconButton color="primary" onClick={() => setOpen(true)}>
          <Add />
        </IconButton>
      </Stack>
      <CommonDialog
        fullWidth
        open={open}
        title="Tambah Kategori"
        formId="form"
        saveLabel="Tambah"
        isSubmitting={isSubmitting}
        onClose={onClose}
      >
        <Box id="form" component="form" onSubmit={onSubmit}>
          <TextField
            required
            fullWidth
            label="Nama Kategori"
            name="stockGroup"
            defaultValue=""
            error={hasSimilar}
            helperText={hasSimilar ? 'Nama kategori telah digunakan' : ''}
            onChange={(e) => {
              setHasSimilar(stockGroupNames.includes(e.target.value));
            }}
          />
        </Box>
      </CommonDialog>
    </>
  );
};

StockGroupHeader.propTypes = {
  stockGroupNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onNewGroup: PropTypes.func.isRequired
};

export default StockGroupHeader;
