import * as React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { Add, ArrowBack, MoreHoriz, Print } from '@mui/icons-material';
import { IconButton, Menu, Stack, Typography } from '@mui/material';

import { IconMenuItem } from 'components/inputs/buttons';

import { useFeedbackMutation } from 'hooks';

import { appendTransaction } from 'stux/data-managers/stockDetail';

import AddTransactionDialog from '../stock-detail/AddTransactionDialog';
import { StockContainer } from '../common';
import { updateTransactionQueries } from '../common/utils';

const StockCardHeader = ({
  title,
  stockGroup,
  stockCardId,
  daysBeforeExpired,
  unitPrice,
  selectedVariants,
  onClickBack,
  onPrint
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const queryClient = useQueryClient();
  const mutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika menambah transaksi. Sila cuba sekali lagi.',
    mutationFn: appendTransaction,
    onSuccessOrError: () => {
      setOpenDialog(false);
    },
    onSuccess: (newData) => {
      updateTransactionQueries(queryClient, newData);
    }
  });

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onAddTransaction = (newData) => {
    mutation.mutate({
      ...newData,
      stockCardId,
      daysBeforeExpired
    });
  };

  return (
    <>
      <StockContainer sx={{ py: 2, px: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton color="primary" onClick={onClickBack}>
              <ArrowBack />
            </IconButton>
            <Stack spacing={0}>
              <Stack direction="row" spacing={1}>
                {!!stockGroup && <Typography color="primary" variant="subtitle2">{`${stockGroup}: `}</Typography>}
                <Typography color="primary" variant="subtitle2">{`${title}`}</Typography>
              </Stack>
              <Typography color="primary" fontWeight="bold">Kad Stok</Typography>
            </Stack>
          </Stack>
          <IconButton
            color="primary"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreHoriz />
          </IconButton>
        </Stack>
      </StockContainer>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onCloseMenu}>
        <IconMenuItem
          label="Tambah Transaksi"
          icon={Add}
          onClick={(e) => {
            e.stopPropagation();
            setOpenDialog(true);
            onCloseMenu();
          }}
        />
        <IconMenuItem
          noDivider
          label="Cetak Kad Stok"
          icon={Print}
          onClick={(e) => {
            e.stopPropagation();
            onCloseMenu();
            onPrint();
          }}
        />
      </Menu>
      <AddTransactionDialog
        disableVariations
        open={openDialog}
        variations={selectedVariants}
        unitPrice={unitPrice}
        onClose={() => setOpenDialog(false)}
        onAddTransaction={onAddTransaction}
      />
    </>
  );
};

StockCardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  stockGroup: PropTypes.string,
  stockCardId: PropTypes.string.isRequired,
  daysBeforeExpired: PropTypes.number.isRequired,
  unitPrice: PropTypes.number.isRequired,
  selectedVariants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClickBack: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired
};

StockCardHeader.defaultProps = {
  stockGroup: undefined
};

export default StockCardHeader;
