import { dateFromString, DAY_IN_MS } from 'utils/formatter';

export default function getStockExpiryData(quantity, expiredDate, todayTime, daysBeforeExpired) {
  const expiryDay = daysBeforeExpired * DAY_IN_MS;
  const expiredTime = dateFromString(expiredDate).getTime();
  const expiryTime = expiredTime - expiryDay;
  let newExpired = 0;
  let newNearExpiry = 0;
  if (todayTime >= expiredTime) {
    newExpired = quantity;
  } else if (todayTime >= expiryTime) {
    newNearExpiry = quantity;
  }

  return { newExpired, newNearExpiry };
}
