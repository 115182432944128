import styled from '@emotion/styled';

export const RootDiv = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100vh;
`;
