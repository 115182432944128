import * as React from 'react';
import PropTypes from 'prop-types';
import { ErrorOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import StockContainer from './StockContainer';

const ErrorPanel = ({ message }) => (
  <StockContainer sx={{ height: '100%' }}>
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        p: 4
      }}
    >
      <ErrorOutline sx={{ opacity: 0.5, fontSize: '8rem', color: 'error.main' }} />
      <Typography color="error">{message}</Typography>
    </Box>
  </StockContainer>
);

ErrorPanel.propTypes = {
  message: PropTypes.string.isRequired
};

export default ErrorPanel;
