import * as React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { Box, ToggleButton } from '@mui/material';

import { useFeedbackMutation } from 'hooks';

import CollapsibleBox from 'components/surfaces/CollapsibleBox';

import { deleteVariation, updateVariation } from 'stux/data-managers/stockDetail';

import { generateUUID } from 'utils/uuid';

import AddVariationDialog from '../AddVariationDialog';

const StockVariantCard = ({
  title,
  variationId,
  selectedVariants,
  variants,
  canDelete,
  sx,
  onSelectVariant
}) => {
  const [open, setOpen] = React.useState(false);

  const queryClient = useQueryClient();
  const updateMutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika mengubah variasi. Sila cuba sekali lagi.',
    mutationFn: updateVariation,
    onSuccessOrError: () => {
      setOpen(false);
    },
    onSuccess: (newData) => {
      queryClient.invalidateQueries({
        queryKey: ['stockCard', newData.userId, newData.stockListId]
      });

      queryClient.setQueryData(
        ['stockDetail', newData.userId, newData.stockListId],
        (prev) => {
          const updatedVariations = prev.variations.map((v) => {
            if (v.variationId !== newData.variationId) return v;
            return { ...v, ...newData.data };
          });
          return {
            ...prev,
            variations: updatedVariations
          };
        }
      );
    }
  });

  const deleteMutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika menghapus variasi. Sila cuba lagi.',
    mutationFn: deleteVariation,
    onSuccessOrError: () => {
      setOpen(false);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['stockDetail', data.userId, data.stockListId],
        (prev) => {
          const newVariations = prev.variations.slice();
          const index = newVariations.findIndex((item) => item.variationId === data.variationId);
          if (index !== -1) {
            newVariations.splice(index, 1);
          }
          return { ...prev, variations: newVariations };
        }
      );
    }
  });

  const onChangeVariation = (newVariation) => {
    const time = new Date().getTime();
    const newVariants = newVariation.variants.map((item, index) => ({
      variantName: item.variantName,
      variantId: item.variantId ?? `${generateUUID()}_${time}_${index}`
    }));

    const variation = {
      variationName: newVariation.variationName,
      variants: newVariants
    };

    updateMutation.mutate({ variationId, variation });
  };

  const onDeleteVariation = () => {
    deleteMutation.mutate({ variationId });
  };

  return (
    <>
      <CollapsibleBox
        isExpand
        title={title}
        contentProps={{ sx: { pt: 1.5 } }}
        style={{ marginTop: 0 }} // need to use style to override stubborn style from Stack
        sx={{ py: 1, ...sx }}
        onEdit={() => setOpen(true)}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :last-child': {
              mb: 1
            },
            '& > :not(:last-child)': {
              mr: 1,
              mb: 1
            }
          }}
        >
          {
            variants.map((item) => (
              <ToggleButton
                color="primary"
                key={item.variantId}
                value={item.variantId}
                selected={selectedVariants.includes(item.variantId)}
                onClick={() => onSelectVariant(variationId, item.variantId)}
              >
                {item.variantName}
              </ToggleButton>
            ))
          }
        </Box>
      </CollapsibleBox>
      <AddVariationDialog
        isUpdate
        open={open}
        variationName={title}
        variants={variants}
        onSubmit={onChangeVariation}
        onDeleteVariation={canDelete ? onDeleteVariation : undefined}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

StockVariantCard.propTypes = {
  sx: PropTypes.shape({}),
  title: PropTypes.string,
  variationId: PropTypes.string.isRequired,
  selectedVariants: PropTypes.arrayOf(PropTypes.string),
  variants: PropTypes.arrayOf(PropTypes.shape({
    variantId: PropTypes.string,
    variantName: PropTypes.string
  })),
  canDelete: PropTypes.bool,
  onSelectVariant: PropTypes.func
};

StockVariantCard.defaultProps = {
  sx: {},
  title: '',
  selectedVariants: [],
  variants: [],
  canDelete: false,
  onSelectVariant: () => undefined
};

export default StockVariantCard;
