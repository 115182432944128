import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { ArrowBack } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';

import { generateUUID } from 'utils/uuid';

import { useFeedbackMutation } from 'hooks';

import { addNewTransaction, addVariation, appendTransaction } from 'stux/data-managers/stockDetail';

import StockDetailHeaderMenu from './StockDetailHeaderMenu';
import AddVariationDialog from '../AddVariationDialog';
import AddTransactionDialog from '../AddTransactionDialog';
import { StockContainer } from '../../common';
import { updateTransactionQueries } from '../../common/utils';

const StockDetailHeader = React.forwardRef(({
  title,
  variations,
  stockCards,
  stockGroup,
  daysBeforeExpired
}, ref) => {
  const navigate = useNavigate();
  const [dialog, setDialog] = React.useState(undefined);

  const queryClient = useQueryClient();
  const variationMutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika menambah variasi baharu. Sila cuba sekali lagi.',
    mutationFn: addVariation,
    onSuccessOrError: () => {
      setDialog(undefined);
    },
    onSuccess: (newData) => {
      queryClient.setQueryData(
        ['stockDetail', newData.userId, newData.stockListId],
        (prev) => {
          const newVariations = [...prev.variations, newData.data];
          return { ...prev, variations: newVariations };
        }
      );
    }
  });

  const existingTransactionMutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika menambah transaksi. Sila cuba sekali lagi.',
    mutationFn: appendTransaction,
    onSuccessOrError: () => {
      setDialog(undefined);
    },
    onSuccess: (newData) => {
      updateTransactionQueries(queryClient, newData);
    }
  });

  const newTransactionMutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika menambah transaksi untuk kad stok baharu. Sila cuba sekali lagi.',
    mutationFn: addNewTransaction,
    onSuccessOrError: () => {
      setDialog(undefined);
    },
    onSuccess: (newData) => {
      queryClient.setQueryData(
        ['stockSummary', newData.userId, newData.stockListId],
        (prev) => {
          if (!prev) return prev;
          return { ...prev, balance: prev.balance + newData.data.balance };
        }
      );

      queryClient.setQueryData(
        ['stockDetail', newData.userId, newData.stockListId],
        (prev) => {
          // when adding a new stockcard, all variantions must be used..
          // so that means, all variations cannot be deleted now
          const newStockCards = [...prev.stockCards, newData.data];
          const newVariations = variations.map((v) => ({ ...v, canDelete: false }));
          return { ...prev, stockCards: newStockCards, variations: newVariations };
        }
      );
    }
  });

  React.useImperativeHandle(ref, () => ({
    openAddVariation: () => {
      setDialog('addVariation');
    },
    openAddTransaction: () => {
      setDialog('addTransaction');
    }
  }), []);

  const onCloseDialog = () => {
    setDialog(undefined);
  };

  const onSubmitVariation = (newVariation) => {
    const time = new Date().getTime();
    const newVariants = newVariation.variants.map((item, index) => ({
      variantName: item.variantName,
      variantId: `${generateUUID()}_${time}_${index}`
    }));

    const variation = {
      variationName: newVariation.variationName,
      variants: newVariants
    };
    variationMutation.mutate({ variation });
  };

  const onAddTransaction = (newTransaction) => {
    const stockCard = stockCards.find((item) => (
      item.variationCode === newTransaction.variationCode
    ));
    if (stockCard) {
      existingTransactionMutation.mutate({
        ...newTransaction,
        daysBeforeExpired,
        stockCardId: stockCard.stockCardId
      });
    } else {
      newTransactionMutation.mutate({ ...newTransaction, daysBeforeExpired });
    }
  };

  return (
    <StockContainer sx={{ py: 2, px: 1 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton color="primary" onClick={() => navigate('/stocks')}>
            <ArrowBack />
          </IconButton>
          {!!stockGroup && <Typography color="primary" fontWeight="bold">{`${stockGroup}: `}</Typography>}
          <Typography color="primary" fontWeight="bold">{`${title}`}</Typography>
        </Stack>
        {
          !!title && (
            <>
              <StockDetailHeaderMenu
                onAddVariation={() => setDialog('addVariation')}
                onAddTransaction={() => setDialog('addTransaction')}
              />
              <AddVariationDialog
                open={dialog === 'addVariation'}
                onSubmit={onSubmitVariation}
                onClose={onCloseDialog}
              />
              <AddTransactionDialog
                open={dialog === 'addTransaction'}
                variations={variations}
                onClose={onCloseDialog}
                onAddTransaction={onAddTransaction}
              />
            </>
          )
        }
      </Stack>
    </StockContainer>
  );
});

StockDetailHeader.propTypes = {
  title: PropTypes.string,
  stockGroup: PropTypes.string,
  variations: PropTypes.arrayOf(PropTypes.shape({})),
  daysBeforeExpired: PropTypes.number,
  stockCards: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

StockDetailHeader.defaultProps = {
  title: '',
  stockGroup: undefined,
  variations: [],
  daysBeforeExpired: undefined
};

StockDetailHeader.displayName = 'StockDetailHeader';

export default StockDetailHeader;
