import * as React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Typography } from '@mui/material';

import { CommonDialog } from 'pages/commons';

const ComfirmDeleteDialog = ({ open, type, date, onCancel, onOk }) => {
  const dateString = format(new Date(date), 'dd/MM/yyyy');

  return (
    <CommonDialog
      fullWidth
      open={open}
      title="Hapus Transaksi"
      saveLabel="Ya, saya pasti"
      onClose={onCancel}
      onSave={onOk}
    >
      <Typography component="span">
        Adakah pasti untuk menghapus transaksi&nbsp;
      </Typography>
      <Typography component="span" fontWeight="bold">
        {type}
      </Typography>
      <Typography component="span">&nbsp;yang telah dibuat pada&nbsp;</Typography>
      <Typography component="span" fontWeight="bold" fontStyle="italic">
        {dateString}
      </Typography>
      <Typography component="span">&nbsp;ini?</Typography>
    </CommonDialog>
  );
};

ComfirmDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired
};

export default ComfirmDeleteDialog;
