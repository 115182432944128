import * as React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, TextField, Typography } from '@mui/material';

import { useFeedbackMutation } from 'hooks';

import { IconMenuItem } from 'components/inputs/buttons';

import { CommonDialog } from 'pages/commons';

import { changeStockGroupName, deleteStockGroup } from 'stux/data-managers/stockGroup';

const StockGroupItemMenu = ({ id, label, stockGroupNames, onUpdate, onChangeStockGroup }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [openChangeName, setOpenChangeName] = React.useState(false);
  const [openDeleteGroup, setOpenDeleteGroup] = React.useState(false);
  const [hasSimilar, setHasSimilar] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const btn = React.useRef(null);

  const onClose = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    setHasSimilar(false);
    setOpenChangeName(false);
    setOpenDeleteGroup(false);
  };

  const onOpenMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const onCloseMenu = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setAnchorEl(null);
  };

  const queryClient = useQueryClient();
  const changeMutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika mengubah nama kategori stok. Sila cuba lagi.',
    mutationFn: changeStockGroupName,
    onSuccessOrError: () => {
      setIsSubmitting(false);
      onClose();
    },
    onSuccess: (newData) => {
      queryClient.setQueryData(
        ['stockGroup', newData.userId],
        (prev) => {
          const prevData = prev.find((item) => item.id === newData.id);
          prevData.label = newData.label;

          // somehow the component will not re-render after we mutate the data
          // even though we return a completely new data e.g:
          // return prev.map((val) => ({ ...val }))
          //
          // in other places like StockGroupHeader, mutating the data will re-render the component..
          // the only different there is that the data is added with new item..
          // so to handle that, we call the function 'onUpdate' where it'll forcefully re-render
          // the interested component
          onUpdate();
          return prev;
        }
      );
    }
  });

  const onSubmitChangeName = (e) => {
    e.preventDefault();
    if (isSubmitting) return false;

    const data = new FormData(e.target);
    const stockGroup = data.get('stockGroup');
    if (stockGroup === label) return false;

    setIsSubmitting(true);
    changeMutation.mutate({
      id,
      label: stockGroup
    });
    return true;
  };

  const deleteMutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika menghapus kategori stok. Sila cuba lagi.',
    mutationFn: deleteStockGroup,
    onSuccessOrError: () => {
      setIsSubmitting(false);
      onClose();
    },
    onSuccess: (newData) => {
      queryClient.setQueryData(
        ['stockGroup', newData.userId],
        (prev) => {
          const index = prev.findIndex((item) => item.id === newData.id);
          if (index !== -1) {
            prev.splice(index, 1);
          }

          onUpdate(newData.id);
          return prev;
        }
      );

      queryClient.setQueryData(
        ['stockList', newData.userId],
        (prev) => {
          let isDeleted = false;
          const newItems = prev.map((item) => {
            const newItem = { ...item };
            if (newItem.stockGroupId === newData.id) {
              isDeleted = true;
              newItem.stockGroupId = '';
            }

            return newItem;
          });

          if (isDeleted) {
            onChangeStockGroup();
          }
          return newItems;
        }
      );
    }
  });

  const onDeleteGroup = () => {
    if (isSubmitting) return false;

    setIsSubmitting(true);
    deleteMutation.mutate({ id });
    return true;
  };

  return (
    <>
      <IconButton ref={btn} onClick={onOpenMenu}>
        <MoreVert />
      </IconButton>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onCloseMenu}>
        <IconMenuItem
          label="Ubah Nama Kategori"
          icon={Edit}
          sx={{ py: 1 }}
          onClick={(e) => {
            e.stopPropagation();
            onCloseMenu();
            setOpenChangeName(true);
          }}
        />
        <IconMenuItem
          label="Hapus Kategori"
          icon={Delete}
          sx={{ py: 1 }}
          onClick={(e) => {
            e.stopPropagation();
            onCloseMenu();
            setOpenDeleteGroup(true);
          }}
        />
      </Menu>
      <CommonDialog
        fullWidth
        open={openChangeName}
        title="Ubah Nama Kategori"
        formId="changeForm"
        isSubmitting={isSubmitting}
        onClose={onClose}
      >
        <Box id="changeForm" component="form" onSubmit={onSubmitChangeName}>
          <TextField
            required
            fullWidth
            label="Nama Kategori"
            name="stockGroup"
            defaultValue={label}
            error={hasSimilar}
            helperText={hasSimilar ? 'Nama kategori telah digunakan' : ''}
            onChange={(e) => {
              setHasSimilar(e.target.value !== label && stockGroupNames.includes(e.target.value));
            }}
          />
        </Box>
      </CommonDialog>
      <CommonDialog
        fullWidth
        open={openDeleteGroup}
        title="Hapus Kategori"
        cancelLabel="Tidak"
        saveLabel="Ya"
        isSubmitting={isSubmitting}
        onClose={onClose}
        onSave={onDeleteGroup}
      >
        <Typography>
          {`Adakah anda pasti untuk menghapuskan kategori "${label}"? `}
        </Typography>
        <Typography sx={{ mt: 1 }}>
          {
            `Stok yang berada di dalam kategori "${label}" akan `
            + 'dikategorikan sebagai "Tiada Kategori".'
          }
        </Typography>
      </CommonDialog>
    </>
  );
};

StockGroupItemMenu.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  stockGroupNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onChangeStockGroup: PropTypes.func.isRequired
};

export default StockGroupItemMenu;
