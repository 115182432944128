import * as React from 'react';
import PropTypes from 'prop-types';
import createCache from '@emotion/cache';
import { CacheProvider, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { CssBaseline, StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/material';

import createTheme from './createTheme';

const theme = createTheme();

// this below cache to supress the warning by emotion for using `first-child`, `last-child`
// and `nth-child`
const emotionCache = createCache({ key: 'sto' });
emotionCache.compat = true;

const ThemeProvider = ({ children, ...props }) => (
  <StyledEngineProvider injectFirst>
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <CacheProvider value={emotionCache}>
        <EmotionThemeProvider theme={theme} {...props}>
          {children}
        </EmotionThemeProvider>
      </CacheProvider>
    </MUIThemeProvider>
  </StyledEngineProvider>
);

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeProvider;
