import * as React from 'react';
import { Button, Card, MenuItem } from '@mui/material';

import { ReadOnlyToggleTextField } from 'components/inputs/textfields';

import ProfileAction from 'stux/actions/ProfileAction';
import UserAction from 'stux/actions/UserAction';
import ProfileStore from 'stux/stores/ProfileStore';
import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

import ProfileCard from './ProfileCard';
import UpdatePasswordDialog from './UpdatePasswordDialog';

const PersonalProfile = ({ ...props }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [editable, setEditable] = React.useState(false);
  const [emel, setEmel] = React.useState('');
  const [fullname, setFullname] = React.useState('');
  const [nickname, setNickname] = React.useState('');
  const [sex, setSex] = React.useState(-1);
  const [tel, setTel] = React.useState('');
  useStux(UserStore, (state) => {
    setEmel(state.username ?? '');
    setNickname(state.displayName ?? '');
  }, true, 'displayName');
  useStux(ProfileStore, (state) => {
    setFullname(state.fullName ?? '');
    setSex(state.sex ?? '');
    setTel(state.personalPhoneNo ?? '');
  }, true, 'sex', 'fullName', 'personalPhoneNo');

  const onSave = () => {
    setEditable(false);

    const { displayName } = UserStore.state;
    const { fullName, personalPhoneNo, sex: profileSex } = ProfileStore.state;

    if (
      nickname !== displayName || fullname !== fullName
      || sex !== profileSex || tel !== personalPhoneNo
    ) {
      const newNickname = nickname !== displayName ? nickname : undefined;
      ProfileAction.updatePersonalProfile(
        fullname,
        newNickname,
        sex,
        tel,
        UserAction.updateDisplayname
      );
    }
  };

  return (
    <>
      <ProfileCard
        {...props}
        editMode={editable}
        onSave={onSave}
        onToggleEdit={() => {
          setEditable((prev) => {
            const newState = !prev;
            if (!newState) {
              const { displayName } = UserStore.state;
              const { fullName, personalPhoneNo, sex: profileSex } = ProfileStore.state;
              setNickname(displayName ?? '');
              setFullname(fullName ?? '');
              setSex(profileSex ?? '');
              setTel(personalPhoneNo ?? '');
            }
            return newState;
          });
        }}
      >
        <ReadOnlyToggleTextField
          fullWidth
          label="Nama Penuh"
          readOnly={!editable}
          value={fullname}
          onChange={(e) => setFullname(e.currentTarget.value)}
        />
        <ReadOnlyToggleTextField
          fullWidth
          label="Nama Panggilan"
          readOnly={!editable}
          value={nickname}
          onChange={(e) => setNickname(e.currentTarget.value)}
        />
        <ReadOnlyToggleTextField
          fullWidth
          select
          label="Jantina"
          readOnly={!editable}
          value={sex === -1 ? '' : sex}
          onChange={(e) => setSex(e.target.value)}
        >
          <MenuItem value={0}>Lelaki</MenuItem>
          <MenuItem value={1}>Perempuan</MenuItem>
        </ReadOnlyToggleTextField>
        <ReadOnlyToggleTextField
          fullWidth
          readOnly
          label="Emel"
          value={emel}
        />
        <ReadOnlyToggleTextField
          fullWidth
          label="No Telefon"
          readOnly={!editable}
          value={tel}
          style={{ marginBottom: '16px' }}
          onChange={(e) => setTel(e.currentTarget.value)}
        />
        <Button onClick={() => setOpenDialog(true)}>Tukar Katalaluan</Button>
      </ProfileCard>
      {openDialog && <UpdatePasswordDialog onClose={() => setOpenDialog(false)} />}
    </>
  );
};

PersonalProfile.propTypes = {
  ...Card.propTypes
};

export default PersonalProfile;
