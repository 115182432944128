import * as React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { Stack } from '@mui/material';

import { useWorkingModal } from 'components/surfaces';

import { useFeedbackMutation } from 'hooks';

import { deleteTransaction, editTransaction } from 'stux/data-managers/stockCard';

import VariationCard from './VariationCard';
import TransactionDataGrid from './TransactionDataGrid';
import AddTransactionDialog from '../stock-detail/AddTransactionDialog';
import { StockContainer } from '../common';
import { deleteTransactionFromQueries, editTransactionFromQueries } from '../common/utils';

const StockCardData = ({
  isLoading,
  variantData,
  transactionData,
  unitPrice,
  daysBeforeExpired,
  selectedVariants
}) => {
  const workingModalId = React.useRef();
  const [editData, setEditData] = React.useState();
  const { showWorkingModal, hideWorkingModal } = useWorkingModal();

  const queryClient = useQueryClient();
  const deleteMutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika menghapus transaksi. Sila cuba sekali lagi.',
    mutationFn: deleteTransaction,
    onSuccessOrError: () => {
      hideWorkingModal(workingModalId.current);
      workingModalId.current = undefined;
    },
    onSuccess: (newData) => {
      deleteTransactionFromQueries(queryClient, newData);
    }
  });

  const editMutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika meminda transaksi. Sila cuba sekali lagi.',
    mutationFn: editTransaction,
    onSuccessOrError: () => {
      setEditData(undefined);
    },
    onSuccess: (newData) => {
      editTransactionFromQueries(queryClient, newData);
    }
  });

  const onDeleteTransaction = (data) => {
    workingModalId.current = showWorkingModal({ indeterminate: true });
    deleteMutation.mutate({ ...data, daysBeforeExpired });
  };

  const onAddTransaction = (data) => {
    editMutation.mutate({ ...data, daysBeforeExpired });
  };

  return (
    <>
      <StockContainer
        fullHeightLoading
        isLoading={isLoading}
        style={{ marginBottom: '1rem' }}
        sx={{ py: 2, overflow: 'hidden', height: '100%' }}
      >
        <Stack spacing={2} sx={{ height: '100%' }}>
          {!!variantData && <VariationCard data={variantData} />}
          <TransactionDataGrid
            data={transactionData ?? []}
            onDeleteTransaction={onDeleteTransaction}
            onChangeTransaction={(data) => {
              setEditData(data);
            }}
          />
        </Stack>
      </StockContainer>
      <AddTransactionDialog
        disableVariations
        title="Pinda Maklumat Transaksi"
        open={!!editData}
        id={editData?.id}
        unitPrice={unitPrice}
        variations={selectedVariants}
        onAddTransaction={onAddTransaction}
        quantity={editData?.quantity}
        docRef={editData?.documentRef}
        docName={editData?.documentName}
        docTypeId={editData?.documentType}
        expiredDate={editData?.expiredDate}
        transactionDate={editData?.transactionDate}
        transactionTypeId={editData?.transactionType}
        onClose={() => setEditData(undefined)}
      />
    </>
  );
};

StockCardData.propTypes = {
  isLoading: PropTypes.bool,
  variantData: PropTypes.shape({}),
  transactionData: PropTypes.arrayOf(PropTypes.shape({})),
  daysBeforeExpired: PropTypes.number,
  unitPrice: PropTypes.number.isRequired,
  selectedVariants: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

StockCardData.defaultProps = {
  isLoading: false,
  variantData: undefined,
  transactionData: undefined,
  daysBeforeExpired: 0
};

export default StockCardData;
