import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AppBar, useScrollTrigger } from '@mui/material';

import styles from './ColorScrolledAppBar.module.css';

const ColorScrolledAppBar = ({
  sx,
  position,
  elevation,
  scrolledElevation,
  fontColor,
  scrolledFontColor,
  backgroundColor,
  scrolledBackgroundColor,
  children,
  scrollTrigger,
  ...props
}) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return (
    <AppBar
      position={position}
      elevation={scrollTrigger && !trigger ? elevation : scrolledElevation}
      className={classNames(
        styles.background,
        // { [styles.engrave]: scrollTrigger && !trigger },
        { [styles.initial]: scrollTrigger && !trigger },
        { [styles.scrolled]: scrollTrigger && trigger },
        { [styles.bgColorTransition]: backgroundColor && scrolledBackgroundColor },
        { [styles.fontColorTransition]: fontColor && scrolledFontColor }
      )}
      sx={{
        ...(sx ?? {}),
        color: scrollTrigger && !trigger ? fontColor : scrolledFontColor,
        backgroundColor: scrollTrigger && !trigger ? backgroundColor : scrolledBackgroundColor
      }}
      {...props}
    >

      {children}
    </AppBar>
  );
};

ColorScrolledAppBar.propTypes = {
  sx: AppBar.propTypes.sx,
  elevation: PropTypes.number,
  scrolledElevation: PropTypes.number,
  fontColor: PropTypes.string,
  scrolledFontColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  scrolledBackgroundColor: PropTypes.string,
  position: PropTypes.oneOf(['static', 'fixed', 'sticky', 'absolute', 'relative']),
  children: PropTypes.element,
  scrollTrigger: PropTypes.bool
};

ColorScrolledAppBar.defaultProps = {
  sx: undefined,
  position: 'fixed',
  elevation: 0,
  scrolledElevation: 4,
  fontColor: undefined,
  scrolledFontColor: undefined,
  backgroundColor: undefined,
  scrolledBackgroundColor: undefined,
  children: null,
  scrollTrigger: false
};

export default ColorScrolledAppBar;
