import { dispatchAction } from '../dispatchers/AppDispatcher';

export default class UserAction {
  static SIGN_IN = Symbol('signIn');
  static SIGN_UP = Symbol('signUp');
  static SIGN_OUT = Symbol('signOut');
  static CHECK_AUTH = Symbol('checkAuth');
  static FINISH_EMAIL_VERIFICATION = Symbol('finishEmailVerification');
  static UPDATE_DISPLAYNAME = Symbol('updateDisplayname');
  static CHANGE_PASSWORD = Symbol('changePassword');

  static signIn(email, password) {
    dispatchAction(UserAction.SIGN_IN, { email, password });
  }

  static signUp(email, password) {
    dispatchAction(UserAction.SIGN_UP, { email, password });
  }

  static signOut() {
    dispatchAction(UserAction.SIGN_OUT);
  }

  static finishEmailVerification() {
    dispatchAction(UserAction.FINISH_EMAIL_VERIFICATION);
  }

  static updateDisplayname(displayName) {
    dispatchAction(UserAction.UPDATE_DISPLAYNAME, { displayName });
  }

  static changePassword(oldPassword, newPassword) {
    dispatchAction(UserAction.CHANGE_PASSWORD, { oldPassword, newPassword });
  }
}
