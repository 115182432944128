import * as React from 'react';
import PropTypes from 'prop-types';
import { Add } from '@mui/icons-material';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';

import { DeletableTextField } from 'components/inputs/textfields';

import { CommonDialog } from 'pages/commons';

const AddVariationDialog = ({
  open,
  isUpdate,
  variationName,
  variants,
  onClose,
  onSubmit,
  onDeleteVariation
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState('');
  const placeholders = React.useRef(['Cth: Biru', 'Cth: Merah', 'Cth: Hijau', 'Cth: Kuning']);
  const keyCount = React.useRef(2);
  const deletedVariants = React.useRef([]);

  // needs to use useLayoutEffect so that the variants textfield will be updated
  // correctly..if not, it'll not show anything because its defaultValue been set with
  // empty string and by the time useEffect finished (useEffect is async), the defaultValue
  // cannot be set again with the value from `items` state
  React.useLayoutEffect(() => {
    if (!open) {
      deletedVariants.current = [];
      keyCount.current = 2;
      setItems([{
        key: 0,
        variantId: undefined,
        variantName: ''
      }, {
        key: 1,
        variantId: undefined,
        variantName: ''
      }]);
      setErrorMsg('');
      setIsSubmitting(false);
    } else {
      deletedVariants.current = [];
      keyCount.current = variants.length;
      setItems(variants.map((v, i) => ({
        ...v,
        key: i
      })));
    }
  }, [open, variants]);

  const onSubmitVariation = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const allVariants = data.getAll('variasi');
    const newVariants = allVariants.filter((val) => !!val);
    const values = [...new Set(newVariants)];
    const hasNoValue = values.length < 1;
    const duplicated = values.length !== newVariants.length;
    if (hasNoValue) setErrorMsg('Perlukan sekurang-kurangnya satu variasi');
    else if (duplicated) setErrorMsg('Senarai variasi mestilah unik');
    else setErrorMsg('');
    if (hasNoValue || duplicated) return false;

    setIsSubmitting(true);
    const newVariationName = data.get('variationName');
    const newVariantsObj = allVariants
      .map((val, index) => ({
        // assign back the variantId (if any)
        variantId: items[index]?.variantId,
        variantName: val
      }))
      .filter((val) => !!val.variantName) // remove any empty variantName
      .map((val) => {
        // if the new variants contains the same variant name which had just
        // been deleted, then use the deleted variant so that its ID
        // is still similar
        const sameVariant = deletedVariants.current.find((dv) => (
          dv.variantName === val.variantName
        ));
        if (sameVariant) return { ...sameVariant };
        return val;
      });

    onSubmit({
      variationName: newVariationName,
      variants: newVariantsObj
    });

    return true;
  };

  const onClickDeleteVariation = () => {
    setIsSubmitting(true);
    onDeleteVariation();
  };

  const disableDeletion = items.length === 1;
  return (
    <CommonDialog
      fullWidth
      formId="form"
      title="Tambah Variasi"
      thirdActionLabel="Hapus Variasi"
      open={open}
      isSubmitting={isSubmitting}
      onClose={onClose}
      onThirdAction={onDeleteVariation ? onClickDeleteVariation : undefined}
    >
      <Box
        id="form"
        component="form"
        sx={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={onSubmitVariation}
      >
        <TextField
          required
          fullWidth
          label="Nama Jenis Variasi"
          name="variationName"
          placeholder="Cth: Warna, Saiz, Model No"
          defaultValue={variationName}
        />
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 4 }}>
          <Typography fontWeight="bold" color="primary">Senarai Variasi *</Typography>
          <IconButton
            onClick={() => {
              setItems((prev) => {
                prev.push({
                  key: keyCount.current++,
                  variantId: undefined,
                  variantName: ''
                });
                return prev.slice();
              });
            }}
          >
            <Add />
          </IconButton>
        </Stack>
        <Typography color="error" fontStyle="italic" variant="caption" sx={{ mt: -1 }}>
          {errorMsg}
        </Typography>
        <Stack spacing={2} sx={{ mt: 0.5 }}>
          {
            items.map((item, index) => (
              <DeletableTextField
                fullWidth
                name="variasi"
                key={item.key}
                label={`Variasi ${index + 1}`}
                disableDeletion={isUpdate || disableDeletion}
                placeholder={placeholders.current[index % 4]}
                defaultValue={item.variantName}
                onDelete={() => {
                  setItems((prev) => {
                    const deleted = prev.splice(index, 1);
                    if (deleted.length && deleted[0].variantId) {
                      deletedVariants.current.push(deleted[0]);
                    }
                    return prev.slice();
                  });
                }}
              />
            ))
          }
        </Stack>
      </Box>
    </CommonDialog>
  );
};

AddVariationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  variationName: PropTypes.string,
  variants: PropTypes.arrayOf(
    PropTypes.shape({ variantId: PropTypes.string, variantName: PropTypes.string })
  ),
  onSubmit: PropTypes.func,
  onDeleteVariation: PropTypes.func
};

AddVariationDialog.defaultProps = {
  isUpdate: false,
  variationName: '',
  variants: [{ variantId: undefined, variantName: '' }, { variantId: undefined, variantName: '' }],
  onSubmit: () => undefined,
  onDeleteVariation: undefined
};

export default AddVariationDialog;
