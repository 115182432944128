import * as React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@mui/material';

import { useForceUpdate } from 'hooks';

import StockGroupHeader from './StockGroupHeader';
import StockGroupItem from './StockGroupItem';
import { StockContainer } from '../../common';

const StockGroupList = ({ data, isLoading, onChangeGroup, onUpdateStockGroupItems }) => {
  const [tabValue, setTabValue] = React.useState('all-group');
  const forceUpdate = useForceUpdate();

  const onSelectGroup = (val) => {
    setTabValue(val);
    onChangeGroup(val);
  };

  const onUpdateGroup = (deletedId) => {
    if (deletedId && deletedId === tabValue) {
      setTabValue('no-group');
      onChangeGroup('no-group');
    } else {
      forceUpdate();
    }
  };

  const stockGroupNames = data.map(({ label }) => label);
  return (
    <StockContainer isLoading={isLoading}>
      <StockGroupHeader stockGroupNames={stockGroupNames} onNewGroup={onSelectGroup} />
      <Tabs
        scrollButtons
        allowScrollButtonsMobile
        orientation="vertical"
        variant="scrollable"
        selectionFollowsFocus={false}
        value={tabValue}
        sx={{
          mt: '0 !important',
          height: 242,
          '& .MuiTabScrollButton-root': {
            height: 25
          }
        }}
      >
        <StockGroupItem divider label="Semua kategori" value="all-group" onSelect={onSelectGroup} />
        <StockGroupItem divider label="Tiada kategori" value="no-group" onSelect={onSelectGroup} />
        {
          data.map(({ id, label }) => (
            <StockGroupItem
              divider
              key={`${id}-${label}`}
              label={label}
              value={id}
              stockGroupNames={stockGroupNames}
              onSelect={onSelectGroup}
              onUpdate={onUpdateGroup}
              onChangeStockGroup={onUpdateStockGroupItems}
            />
          ))
        }
      </Tabs>
    </StockContainer>
  );
};

StockGroupList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onChangeGroup: PropTypes.func.isRequired,
  onUpdateStockGroupItems: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string
  })).isRequired
};

export default StockGroupList;
