import {
  getAuth,
  signOut as fireBaseSignOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider
} from 'firebase/auth';

import UserError from 'utils/UserError';

export async function checkAuth(fn) {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    const state = {
      isIdentified: true,
      signInError: 0,
      signupError: 0
    };
    if (user) {
      fn({
        ...state,
        userId: user.uid,
        username: user.email,
        displayName: user.displayName,
        accessToken: user.accessToken,
        refreshToken: user.stsTokenManager.refreshToken,
        expirationTime: user.stsTokenManager.expirationTime,
        isAuthenticated: user.emailVerified
      });
    } else {
      fn({
        ...state,
        username: '',
        displayName: '',
        isAuthenticated: false
      });
    }
  });
}

export async function changePassword(email, oldPassword, newPassword) {
  const auth = getAuth();
  try {
    const user = auth.currentUser;
    const cred = EmailAuthProvider.credential(email, oldPassword);
    await reauthenticateWithCredential(user, cred);
    await updatePassword(user, newPassword);
    return {
      changePassError: 0
    };
  } catch (err) {
    const isWrongPass = err.code === 'auth/wrong-password';
    const errCode = isWrongPass ? 'invalid old password' : err.code;
    return {
      changePassError: UserError.getErrorId(errCode)
    };
  }
}

export async function signUp(email, password) {
  const auth = getAuth();
  try {
    await createUserWithEmailAndPassword(auth, email, password);
    return { isVerifyingEmail: true };
  } catch (err) {
    // console.error('ERROR', UserError.getErrorId(err.code), err.code, err.message);
    return {
      signupError: UserError.getErrorId(err.code)
    };
  }
}

export async function verifyEmail() {
  const auth = getAuth();
  const actionCodeSettings = {
    url: `${window.location.origin}/login`,
    handleCodeInApp: true
  };

  try {
    await sendEmailVerification(auth.currentUser, actionCodeSettings);
  } catch (err) {
    // console.error('ERROR sending email verification:', err);
  }
}

export async function signIn(email, password) {
  const auth = getAuth();
  try {
    // we set the persistence to be only on browse session..
    // that means, when the user close the browser, it'll be automatically signed out..
    await setPersistence(auth, browserSessionPersistence);

    // once the user is signed in, firebase will update the token peridiocally (i.e: when the
    // token is about to expire)..but to enable that feature, we must:
    // 1) go to: https://console.developers.google.com/apis/credentials
    // 2) click on the API key (e.g: Browser key (auto created by Firebase))
    // 3) on the 'API restrictions', check the 'Restrict key' checkbox
    // 4) in the dropdown below it, select:
    //    * Identity Toolkit API
    //    * Token Service API
    //    * Firebase Installations API
    //    * Firebase Management API (if enable Google Analytics)
    // 5) click 'Save'
    //
    // see:
    //   - creating API Keys: https://firebase.google.com/docs/projects/api-keys
    //   - securing API keys: https://medium.com/@devesu/how-to-secure-your-firebase-project-even-when-your-api-key-is-publicly-available-a462a2a58843
    const { user } = await signInWithEmailAndPassword(auth, email, password);

    if (!user.emailVerified) {
      return {
        signInError: UserError.getErrorId('auth/email-not-verified')
      };
    }
    return {
      isAuthenticated: user.emailVerified
    };
  } catch (err) {
    // console.error('ERROR', UserError.getErrorId(err.code), err.code, err.message);
    return {
      signInError: UserError.getErrorId(err.code)
    };
  }
}

export async function resetPassword(email) {
  try {
    const actionCodeSettings = {
      url: `${window.location.origin}/login`,
      handleCodeInApp: true
    };
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email, actionCodeSettings);
    return 'success';
  } catch (err) {
    return 'error';
  }
}

export async function signOut() {
  const auth = getAuth();
  try {
    await fireBaseSignOut(auth);
  } catch (err) {
    // console.error('Sign out error:', err.code);
  }
}
