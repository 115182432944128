import * as React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';

import SellingPointSummary from './SellingPointSummary';
import PointCard from './PointCard';
import Shortcut from './Shortcut';
import RecordedLectures from './RecordedLectures';
import HitungUntung from './HitungUntung';
import EGuide from './EGuide';
import GetStarted from './GetStarted';
import { Container, SlantedDiv } from './styles';

const HomePage = () => (
  <Stack sx={{ backgroundColor: 'white' }}>
    <SellingPointSummary>
      <Shortcut />
      <Divider sx={{ mb: '1.5rem !important', borderColor: 'rgba(255, 255, 255, 0.7)' }} />
      <Stack
        justifyContent="center"
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 4, sm: 2 }}
        sx={{ mt: 4 }}
      >
        <PointCard
          title="Recorded Lectures"
          main="Easy-to-follow lectures presented by accredited instructors are
                now available for you."
          sub="Any time. Any place."
          pointNumber={1}
        />
        <PointCard
          title="STOM (Stock Management)"
          main="An easy-friendly apps for maintaining an optimal business stock."
          sub="No hustle. No bustle."
          pointNumber={2}
        />
        <PointCard
          title="Stock Management and Inventory Control e-Guidebook"
          main="Expand your knowledge in recording and managing business stock."
          sub="Curated. Augmented."
          pointNumber={3}
        />
      </Stack>
    </SellingPointSummary>
    <Container>
      <SlantedDiv />
      <SlantedDiv style={{ transform: 'rotateY(180deg)' }} />
      <SlantedDiv />
      <SlantedDiv style={{ transform: 'rotateY(180deg)' }} />
    </Container>
    <Stack justifyContent="center" alignItems="center" spacing={3} sx={{ width: '100%', pt: 4 }}>
      <RecordedLectures />
      <HitungUntung />
      <EGuide />
    </Stack>
    <GetStarted />
    <Box sx={{ mt: 2, backgroundColor: 'secondary.dark' }}>
      <Typography sx={{ p: 2, textAlign: 'center', color: 'lightgrey' }}>
        {`© ${new Date().getFullYear()} STOM`}
      </Typography>
    </Box>
  </Stack>
);

export default HomePage;
