import { initializeApp, getApps } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const devConfig = {
  apiKey: 'AIzaSyCddF3BCe_z165Ko6Nvjyda6Jshfo1ioms',
  authDomain: 'dev-hitung-untung.firebaseapp.com',
  projectId: 'dev-hitung-untung',
  storageBucket: 'dev-hitung-untung.appspot.com',
  messagingSenderId: '54733528867',
  appId: '1:54733528867:web:5b0328cccbd7bb6f563696',
  measurementId: 'G-62JEBBEQ0Z'
};

const stageConfig = {
  apiKey: 'AIzaSyAvpkDED2IcSGjq8m1suGAi1F814KBiAfs',
  authDomain: 'staging-hitung-untung.firebaseapp.com',
  projectId: 'staging-hitung-untung',
  storageBucket: 'staging-hitung-untung.appspot.com',
  messagingSenderId: '45635495663',
  appId: '1:45635495663:web:223c83a35ac483d80a1d1a',
  measurementId: 'G-8ZHF85Q7C2'
};

const prodConfig = {
  apiKey: 'AIzaSyAFuFDUEFEznUSh546WvxFlOJyYInQCVjg',
  authDomain: 'hitung-untung.firebaseapp.com',
  projectId: 'hitung-untung',
  storageBucket: 'hitung-untung.appspot.com',
  messagingSenderId: '191363569129',
  appId: '1:191363569129:web:a9235f4e3c72511fab6778',
  measurementId: 'G-8NWQ2K9JBP'
};

let __app;

export function initFirebaseApp() {
  if (!getApps().length) {
    let config;
    const env = process.env.REACT_APP_ENV;
    switch (env) {
      case 'production':
        config = prodConfig;
        break;
      case 'staging':
        config = stageConfig;
        break;
      default:
        config = devConfig;
        break;
    }
    __app = initializeApp(config);

    if (env !== 'development') {
      getAnalytics(__app);
    }
  }
}

export function getApp() {
  if (!__app) {
    initFirebaseApp();
  }

  return __app;
}
