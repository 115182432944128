import * as React from 'react';
import PropTypes from 'prop-types';
import msLocale from 'date-fns/locale/ms';
import roundedToFixed from 'round-tofixed';
import { format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import { generateUUID } from 'utils/uuid';
import { dateFromString } from 'utils/formatter';

import { NumberTextField, UploadTextField } from 'components/inputs/textfields';

import { CommonDialog } from 'pages/commons';
import {
  DELETED_FILE,
  documentTypes,
  transactionIns,
  transactionInTypes,
  transactionOuts,
  transactionOutTypes
} from 'pages/constants/transaction';

const getTransactionType = (inOut, transactionTypeId) => {
  if (!transactionTypeId) return '';
  const list = inOut === 'in' ? transactionIns : transactionOuts;
  return list.includes(transactionTypeId) ? transactionTypeId : '';
};

const getDefaultQuantity = (quantity) => {
  if (!quantity) return '';
  return Math.abs(Number(quantity));
};

const AddTransactionDialog = ({
  open,
  title,
  id,
  stockCardId,
  variations,
  docRef,
  quantity,
  unitPrice,
  expiredDate,
  transactionTypeId,
  disableVariations,
  transactionDate,
  docTypeId,
  docName,
  onAddTransaction,
  onClose
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasExpiredDate, setHasExpiredDate] = React.useState(false);
  const [date, setDate] = React.useState(new Date());
  const [currTransactionDate, setCurrTransactionDate] = React.useState(new Date());
  const [docTypes, setDocTypes] = React.useState([]);
  const [hasDocType, setHasDocType] = React.useState(false);
  const [inOut, setInOut] = React.useState('in');
  const [transactionType, setTransactionType] = React.useState('');
  const docFile = React.useRef();

  React.useEffect(() => {
    if (!open) {
      setIsSubmitting(false);
      setHasExpiredDate(false);
      setDate(new Date());
      setCurrTransactionDate(new Date());
      setDocTypes([]);
      setHasDocType(false);
      setInOut('in');
      setTransactionType('');
      docFile.current = undefined;
    } else {
      let d = new Date();
      if (expiredDate) {
        d = dateFromString(expiredDate);
      }
      setHasExpiredDate(!!expiredDate);
      setDate(d);

      setHasDocType(docTypeId && docTypeId !== 'noDoc');
      if (transactionTypeId) {
        setDocTypes(documentTypes[transactionTypeId]);

        const initialInOut = transactionOuts.includes(transactionTypeId) ? 'out' : 'in';
        setInOut(initialInOut);
        setTransactionType(getTransactionType(initialInOut, transactionTypeId));
      }

      const newDate = format(transactionDate ?? Date.now(), 'yyyy-MM-dd');
      const td = dateFromString(newDate);
      setCurrTransactionDate(td);
    }
  }, [open]);

  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const newUnitPrice = Number(data.get('unitPrice'));
    const code = variations.reduce((acc, curr) => {
      const d = data.get(curr.variationId);
      if (d) {
        acc[curr.variationId] = d;
      }
      return acc;
    }, { unitPrice: newUnitPrice });

    const newQuantity = Number(data.get('quantity'));
    const newData = {
      stockCardId,
      unitPrice: newUnitPrice,
      variationCode: JSON.stringify(code),
      parsedVariationCode: code,
      oldDocName: docName,
      documentFile: hasDocType && docTypes.length > 1 ? docFile.current : DELETED_FILE,
      transactionData: {
        id: id || `${generateUUID()}_${Date.now()}`,
        documentRef: hasDocType && docTypes.length > 1 ? data.get('documentRef') : '',
        documentType: data.get('documentType') || 'noDoc',
        expiredDate: hasExpiredDate ? format(date, 'yyyy-MM-dd') : '',
        quantity: inOut === 'out' ? -newQuantity : newQuantity,
        transactionType: data.get('transactionType'),
        transactionDate: currTransactionDate.getTime()
      }
    };

    setIsSubmitting(true);
    onAddTransaction(newData);

    return true;
  };

  const transactionTypeList = inOut === 'out' ? transactionOutTypes : transactionInTypes;
  let uploaded = docFile.current?.file ? [docFile.current?.file] : undefined;
  if (!uploaded && docName && docFile.current !== DELETED_FILE) {
    uploaded = [{ name: docName }];
  }
  return (
    <CommonDialog
      fullWidth
      formId="form"
      title={title}
      open={open}
      isSubmitting={isSubmitting}
      onClose={onClose}
    >
      {
        open && (
          <Box
            id="form"
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
            onSubmit={onSubmit}
          >
            <Stack spacing={2} sx={{ mt: 1 }}>
              <Typography fontWeight="bold">Maklumat Variasi</Typography>
              {
                variations.map(({ variationId, variationName, variants, selectedIndex }) => (
                  <TextField
                    required
                    select
                    size="small"
                    name={variationId}
                    key={variationId}
                    label={variationName}
                    disabled={disableVariations}
                    defaultValue={variants[selectedIndex]?.variantId ?? ''}
                  >
                    {
                      variants.map(({ variantId, variantName }) => (
                        <MenuItem key={variantId} value={variantId}>{variantName}</MenuItem>
                      ))
                    }
                  </TextField>
                ))
              }
            </Stack>
            <Stack spacing={2} sx={{ mt: 4 }}>
              <Typography fontWeight="bold">Maklumat Transaksi</Typography>
              <RadioGroup
                row
                value={inOut}
                onChange={(e) => {
                  setInOut(e.target.value);
                  setTransactionType('');
                }}
              >
                <FormControlLabel label="Stok Masuk" value="in" control={<Radio />} />
                <FormControlLabel label="Stok Keluar" value="out" control={<Radio />} />
              </RadioGroup>
              <TextField
                required
                select
                size="small"
                label="Jenis Transaksi"
                name="transactionType"
                value={transactionType}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setDocTypes(documentTypes[newValue]);
                  setTransactionType(newValue);
                }}
              >
                {
                  transactionTypeList.map(({ id: itemId, name }) => (
                    <MenuItem key={itemId} value={itemId}>{name}</MenuItem>
                  ))
                }
              </TextField>
              {
                docTypes.length > 1 && (
                  <TextField
                    select
                    size="small"
                    name="documentType"
                    label="Jenis Dokumen"
                    defaultValue={docTypeId && docTypeId !== 'noDoc' ? docTypeId : ''}
                    onChange={(e) => {
                      setHasDocType(e.target.value && e.target.value !== 'noDoc');
                    }}
                  >
                    {
                      docTypes.map(({ id: itemId, name }) => (
                        <MenuItem key={itemId} value={itemId === 'noDoc' ? '' : itemId}>
                          <Typography fontStyle={itemId === 'noDoc' ? 'italic' : undefined}>{name}</Typography>
                        </MenuItem>
                      ))
                    }
                  </TextField>
                )
              }
              {
                hasDocType && docTypes.length > 1 && (
                  <>
                    <TextField
                      label="No Rujukan Dokumen"
                      name="documentRef"
                      placeholder=""
                      size="small"
                      defaultValue={docRef ?? ''}
                    />
                    <UploadTextField
                      label="Dokumen"
                      size="small"
                      acceptedFileType="image/jpeg,image/jpg,image/png,application/pdf"
                      value={uploaded}
                      onChange={([val]) => {
                        let newValue;
                        if (val) {
                          const names = val.name.split('.');
                          const ext = names[names.length - 1];
                          const filename = `${generateUUID()}_${Date.now()}.${ext}`;
                          newValue = { filename, file: val };
                        } else {
                          newValue = DELETED_FILE;
                        }
                        docFile.current = newValue;
                      }}
                    />
                  </>
                )
              }
              <Box sx={{ display: 'flex' }}>
                <NumberTextField
                  required
                  fullWidth
                  onlyInteger
                  onlyPositive
                  label="Kuantiti"
                  name="quantity"
                  size="small"
                  defaultValue={getDefaultQuantity(quantity)}
                  sx={{ mr: 2 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    sx: { color: inOut === 'out' ? 'red' : 'black' },
                    endAdornment: <InputAdornment position="end">Unit</InputAdornment>
                  }}
                />
                <NumberTextField
                  required
                  fullWidth
                  onlyPositive
                  label="Harga Seunit"
                  name="unitPrice"
                  size="small"
                  maxDecimal={2}
                  disabled={disableVariations}
                  defaultValue={unitPrice ? roundedToFixed(unitPrice, 2).toFixed(2) : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ pb: 0.375 }}>
                        RM
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={msLocale}>
                <DatePicker
                  value={currTransactionDate}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tarikh Transaksi"
                      size="small"
                    />
                  )}
                  onChange={(newValue) => {
                    setCurrTransactionDate(newValue);
                  }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    label="Tarikh Luput"
                    sx={{
                      mt: 1,
                      '& .MuiCheckbox-root': { py: 0 }
                    }}
                    control={(
                      <Checkbox
                        color="primary"
                        checked={hasExpiredDate}
                        onChange={(e) => setHasExpiredDate(e.target.checked)}
                      />
                    )}
                  />
                  {
                    hasExpiredDate && (
                      <DatePicker
                        value={date}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ ml: 4, maxWidth: 150, mt: 1 }}
                          />
                        )}
                        onChange={(newValue) => {
                          setDate(newValue);
                        }}
                      />
                    )
                  }
                </Box>
              </LocalizationProvider>
            </Stack>
          </Box>
        )
      }
    </CommonDialog>
  );
};

AddTransactionDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  id: PropTypes.string,
  stockCardId: PropTypes.string,
  variations: PropTypes.arrayOf(PropTypes.shape({})),
  docRef: PropTypes.string,
  quantity: PropTypes.number,
  unitPrice: PropTypes.number,
  expiredDate: PropTypes.string,
  transactionDate: PropTypes.number,
  transactionTypeId: PropTypes.string,
  docTypeId: PropTypes.string,
  docName: PropTypes.string,
  disableVariations: PropTypes.bool,
  onAddTransaction: PropTypes.func,
  onClose: PropTypes.func.isRequired
};

AddTransactionDialog.defaultProps = {
  title: 'Tambah Transaksi',
  id: undefined,
  docRef: '',
  stockCardId: '',
  quantity: 0,
  unitPrice: 0,
  variations: [],
  transactionTypeId: undefined,
  docTypeId: undefined,
  docName: undefined,
  expiredDate: undefined,
  transactionDate: undefined,
  disableVariations: false,
  onAddTransaction: () => undefined
};

export default AddTransactionDialog;
