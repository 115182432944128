import { format } from 'date-fns';

import { ref as bucketRef, deleteObject, getBlob, uploadBytes } from 'firebase/storage';
import {
  doc,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  runTransaction,
  arrayRemove
} from 'firebase/firestore';

import { getBucket, getDB, initFirestore } from 'firebaseUtil/db';

import { DELETED_FILE } from 'pages/constants/transaction';

import { dateFromString } from 'utils/formatter';

import getStockExpiryData from '../utils/getStockExpiryData';

let __userId;
let __stockListId;
let __stockCardId;

function validateDB() {
  if (!__stockListId || !__stockCardId) throw new Error('No such record');
  return getDB();
}

export function initStockCard(userId, stockListId, stockCardId) {
  initFirestore();
  __userId = userId;
  __stockListId = stockListId;
  __stockCardId = stockCardId;
}

export function resetStockCardData(queryClient) {
  queryClient.resetQueries(['stockCard', __userId, __stockListId, __stockCardId], { exact: true });
  __userId = undefined;
  __stockListId = undefined;
  __stockCardId = undefined;
}

export async function getStockCardDetail() {
  const db = validateDB();

  const stockDoc = await getDoc(doc(db, 'stockList', __stockListId));
  const stockData = stockDoc.data();
  if (!stockData?.userId?.includes(__userId)) {
    throw new Error('No such record');
  }

  const stockCardDoc = await getDoc(doc(db, 'stockCard', __stockCardId));
  const stockCardData = stockCardDoc.data();
  if (!stockCardData?.userId?.includes(__userId)) {
    throw new Error('No such record');
  }

  stockCardData.transactions.sort((a, b) => (
    a.transactionDate - b.transactionDate
  ));

  const parsedVariationCode = JSON.parse(stockCardData.variationCode ?? '{}');
  const variationQuery = query(
    collection(db, 'stockVariation'),
    where('userId', 'array-contains', __userId),
    where('stockListId', '==', __stockListId)
  );

  const selectedVariants = [];
  const variantionData = {};
  const variationDoc = await getDocs(variationQuery);
  variationDoc.forEach((docData) => {
    // docData.data() is never undefined for query doc snapshots
    const data = docData.data();
    const variationId = docData.id;
    const variantId = parsedVariationCode[variationId];
    const variant = data.variants.find((item) => item.variantId === variantId);

    if (variant) {
      variantionData[data.variationName] = variant.variantName;
      selectedVariants.push({
        variationId,
        variationName: data.variationName,
        variants: [variant],
        selectedIndex: 0
      });
    }
  });

  return ({
    variantionData,
    selectedVariants,
    stockCardData,
    stockData: {
      stockListId: __stockListId,
      stockGroupId: stockData.stockGroupId,
      daysBeforeExpired: stockData.daysBeforeExpired,
      stockName: stockData.stockName
    }
  });
}

export async function deleteTransaction(prevData) {
  const db = validateDB();
  const bucket = getBucket();

  const stockcardRef = doc(db, 'stockCard', __stockCardId);
  const data = await runTransaction(db, async (transaction) => {
    const docSnap = await transaction.get(stockcardRef);
    if (!docSnap.exists()) {
      throw new Error('Stock card not exist');
    }

    const transactionList = docSnap.data().transactions;
    const t = transactionList.find((item) => item.id === prevData.id);
    let deletedObj = {};
    if (t) {
      if (t.documentName) {
        const fileRef = bucketRef(bucket, `${__stockCardId}/${t.documentName}`);
        try {
          await deleteObject(fileRef);
        } catch (err) {
          if (err.code !== 'storage/object-not-found') {
            throw new Error('Error deleting file');
          }
        }
      }

      transaction.update(stockcardRef, {
        transactions: arrayRemove(t)
      });

      let nearExpiry = 0;
      let expired = 0;
      const { expiredDate, quantity } = t;
      if (expiredDate) {
        const todayTime = dateFromString(format(new Date(), 'yyyy-MM-dd')).getTime();
        ({ newExpired: expired, newNearExpiry: nearExpiry } = getStockExpiryData(
          quantity,
          expiredDate,
          todayTime,
          prevData.daysBeforeExpired
        ));
      }

      deletedObj = {
        id: t.id,
        quantity,
        expired,
        nearExpiry
      };
    }

    return deletedObj;
  });

  return {
    data,
    userId: __userId,
    stockListId: __stockListId,
    stockCardId: __stockCardId
  };
}

export async function downloadDocument(documentName) {
  const bucket = getBucket();
  const fileRef = bucketRef(bucket, `${__stockCardId}/${documentName}`);
  try {
    const blob = await getBlob(fileRef);
    const aElement = document.createElement('a');
    aElement.setAttribute('download', documentName);
    const href = URL.createObjectURL(blob);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
  } catch (err) {
    console.error('Error downloading file', documentName, ':', err);
  }
}

export async function editTransaction(newData) {
  const db = validateDB();
  const bucket = getBucket();

  const stockcardRef = doc(db, 'stockCard', __stockCardId);
  const data = await runTransaction(db, async (transaction) => {
    const docSnap = await transaction.get(stockcardRef);
    if (!docSnap.exists()) {
      throw new Error('Stock card not exist');
    }
    const { transactionData, daysBeforeExpired, documentFile } = newData;
    const transactionId = transactionData.id;

    let returnedObj = {};
    const transactionList = docSnap.data().transactions;
    const tIndex = transactionList.findIndex((item) => item.id === transactionId);
    if (tIndex !== -1) {
      const t = transactionList[tIndex];
      if (t.documentName && (documentFile?.filename || documentFile === DELETED_FILE)) {
        const fileRef = bucketRef(bucket, `${__stockCardId}/${t.documentName}`);
        try {
          await deleteObject(fileRef);
        } catch (err) {
          if (err.code !== 'storage/object-not-found') {
            throw new Error('Error deleting file');
          }
        }
      }

      if (documentFile && documentFile !== DELETED_FILE) {
        const { filename, file } = documentFile;
        const storageRef = bucketRef(bucket, `${__stockCardId}/${filename}`);
        const storageSnap = await uploadBytes(storageRef, file);
        transactionData.documentName = filename;
        transactionData.documentHash = storageSnap.metadata.md5Hash;
      } else if (!documentFile && t.documentName) {
        transactionData.documentName = t.documentName;
        transactionData.documentHash = t.documentHash;
      }

      let oldNearExpiry = 0;
      let oldExpired = 0;
      const oldQuantity = t.quantity;
      if (t.expiredDate) {
        const todayTime = dateFromString(format(new Date(), 'yyyy-MM-dd')).getTime();
        ({ newExpired: oldExpired, newNearExpiry: oldNearExpiry } = getStockExpiryData(
          t.quantity,
          t.expiredDate,
          todayTime,
          daysBeforeExpired
        ));
      }

      transactionList[tIndex] = transactionData;
      transaction.update(stockcardRef, { transactions: transactionList });

      let newNearExpiry = 0;
      let newExpired = 0;
      const { expiredDate, quantity } = transactionData;
      if (expiredDate) {
        const todayTime = dateFromString(format(new Date(), 'yyyy-MM-dd')).getTime();
        ({ newExpired, newNearExpiry } = getStockExpiryData(
          quantity,
          expiredDate,
          todayTime,
          daysBeforeExpired
        ));
      }

      returnedObj = {
        oldQuantity,
        oldExpired,
        oldNearExpiry,
        newQuantity: quantity,
        newExpired,
        newNearExpiry,
        transactionData
      };
    }

    return returnedObj;
  });

  return {
    data,
    userId: __userId,
    stockListId: __stockListId,
    stockCardId: __stockCardId
  };
}
