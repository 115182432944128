import * as React from 'react';
import deepCopy from 'fast-copy';
import { deepEqual } from 'fast-equals';

/**
 * The hook to update the react states when the observed store state is updated
 * @param store The stux store to observe
 * @param rest  The rest of arguments. This can be:
 *              * a callback function:
 *                - the function can take states as it arguments.
 *                  It must be the first argument in the `rest` argument,
 *              * a boolean:
 *                - a boolean value to mark as the given callback to be called when it's mounted.
 *                  It must be the second argument after the given callback
 *              * state name/key to be observed:
 *                - if not provided, the hook will update the state everytime the store updates
 *                  its state. If provided, the hook updates the state only if the observed store
 *                  key is updated
 *
 *              example usage:
 *              ```
 *              const { a, b, c } = useStux(myStore, (state) => { console.log(state); });
 *              const { a, b, c } = useStux(myStore, (state) => { console.log(state); }, 'a', 'c');
 *              const { a, b, c } = useStux(myStore, 'c'); // observe only changes in state 'c'
 *              const { a, b, c } = useStux(myStore); // observe any changes
 *              ```
 */
export default function useStux(store, ...rest) {
  const [state, setState] = React.useState(deepCopy(store.state));
  const isMounted = React.useRef(false);
  const stateNames = React.useRef([]);
  const callback = React.useRef();

  React.useEffect(() => {
    let executeCallbackWhenMount = false;
    if (rest[0] instanceof Function) {
      callback.current = rest.shift();
    }
    if (typeof rest[0] === 'boolean') {
      executeCallbackWhenMount = rest.shift();
    }
    stateNames.current = [...rest];

    const onStoreUpdate = () => {
      if (!isMounted.current) return;

      // check the state inside the setState so that, the value is always the current one
      setState((prev) => {
        // if no state name of the store is observed (which means observe every store states), or
        // the observed store state has changed, execute the callback (if any) and update the
        // component states
        if (
          !stateNames.current.length
          || stateNames.current.some((name) => !deepEqual(prev[name], store.state[name]))
        ) {
          if (callback.current) {
            callback.current(store.state);
          }
          // use deep copy to make sure we can catch any changes in the store state
          // even for state with hard reference
          return deepCopy(store.state);
        }
        return prev;
      });
    };

    // if it should execute the given callback (if any) when it's mounted, do it!
    if (executeCallbackWhenMount && callback.current) {
      callback.current(store.state);
    }

    store.on(store.STATE_UPDATE, onStoreUpdate);
    isMounted.current = true;

    return () => {
      isMounted.current = false;
      store.removeListener(store.STATE_UPDATE, onStoreUpdate);
    };
  }, []);

  return state;
}
