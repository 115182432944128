import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';

import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

const AuthenticationButton = ({ label, onClick }) => {
  const { isAuthenticating } = useStux(UserStore, 'isAuthenticating');

  return (
    <Button fullWidth type="submit" variant="contained" onClick={onClick}>
      {isAuthenticating ? <CircularProgress color="inherit" size={24} /> : label}
    </Button>
  );
};

AuthenticationButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default AuthenticationButton;
