import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material';
import {
  LockReset as NewPasswordIcon,
  Lock as PasswordIcon,
  EnhancedEncryption as ConfirmPasswordIcon
} from '@mui/icons-material';

import UserAction from 'stux/actions/UserAction';
import userStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

import UserError from 'utils/UserError';

import { FormContainer } from './styles';

const UpdatePasswordDialog = ({ onClose }) => {
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [pwMatchError, setPwMatchError] = React.useState('');
  const [pwLengthError, setPwLengthEror] = React.useState('');
  const { changePassError } = useStux(userStore, 'changePassError');

  const onSave = (e) => {
    if (currentPassword && password1 && password2) {
      if (password1.length < 8) {
        setPwLengthEror('Katalaluan mestilah tidak kurang dari 8 aksara');
        return false;
      }

      e.preventDefault();
      if (password1 === password2) {
        UserAction.changePassword(currentPassword, password1);
      } else {
        setPwMatchError('Katalaluan tidak sama');
      }
    }

    return false;
  };

  return (
    <Dialog open fullWidth onClose={onClose}>
      <DialogTitle>Tukar Katalaluan</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <span>
            Selepas menukar katalaluan, anda akan didaftar keluar secara automatik dan
            perlu mendaftar masuk dengan katalaluan yang baru.
          </span>
          <FormContainer id="updatePass">
            <TextField
              required
              fullWidth
              type="password"
              // variant="outlined"
              placeholder="Katalaluan Sekarang"
              value={currentPassword}
              error={!!changePassError}
              helperText={UserError.getErrorMessage(changePassError) || ' '}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <NewPasswordIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => {
                setCurrentPassword(e.currentTarget.value);
              }}
            />
            <TextField
              required
              fullWidth
              type="password"
              // variant="outlined"
              placeholder="Katalaluan Baru"
              value={password1}
              error={!!pwLengthError}
              helperText={pwLengthError || ' '}
              inputProps={{ minLength: 8 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => {
                setPassword1(e.currentTarget.value);
                setPwMatchError('');
                setPwLengthEror('');
              }}
            />
            <TextField
              required
              fullWidth
              type="password"
              // variant="outlined"
              placeholder="Sahkan Katalaluan Baru"
              value={password2}
              error={!!pwMatchError}
              helperText={pwMatchError || ' '}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ConfirmPasswordIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => {
                setPassword2(e.currentTarget.value);
                setPwMatchError('');
              }}
            />
          </FormContainer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          <strong>Batal</strong>
        </Button>
        <Button type="submit" form="updatePass" onClick={onSave}>
          <strong>Simpan</strong>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UpdatePasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default UpdatePasswordDialog;
