import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

export const ProfileBannerContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(8, 1, 0)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.white};
  background: ${({ theme: { palette: { primary } } }) => `linear-gradient(to right, ${primary.dark}, ${primary.main})`};
`;

export const CircledButton = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  border: 2px solid;
  border-radius: 50%;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
