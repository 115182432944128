import * as React from 'react';
import { Card, CardContent, Stack } from '@mui/material';

import Editbar from './EditBar';

const ProfileCard = ({ editMode, onSave, onToggleEdit, children, anchorSx, ...props }) => (
  <Card {...props}>
    <CardContent>
      <Editbar
        sx={{ mr: -2 }}
        anchorSx={{ pr: 0.5, ...anchorSx }}
        editMode={editMode}
        onSave={onSave}
        onToggleEdit={onToggleEdit}
      />
      <Stack spacing={4}>
        {children}
      </Stack>
    </CardContent>
  </Card>
);

ProfileCard.propTypes = {
  ...Card.propTypes,
  ...Editbar.propTypes
};

ProfileCard.defaultProps = {
  ...Editbar.defaultProps
};

export default ProfileCard;
