import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import UserAction from 'stux/actions/UserAction';
import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

const EmailVerificationDialog = () => {
  const navigate = useNavigate();
  const { isVerifyingEmail } = useStux(UserStore, 'isVerifyingEmail');

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      keepMounted
      open={isVerifyingEmail}
    >
      <DialogTitle>
        Pengesahan akaun
      </DialogTitle>
      <DialogContent>
        Pautan pengesahan akaun telah dihantar ke emel anda.
        Klik pada pautan tersebut untuk membuat pengesahan
        dan seterusnya anda boleh mendaftar masuk.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            UserAction.finishEmailVerification();
            navigate('/login', { replace: true });
          }}
        >
          Pergi ke halaman daftar masuk
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailVerificationDialog;
