import eventemitter3 from 'eventemitter3';

class StatelessStore extends eventemitter3 {
  __isInitialized = false;
  __actions = new Map();
  __tokens = new Map();
  __autoInit = false;

  /**
   * @param autoInit Whether the store should be manually initialised. If `false`, the store `init`
   *                 function must be explicitly called to initialise this store. If `true`,
   *                 this store can be initialised by calling `init` or by just listen to a listener
   *                 e.g: calling `on`, `once`, or by using `useStux` on this store
   */
  constructor(autoInit = true) {
    super();
    this.__autoInit = autoInit;
  }

  init(initArguments) {
    if (!this.__isInitialized) {
      this._init(initArguments);
      this.__isInitialized = true;
    }
  }

  /**
   * Init the store internally
   */
  _init() {
    // abstract function to be implemented by the subclasses
  }

  /**
   * Register callback to the given flux dispatcher.
   * @param dispatcher  The dispatcher to subscribe to
   */
  _subscribe(dispatcher, shouldPassActionType = false) {
    const token = dispatcher.register(({ actionType, data }) => {
      if (this.hasAction(actionType)) {
        const { callback, waitFor } = this.__actions.get(actionType);
        if (Array.isArray(waitFor)) {
          dispatcher.waitFor(waitFor);
        }
        if (callback) {
          const callbackData = !shouldPassActionType ? data : { ...(data ?? {}), actionType };
          callback(callbackData);
        }
      }
      return true; // No errors. Needed by promise in Dispatcher.
    });

    const { storeName } = this.constructor.prototype;
    if (storeName) {
      dispatcher.setToken(storeName, token);
    }
  }

  /**
   * Register an action so that it can be listened by store
   * @param actionType      A unique action type to be registered
   * @param callback        Function to be called for the action
   * @param [waitFor=null]  List of dispatcher token to be waiting before executing the action
   */
  _registerAction(actionType, callback, waitFor) {
    if (typeof actionType === 'symbol' && callback instanceof Function) {
      if (!this.hasAction(actionType)) {
        this.__actions.set(actionType, {
          callback,
          waitFor
        });
      }
    }
  }

  /**
   * To check whether the action has been registered
   * @param   actionType   Action type to be check
   * @return               Return true if the action has been registered and false if otherwise
   */
  hasAction(actionType) {
    return this.__actions.has(actionType);
  }

  /**
   * Get the given dispatcher token. An error will be thrown if the store never registered
   * the dispatcher
   * @deprecated
   * @param dispatcher The registered dispatcher
   * @return           The dispatcher token.
   */
  getToken(dispatcher) {
    const token = this.__tokens.get(dispatcher);
    if (!token) {
      throw new Error('StatelessStore: the dispatcher is not registered');
    }
    return token;
  }

  /**
   * Adds the listener function to the end of the listeners array for the event named eventName.
   * If the store `autoInit` = true, if it's not been initialised, the `init` will be called
   * @see https://nodejs.org/api/events.html#events_emitter_on_eventname_listener
   * @param  eventName The name of the event
   * @param  listener  The callback function
   * @return           This class instance
   */
  on(eventName, listener) {
    if (this.__autoInit) {
      this.init();
    }
    return super.on(eventName, listener);
  }

  /**
   * Adds a one-time listener function for the event named eventName.
   * If the store `autoInit` = true, if it's not been initialised, the `init` will be called
   * @see https://nodejs.org/api/events.html#events_emitter_once_eventname_listener
   * @param  eventName The name of the event
   * @param  listener  The callback function
   * @return           This class instance
   */
  once(eventName, listener) {
    if (this.__autoInit) {
      this.init();
    }
    return super.once(eventName, listener);
  }

  /**
   * Indicates that the store has been initialized
   */
  get isInitialized() {
    return this.__isInitialized;
  }
}

export default StatelessStore;
