import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const NumberTextField = ({ onlyInteger, onlyPositive, maxDecimal, onChange, ...props }) => (
  <TextField
    {...props}
    onChange={(e) => {
      const newValue = e.currentTarget.value;
      const exp2 = `^${onlyPositive ? '' : '-?'}\\d*${onlyInteger ? '' : '\\.?'}\\d{0,${maxDecimal}}`;
      const val = newValue.replace(/[^0-9.-]/g, '').match(new RegExp(exp2));
      e.currentTarget.value = val ? val[0] : '';
      if (onChange) onChange(e);
    }}
  />
);

NumberTextField.propTypes = {
  maxDecimal: PropTypes.number,
  onlyInteger: PropTypes.bool,
  onlyPositive: PropTypes.bool,
  onChange: PropTypes.func
};

NumberTextField.defaultProps = {
  onlyInteger: false,
  onlyPositive: false,
  maxDecimal: 10000,
  onChange: undefined
};

export default NumberTextField;
