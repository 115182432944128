import { collection, query, where, getDocs } from 'firebase/firestore';

import { getDB, initFirestore } from 'firebaseUtil/db';

let __userId;

export function initStockSummaryData(userId) {
  initFirestore();
  __userId = userId;
}

export function resetStockSummaryData(queryClient) {
  queryClient.resetQueries(['stockSummary', __userId], { exact: true });
  __userId = undefined;
}

export async function getStockSummary(stockListId) {
  const db = getDB();
  const q = query(
    collection(db, 'stockCard'),
    where('userId', 'array-contains', __userId),
    where('stockListId', '==', stockListId)
  );

  const querySnapshot = await getDocs(q);
  let balance = 0;
  querySnapshot.forEach((docData) => {
    // docData.data() is never undefined for query doc snapshots
    const data = docData.data();
    balance = data.transactions.reduce((acc, curr) => acc + curr.quantity, balance);
  });

  return {
    stockListId,
    balance
  };
}
