import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { Container, Content, Header, Logo } from './styles';

const Authentication = ({ icon: Icon, logo, title, children }) => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Content>
        <Header>
          <Logo
            $hasLogo={!!logo}
            variant={logo ? 'square' : 'circular'}
            src={logo}
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            <Icon sx={{ fontSize: 45 }} />
          </Logo>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            {title}
          </Typography>
        </Header>
        {children}
      </Content>
    </Container>
  );
};

Authentication.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  logo: PropTypes.string,
  children: PropTypes.node
};

Authentication.defaultProps = {
  logo: undefined,
  children: undefined
};

export default Authentication;
