import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';

const palette = {
  background: {
    default: '#e1e8f2'
  },
  primary: {
    light: '#3d8af7',
    main: '#0c59cf',
    dark: '#042f6f'
  },
  secondary: {
    main: '#92278f'
  },
  alternative: {
    main: '#febe10',
    dark: '#d68227'
  },
  error: {
    light: '#ff3823',
    main: '#e61610'
  }
};

export default function createTheme() {
  const defaultTheme = createMuiTheme();
  const theme = createMuiTheme({
    palette,
    zIndex: {
      ...defaultTheme.zIndex,
      stickyTable: 10
    },
    components: {
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            color: defaultTheme.palette.common.white
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            margin: 0,
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale'
          }
        }
      },
      MuiCardHeader: {
        styleOverrides: {
          title: {
            color: palette.primary.main,
            fontWeight: 'bold'
          },
          subheader: {
            marginTop: defaultTheme.spacing(0.7)
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '& .MuiSvgIcon-root': {
              fill: palette.primary.main
            }
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingTop: `${defaultTheme.spacing(2.5)} !important`
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            background: `linear-gradient(to right, ${palette.primary.dark}, ${palette.primary.main})`,
            fontWeight: 'bold',
            color: defaultTheme.palette.common.white
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: palette.primary.light
          }
        },
        variants: [{
          props: { variant: 'thick' },
          style: {
            borderBottomWidth: defaultTheme.spacing(0.25)
          }
        }]
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
              {
                display: 'none'
              },
            '& input[type=number]': {
              MozAppearance: 'textfield'
            }
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          divider: {
            borderBottomStyle: 'dashed',
            borderColor: '#aaa'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '& > .MuiSvgIcon-root': {
              color: palette.primary.light,
              marginRight: defaultTheme.spacing(1.5)
            }
          },
          divider: {
            borderBottomColor: `${palette.primary.light}90`
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            bottom: defaultTheme.spacing(1.25)
          },
          scrollButtons: {
            '&.Mui-disabled': {
              opacity: 0.3
            }
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: 'bold'
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard'
        },
        variants: [{
          props: { variant: 'standard', readOnly: true },
          style: {
            '& .MuiInput-underline:before': {
              borderBottomColor: defaultTheme.palette.grey[500]
            },
            '& .MuiInput-underline:hover:before': {
              borderBottom: `1px solid ${defaultTheme.palette.grey[500]}`
            },
            '& .MuiInput-underline:after': {
              borderBottom: `1px solid ${defaultTheme.palette.grey[500]}`
            }
          }
        }, {
          props: { readOnly: true, select: true },
          style: {
            '& .MuiSvgIcon-root': {
              display: 'none'
            }
          }
        }],
        styleOverrides: {
          root: {
            '& .MuiInput-underline:before': {
              borderColor: palette.primary.light
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.primary.light
            }
          }
        }
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: 'inherit',
            textTransform: 'none',
            borderRadius: 0,
            '&.MuiToggleButton-primary.Mui-selected': {
              backgroundColor: 'transparent',
              border: `1px solid ${palette.primary.light}`
            },
            '&.MuiToggleButton-primary.Mui-selected:hover': {
              backgroundColor: `${palette.primary.light}16`
            }
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            maxWidth: '250px',
            fontSize: '0.75rem',
            whiteSpace: 'pre-wrap',
            backgroundColor: palette.primary.main,
            borderRadius: defaultTheme.spacing(1)
          },
          arrow: {
            ':before': {
              backgroundColor: palette.primary.main
            }
          },
          tooltipPlacementLeft: {
            margin: `${defaultTheme.spacing(0, 1)} !important`
          },
          tooltipPlacementRight: {
            margin: `${defaultTheme.spacing(0, 1)} !important`
          },
          tooltipPlacementTop: {
            margin: `${defaultTheme.spacing(1, 0)} !important`
          },
          tooltipPlacementBottom: {
            margin: `${defaultTheme.spacing(1, 0)} !important`
          }
        }
      }
    }
  });

  return responsiveFontSizes(theme);
}
