import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Link as MUILink
} from '@mui/material';
import {
  VpnKey as SignInIcon,
  Email as EmailIcon,
  Lock as PasswordIcon,
  Visibility as ShowPasswordIcon,
  VisibilityOff as HidePasswordIcon
} from '@mui/icons-material';

import { useAuthentication } from 'hooks';

import LogoImg from 'logo/logo.png';

import UserAction from 'stux/actions/UserAction';
import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

import UserError from 'utils/UserError';

import Authentication from './Authentication';
import AuthenticationButton from './AuthenticationButton';
import PasswordResetDialog from './PasswordResetDiallog';
import { ActionContainer, ItemContainer } from './styles';

const SignInPage = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [hidePassword, setHidePassword] = React.useState(false);
  const [showPasswordResetDialog, setShowPasswordResetDialog] = React.useState(false);
  const { signInError } = useStux(UserStore, 'signInError');

  useAuthentication();

  const onSignIn = (e) => {
    if (email && password) {
      e.preventDefault();
      UserAction.signIn(email, password);
    }
  };

  return (
    <Authentication title="Log masuk aplikasi" logo={LogoImg} icon={SignInIcon}>
      <ItemContainer>
        <TextField
          required
          fullWidth
          type="email"
          variant="outlined"
          placeholder="Emel"
          value={email}
          error={!!signInError}
          helperText={UserError.getErrorMessage(signInError) || ' '}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            )
          }}
          onChange={(e) => {
            setEmail(e.currentTarget.value);
          }}
        />
        <TextField
          required
          fullWidth
          variant="outlined"
          placeholder="Katalaluan"
          type={hidePassword ? 'text' : 'password'}
          value={password}
          helperText={' '}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PasswordIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setHidePassword((prev) => !prev)}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {hidePassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
          onChange={(e) => {
            setPassword(e.currentTarget.value);
          }}
        />
        <ActionContainer>
          <AuthenticationButton label="Log Masuk" onClick={onSignIn} />
          <span>
            <MUILink
              type="button"
              component="button"
              underline="hover"
              variant="body1"
              onClick={() => {
                setShowPasswordResetDialog(true);
              }}
            >
              Terlupa katalaluan?
            </MUILink>
          </span>
          <span>
            <Typography component="span">
              Belum berdaftar?&nbsp;
            </Typography>
            <Link replace to="/sign-up">Daftar sekarang.</Link>
          </span>
        </ActionContainer>
      </ItemContainer>
      {showPasswordResetDialog && (
        <PasswordResetDialog
          onClose={() => setShowPasswordResetDialog(false)}
        />
      )}
    </Authentication>
  );
};

export default SignInPage;
