import * as React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Button, CircularProgress, Stack, Typography } from '@mui/material';

const Context = React.createContext();

export const WorkingModalProvider = ({ children }) => {
  const [show, setShow] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [buttonProps, setButtonProps] = React.useState();
  const [isIndeterminate, setIsIndeterminate] = React.useState(false);
  const [iconProps, setIconProps] = React.useState(null);
  const modalId = React.useRef(0);

  const value = React.useMemo(() => ({
    showWorkingModal: ({
      message = '',
      icon = null,
      iconColor = 'primary',
      indeterminate = false,
      buttonLabel = 'OK',
      onClickButton = undefined
    }) => {
      modalId.current += 1;
      setShow(true);
      setMsg(message);
      setIsIndeterminate(indeterminate);
      setIconProps(icon ? { icon, color: iconColor } : undefined);
      setButtonProps(onClickButton ? { label: buttonLabel, onClick: onClickButton } : undefined);
      return modalId.current;
    },
    hideWorkingModal: (id, force = false) => {
      if (id === modalId.current || force) {
        setShow(false);
        setMsg('');
        setIsIndeterminate(false);
        setButtonProps(undefined);
        setIconProps(undefined);
      }
    }
  }), []);

  return (
    <Context.Provider value={value}>
      {children}
      <Backdrop
        open={show}
        sx={{
          flexDirection: 'column',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      >
        {isIndeterminate && <CircularProgress color="primary" size={100} />}
        {
          (!isIndeterminate && iconProps) && (
            <iconProps.icon
              color={iconProps.color}
              sx={{ fontSize: (theme) => theme.spacing(15) }}
            />
          )
        }
        <Stack alignItems="center" spacing={2} sx={{ mt: 5 }}>
          {
            !!msg && (
              <Typography
                color="white"
                variant="label"
                sx={{ textAlign: 'center', whiteSpace: 'break-spaces' }}
              >
                {msg}
              </Typography>
            )
          }
          {
            !!buttonProps && (
              <Button
                size="small"
                variant="contained"
                onClick={buttonProps.onClick}
              >
                {buttonProps.label}
              </Button>
            )
          }
        </Stack>
      </Backdrop>
    </Context.Provider>
  );
};

WorkingModalProvider.propTypes = {
  children: PropTypes.node
};

WorkingModalProvider.defaultProps = {
  children: undefined
};

export const useWorkingModal = () => React.useContext(Context);
