import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import { EnhancedTable } from 'components/data-display/table';

import { StockContainer } from '../common';

const StockSummaryList = ({ isLoading, data, filter }) => {
  const [rows, setRows] = React.useState([]);
  const columns = React.useRef([{
    prop: 'stockType',
    key: 0,
    label: 'Jenis Stok',
    align: 'left'
  }, {
    prop: 'balance',
    key: 1,
    label: 'Baki',
    minWidth: 80,
    maxWidth: 80,
    width: 80
  }]);

  React.useEffect(() => {
    if (!data.length) return;
    const { isRaw, isPartial, isFinished } = filter;
    if (!isRaw && !isPartial && !isFinished) {
      setRows(data);
      return;
    }

    const newRows = data.filter((item) => (
      (isRaw && item.isRaw)
      || (isPartial && item.isPartial)
      || (isFinished && item.isFinished)
    ));
    setRows(newRows);
  }, [data, filter]);

  return (
    <StockContainer fullHeightLoading isLoading={isLoading} style={{ marginBottom: '1rem', flex: '1 1 auto' }}>
      {
        !data?.length ? (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography sx={{ p: 2, border: 'double lightgrey', color: 'grey' }}>
              Tiada senarai stok untuk dipaparkan
            </Typography>
          </Box>
        ) : (
          <Box sx={{ py: 2, maxWidth: 'calc(100vw - 32px)' }}>
            <EnhancedTable
              pinEnd
              stickyHeader
              noWrapCellText
              noWrapHeaderText
              alternateColor="#edf4ff"
              primaryValueVariant="subtitle2"
              secondaryValueVariant="caption"
              rows={rows}
              columns={columns.current}
            />
          </Box>
        )
      }
    </StockContainer>
  );
};

StockSummaryList.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filter: PropTypes.shape({
    isRaw: PropTypes.bool,
    isPartial: PropTypes.bool,
    isFinished: PropTypes.bool
  }).isRequired
};

StockSummaryList.defaultProps = {
  isLoading: false
};

export default StockSummaryList;
