import * as React from 'react';
import PropTypes from 'prop-types';

import { Upload } from '@mui/icons-material';
import { Chip, IconButton, TextField, Box, InputAdornment } from '@mui/material';

const getCorrectedValue = (value, multiple) => {
  if (Array.isArray(value)) {
    return value;
  }
  if (!multiple && (value?.filename || value?.name)) {
    return [value];
  }
  return [];
};

const UploadTextField = ({
  value,
  acceptedFileType,
  multiple,
  chipSx,
  inputSx,
  canDownload,
  onChange,
  onOpenFile,
  ...props
}) => {
  const [files, setFiles] = React.useState(getCorrectedValue(value, multiple));
  const inputFile = React.useRef(null);
  const acceptableFileTypes = acceptedFileType.split(/,\s*/);

  React.useEffect(() => {
    setFiles(getCorrectedValue(value, multiple));
  }, [value, multiple]);

  const onTrigger = (e) => {
    e.stopPropagation();
    inputFile.current.click();
  };

  const onSelectFile = () => {
    const allFileNames = files.map(({ name, filename }) => name ?? filename);
    const selectedFiles = Array.from(inputFile.current.files)
      .filter(({ type }) => acceptableFileTypes.some((acceptedType) => {
        if (acceptedType === type) return true;

        const acceptedMimeType = acceptedType.split('/');
        if (acceptedMimeType[1] === '*') {
          return type.startsWith(acceptedMimeType[0]);
        }
        return false;
      }))
      .filter((f) => !allFileNames.includes(f.name));
    inputFile.current.value = null;
    if (selectedFiles.length) {
      const allFiles = multiple ? [...files, ...selectedFiles] : [...selectedFiles];
      setFiles(allFiles);
      onChange(allFiles);
    }
  };

  return (
    <>
      <input
        type="file"
        ref={inputFile}
        accept={acceptedFileType}
        multiple={multiple}
        style={{ display: 'none', height: 0, padding: 0 }}
        onChange={onSelectFile}
      />
      <TextField
        {...props}
        // disabled
        onClick={onTrigger}
        sx={{
          ...props.sx,
          cursor: 'pointer',
          '& input': {
            display: 'none'
          },
          '& .MuiInputBase-root': {
            display: 'flex',
            flexWrap: 'wrap',
            // py: 0.5,
            // borderRadius: 1.25,
            ...inputSx
          }
        }}
        InputLabelProps={{
          shrink: files?.length > 0
        }}
        InputProps={{
          // notched: files?.length > 0,
          startAdornment: (
            <Box sx={{ paddingRight: 2.5, pb: 0.5, minHeight: '2rem' }}>
              {
                files.map((f, index) => (
                  <Chip
                    id={index}
                    key={f.name ?? f.filename}
                    label={f.name ?? f.filename}
                    sx={{ mx: 0.5, py: 0.25, height: 'unset', ...chipSx }}
                    onDelete={() => {
                      files.splice(index, 1);
                      const updatedFiles = [...files];
                      setFiles(updatedFiles);
                      onChange(updatedFiles);
                    }}
                    onClick={() => {
                      if (canDownload && f instanceof File) {
                        const a = document.createElement('a');
                        a.href = URL.createObjectURL(f);
                        a.download = f.name;
                        a.click();
                      } else if ((f.fileUrl || f.fileId) && onOpenFile) {
                        onOpenFile(f);
                      }
                    }}
                  />
                ))
              }
            </Box>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                sx={{ position: 'absolute', mb: 1, right: 0.625 }}
                // onClick={onTrigger}
              >
                <Upload />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </>
  );
};

UploadTextField.propTypes = {
  sx: PropTypes.shape({}),
  value: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({ name: PropTypes.string })
  ])),
  chipSx: PropTypes.shape(),
  inputSx: PropTypes.shape(),
  canDownload: PropTypes.bool,
  acceptedFileType: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onOpenFile: PropTypes.func
};

UploadTextField.defaultProps = {
  sx: {},
  value: [],
  chipSx: {},
  inputSx: {},
  canDownload: false,
  acceptedFileType: 'image/*',
  multiple: false,
  onChange: () => {},
  onOpenFile: null
};

export default UploadTextField;
