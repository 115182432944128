import { format } from 'date-fns';

import {
  doc,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  runTransaction
} from 'firebase/firestore';
import { uploadBytes, ref as bucketRef } from 'firebase/storage';

import { getBucket, getDB, initFirestore } from 'firebaseUtil/db';

import { DELETED_FILE } from 'pages/constants/transaction';

import { dateFromString } from 'utils/formatter';

import getStockExpiryData from '../utils/getStockExpiryData';

let __userId;
let __stockListId;
let __variationLastPosition = 0;

function validateDB() {
  if (!__stockListId) throw new Error('No such record');
  return getDB();
}

export function initStockDetail(userId, stockListId) {
  initFirestore();
  __userId = userId;
  __stockListId = stockListId;
}

export function resetStockDetailData(queryClient) {
  queryClient.resetQueries(['stockDetail', __userId, __stockListId], { exact: true });
  __userId = undefined;
  __stockListId = undefined;
  __variationLastPosition = 0;
}

export async function getStockDetail() {
  const db = validateDB();
  const stockDoc = await getDoc(doc(db, 'stockList', __stockListId));
  const stockData = stockDoc.data();
  if (!stockData?.userId?.includes(__userId)) {
    throw new Error('No such record');
  }

  const stockCardQuery = query(
    collection(db, 'stockCard'),
    where('userId', 'array-contains', __userId),
    where('stockListId', '==', __stockListId)
  );

  const stockCards = [];
  const stockCardDoc = await getDocs(stockCardQuery);
  const todayTime = dateFromString(format(new Date(), 'yyyy-MM-dd')).getTime();

  stockCardDoc.forEach((docData) => {
    // docData.data() is never undefined for query doc snapshots
    const data = docData.data();
    const transactions = data.transactions ?? [];
    let balance = 0;
    let nearExpiry = 0;
    let expired = 0;
    transactions.forEach((item) => {
      balance += item.quantity;
      if (item.expiredDate) {
        const { newExpired, newNearExpiry } = getStockExpiryData(
          item.quantity,
          item.expiredDate,
          todayTime,
          stockData.daysBeforeExpired
        );
        expired += newExpired;
        nearExpiry += newNearExpiry;
      }
    });
    stockCards.push({
      balance,
      nearExpiry,
      expired,
      stockCardId: docData.id,
      unitPrice: data.unitPrice,
      transactions,
      variationCode: data.variationCode,
      parsedVariationCode: JSON.parse(data.variationCode ?? '{}')
    });
  });

  const variationQuery = query(
    collection(db, 'stockVariation'),
    where('userId', 'array-contains', __userId),
    where('stockListId', '==', __stockListId)
  );
  const variations = [];
  const variationDoc = await getDocs(variationQuery);
  variationDoc.forEach((docData) => {
    // docData.data() is never undefined for query doc snapshots
    const data = docData.data();
    const variationId = docData.id;
    const canDelete = !stockCards.some(({ variationCode }) => variationCode.includes(`"${variationId}":`));
    variations.push({
      canDelete,
      variationId,
      position: data.position,
      variationName: data.variationName,
      variants: data.variants
    });
  });

  variations.sort((a, b) => a.position - b.position);
  __variationLastPosition = variations.length ? variations[variations.length - 1].position ?? 0 : 0;

  return {
    variations,
    stockCards,
    stockData: {
      stockListId: __stockListId,
      stockName: stockData.stockName,
      stockGroupId: stockData.stockGroupId,
      daysBeforeExpired: stockData.daysBeforeExpired,
      unitBeforeRestock: stockData.unitBeforeRestock
    }
  };
}

export async function addVariation({ variation }) {
  const db = validateDB();
  const newVariationPos = __variationLastPosition + 1;
  const position = newVariationPos;
  const ref = await addDoc(collection(db, 'stockVariation'), {
    ...variation,
    stockListId: __stockListId,
    position,
    userId: [__userId, 'superuser']
  });

  __variationLastPosition = newVariationPos;

  return {
    userId: __userId,
    stockListId: __stockListId,
    data: {
      ...variation,
      canDelete: true,
      position,
      variationId: ref.id
    }
  };
}

export async function updateVariation({ variationId, variation }) {
  const db = validateDB();
  const docRef = doc(db, 'stockVariation', variationId);
  await updateDoc(docRef, { ...variation });

  return {
    variationId,
    userId: __userId,
    stockListId: __stockListId,
    data: {
      ...variation
    }
  };
}

export async function deleteVariation({ variationId }) {
  const db = validateDB();
  const docRef = doc(db, 'stockVariation', variationId);

  await deleteDoc(docRef);

  return { variationId, userId: __userId, stockListId: __stockListId };
}

export async function addNewTransaction(newData) {
  const db = validateDB();
  const bucket = getBucket();

  const ref = doc(collection(db, 'stockCard'));
  const data = await runTransaction(db, async (transaction) => {
    const { unitPrice, variationCode, daysBeforeExpired, documentFile, transactionData } = newData;
    if (documentFile && documentFile !== DELETED_FILE) {
      const { filename, file } = documentFile;
      const storageRef = bucketRef(bucket, `${ref.id}/${filename}`);
      const storageSnap = await uploadBytes(storageRef, file);
      transactionData.documentName = filename;
      transactionData.documentHash = storageSnap.metadata.md5Hash;
    }

    const transactions = [transactionData];
    transaction.set(ref, {
      unitPrice,
      variationCode,
      transactions,
      stockListId: __stockListId,
      userId: [__userId, 'superuser']
    });

    let nearExpiry = 0;
    let expired = 0;
    const { expiredDate, quantity } = newData.transactionData;
    if (expiredDate) {
      const todayTime = dateFromString(format(new Date(), 'yyyy-MM-dd')).getTime();
      ({ newExpired: expired, newNearExpiry: nearExpiry } = getStockExpiryData(
        quantity,
        expiredDate,
        todayTime,
        daysBeforeExpired
      ));
    }

    return {
      userId: __userId,
      stockListId: __stockListId,
      data: {
        stockCardId: ref.id,
        balance: quantity,
        nearExpiry,
        expired,
        unitPrice,
        transactions,
        variationCode,
        parsedVariationCode: newData.parsedVariationCode
      }
    };
  });

  return data;
}

export async function appendTransaction(newData) {
  const db = validateDB();
  const bucket = getBucket();

  const stockcardRef = doc(db, 'stockCard', newData.stockCardId);
  const data = await runTransaction(db, async (transaction) => {
    const docSnap = await transaction.get(stockcardRef);
    if (!docSnap.exists()) {
      throw new Error('Stock card not exist');
    }

    const { stockCardId, daysBeforeExpired, documentFile, transactionData } = newData;
    if (documentFile && documentFile !== DELETED_FILE) {
      const { filename, file } = documentFile;
      const storageRef = bucketRef(bucket, `${stockCardId}/${filename}`);
      const storageSnap = await uploadBytes(storageRef, file);
      transactionData.documentName = filename;
      transactionData.documentHash = storageSnap.metadata.md5Hash;
    }

    transaction.update(stockcardRef, {
      transactions: arrayUnion(transactionData)
    });

    let nearExpiry = 0;
    let expired = 0;
    const { expiredDate, quantity } = transactionData;
    if (expiredDate) {
      const todayTime = dateFromString(format(new Date(), 'yyyy-MM-dd')).getTime();
      ({ newExpired: expired, newNearExpiry: nearExpiry } = getStockExpiryData(
        quantity,
        expiredDate,
        todayTime,
        daysBeforeExpired
      ));
    }

    return {
      newExpired: expired,
      newNearExpiry: nearExpiry,
      newTransaction: transactionData
    };
  });

  return {
    data,
    userId: __userId,
    stockListId: __stockListId,
    stockCardId: newData.stockCardId
  };
}
