import * as React from 'react';
import PropTypes from 'prop-types';
import { ListItemText, MenuItem } from '@mui/material';

import StockGroupItemMenu from './StockGroupItemMenu';

const StockGroupItem = ({
  label,
  value,
  stockGroupNames,
  onSelect,
  onUpdate,
  onChangeStockGroup,
  // these below props are provided by the Tab component..
  // because we use a MenuItem instead of a Tab inside the Tabs component, we need to strip out
  // any invalid props that is intended to be passed to a Tab component
  /* eslint-disable react/prop-types */
  fullWidth,
  selectionFollowsFocus,
  textColor,
  indicator,
  /* eslint-enable react/prop-types */
  ...props
}) => (
  <MenuItem
    {...props}
    value={value}
    sx={{
      py: 0,
      height: '48px !important',
      borderBottomStyle: 'dashed',
      '&.Mui-selected': {
        color: 'primary.main',
        fontWeight: 'bold'
      }
    }}
  >
    <ListItemText
      primary={label}
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-root': { fontWeight: 'inherit' }
      }}
      onClick={() => onSelect(value)}
    />
    {
      !!stockGroupNames && (
        <StockGroupItemMenu
          id={value}
          label={label}
          stockGroupNames={stockGroupNames}
          onUpdate={onUpdate}
          onChangeStockGroup={onChangeStockGroup}
        />
      )
    }
  </MenuItem>
);

StockGroupItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  stockGroupNames: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  onChangeStockGroup: PropTypes.func
};

StockGroupItem.defaultProps = {
  stockGroupNames: undefined,
  onUpdate: () => undefined,
  onChangeStockGroup: () => undefined
};

export default StockGroupItem;
