import { dispatchAction } from '../dispatchers/AppDispatcher';

export default class ProfileAction {
  static INIT_PROFILE = Symbol('initProfile');
  static RESET = Symbol('reset');
  static UPDATE_PERSONAL = Symbol('updatePersonal');
  static UPDATE_BUSINESS = Symbol('updateBusiness');

  static initProfile(userId) {
    dispatchAction(ProfileAction.INIT_PROFILE, { userId });
  }

  static reset() {
    dispatchAction(ProfileAction.RESET);
  }

  static updatePersonalProfile(fullName, nickname, sex, tel, callback) {
    dispatchAction(ProfileAction.UPDATE_PERSONAL, { fullName, nickname, sex, tel, callback });
  }

  static updateBusinessProfile(businessName, registrationNumber, businessPhoneNo, website) {
    dispatchAction(
      ProfileAction.UPDATE_BUSINESS,
      { businessName, registrationNumber, businessPhoneNo, website }
    );
  }
}
