import * as React from 'react';
import PropTypes from 'prop-types';
import { Divider, Typography } from '@mui/material';

const VariationCard = ({ data }) => (
  <>
    <table>
      <tbody>
        {
          Object.entries(data).map(([key, value]) => (
            <tr key={key}>
              <td style={{ whiteSpace: 'nowrap' }}>
                <Typography variant="subtitle2" fontWeight="bold">{key}</Typography>
              </td>
              <td
                style={{
                  width: '100%',
                  paddingLeft: '10px',
                  paddingTop: '4px',
                  paddingBottom: '4px'
                }}
              >
                <Typography variant="subtitle2">{value}</Typography>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
    <Divider
      style={{
        // need to use style to override stubborn style from Stack
        marginTop: 8,
        marginLeft: '-1rem',
        marginRight: '-1rem',
        borderBottom: '1px dashed lightgrey'
      }}
    />
  </>
);

VariationCard.propTypes = {
  data: PropTypes.shape({}).isRequired
};

export default VariationCard;
