import * as React from 'react';
import PropTypes from 'prop-types';
import { HelpOutline as InfoIcon } from '@mui/icons-material';
import { Stack, Typography, Tooltip } from '@mui/material';

const InfoLabel = ({ label, labelProps, tooltip, labelSX, ...props }) => (
  <Stack direction="row" alignItems="center" spacing={2} {...props}>
    <Typography
      {...labelProps}
      sx={{ lineHeight: 'normal', ...labelSX }}
    >
      {label}
    </Typography>
    <Tooltip
      arrow
      enterTouchDelay={0}
      leaveTouchDelay={6000}
      title={tooltip}
      {...labelProps}
    >
      <InfoIcon sx={{ color: 'primary' }} />
    </Tooltip>
  </Stack>
);

InfoLabel.propTypes = {
  tooltip: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelSX: Typography.propTypes.sx,
  labelProps: PropTypes.shape({ ...Typography.propTypes })
};

InfoLabel.defaultProps = {
  labelSX: {},
  labelProps: {}
};

export default InfoLabel;
