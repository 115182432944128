import roundedToFixed from 'round-tofixed';

export const DAY_IN_MS = 24 * 60 * 60 * 1000;

export function formatCurrency(value, currency = 'RM') {
  return `${currency} ${roundedToFixed(value, 2).toFixed(2)}`;
}

/**
 * Create a Date object so that it'll be in accordance to the UTC timezone even though
 * the returned Date object is still in the computer's timezone.
 * Inpired from here: https://stackoverflow.com/a/67730952
 * @param   date The date string
 * @returns      The Date where it's been offset to make the date as if in the UTC timezone
 */
export function dateFromString(date) {
  const newDate = new Date(date.replace(/-/g, '/'));

  // get the timezone offset in hour because getTimezoneOffset is in minute
  const tzOffsetHour = Math.abs(newDate.getTimezoneOffset()) / 60;

  // add with the timezone offset so that it'll be in the same time as if
  // it's in the UTC timezone
  newDate.setHours(newDate.getHours() + tzOffsetHour);
  return newDate;
}
