import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Paper, Stack } from '@mui/material';

import EmptyList from './EmptyList';

const StockContainer = ({
  isLoading,
  isEmptyList,
  fullHeightLoading,
  emptyListProps,
  children,
  sx,
  ...props
}) => (
  <Paper sx={{ px: 2, py: 1, borderRadius: 0, ...sx }} {...props}>
    <Stack spacing={1} sx={{ height: '100%' }}>
      {
        isEmptyList && emptyListProps && !isLoading && (
          <EmptyList messages={[emptyListProps.message, 'Klik butang di bawah untuk mula']}>
            <Button
              variant="contained"
              sx={{ mx: 'auto', my: 1 }}
              onClick={emptyListProps.onClickStart}
            >
              {emptyListProps.buttonLabel}
            </Button>
          </EmptyList>
        )
      }
      {
        isLoading && (
          <Box
            sx={{
              height: fullHeightLoading ? '100%' : undefined,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress disableShrink color="primary" size={80} />
          </Box>
        )
      }
      {!isLoading && !isEmptyList && children}
    </Stack>
  </Paper>
);

StockContainer.propTypes = {
  sx: PropTypes.shape({}),
  isEmptyList: PropTypes.bool,
  isLoading: PropTypes.bool,
  fullHeightLoading: PropTypes.bool,
  emptyListProps: PropTypes.shape({
    message: PropTypes.string,
    buttonLabel: PropTypes.string,
    onClickStart: PropTypes.func
  }),
  children: PropTypes.node.isRequired
};

StockContainer.defaultProps = {
  sx: {},
  isEmptyList: false,
  isLoading: false,
  fullHeightLoading: false,
  emptyListProps: undefined
};

export default StockContainer;
