import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

import { getApp } from './app';

let __db;
let __storage;

export function initFirestore() {
  if (!__db) {
    const app = getApp();
    __db = getFirestore(app);
  }

  if (!__storage) {
    const app = getApp();
    __storage = getStorage(app);
  }
}

export function getDB() {
  if (!__db) {
    initFirestore();
  }
  return __db;
}

export function getBucket() {
  if (!__storage) {
    initFirestore();
  }
  return __storage;
}

export function resetFirestore() {
  __db = undefined;
  __storage = undefined;
}
