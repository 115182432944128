import { useMutation } from '@tanstack/react-query';

import { useWorkingModal } from 'components/surfaces';

export default function useFeebackMutation(options) {
  const { mutationFn, errorMessage, onSuccess, onError, onSuccessOrError } = options;
  const { showWorkingModal, hideWorkingModal } = useWorkingModal();

  return useMutation(mutationFn, {
    onSettled: (data, error) => {
      if (onSuccessOrError) onSuccessOrError();
      if (error) {
        const workingModalId = showWorkingModal({
          message: errorMessage,
          onClickButton: () => {
            hideWorkingModal(workingModalId);
          }
        });
        if (onError) onError(error);
        return;
      }

      onSuccess(data);
    }
  });
}
