import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueries, useQuery } from '@tanstack/react-query';
import { ArrowBack } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, IconButton, Stack, Typography } from '@mui/material';

import UserStore from 'stux/stores/UserStore';
import { getStockList, initStockListData } from 'stux/data-managers/stockList';
import { getStockSummary, initStockSummaryData } from 'stux/data-managers/stockSummary';

import { stockTypes } from 'pages/constants/transaction';

import { StockContainer } from '../common';
import StockSummaryList from './StockSummaryList';

const StockSummaryPage = () => {
  const navigate = useNavigate();
  const [isReady, setIsReady] = React.useState(false);
  const [queries, setQueries] = React.useState({ queries: [], enabled: false });
  const hasResult = React.useRef(false);
  const [summaryData, setSummaryData] = React.useState([]);
  const [filter, setFilter] = React.useState({
    isRaw: false,
    isPartial: false,
    isFinished: false
  });

  const result = useQueries(queries);
  const { data, isLoading } = useQuery(
    ['stockList', UserStore.state.userId],
    getStockList,
    { enabled: isReady }
  );

  React.useEffect(() => {
    if (UserStore.state.isAuthenticated) {
      initStockListData(UserStore.state.userId);
      initStockSummaryData(UserStore.state.userId);
      setIsReady(true);
    }
  }, []);

  React.useEffect(() => {
    if (data) {
      hasResult.current = !data.length;
      const newQueris = data.map((item) => ({
        queryKey: ['stockSummary', UserStore.state.userId, item.stockId],
        queryFn: () => getStockSummary(item.stockId)
      }));
      setQueries({
        queries: newQueris,
        enabled: true
      });
    }
  }, [data]);

  React.useEffect(() => {
    if (!isReady || !queries.enabled || result?.some((r) => r.isLoading)) return;
    const newData = [];
    data.forEach((item) => {
      const { stockId, stockCode, stockName, stockType } = item;
      const summary = result.find((val) => val.data?.stockListId === stockId);
      newData.push({
        id: stockId,
        isRaw: stockType === 'R',
        isPartial: stockType === 'W',
        isFinished: stockType === 'F',
        balance: summary?.data?.balance ?? 0,
        stockType: {
          primary: `${stockCode} ${stockName}`,
          secondary: stockTypes[stockType]
        }
      });
    });

    hasResult.current = true;
    setSummaryData(newData);
    setQueries({ queries: [], enabled: false });
  }, [result]);

  const onFilter = (type, isChecked) => {
    setFilter((prev) => ({ ...prev, [type]: isChecked }));
  };

  const isWaiting = isLoading || !hasResult.current;
  return (
    <Stack spacing={1.5} sx={{ height: '100%' }}>
      <StockContainer sx={{ py: 2 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton color="primary" onClick={() => navigate('/stocks')}>
            <ArrowBack />
          </IconButton>
          <Typography color="primary" fontWeight="bold">Ringkasan Stok</Typography>
        </Stack>
      </StockContainer>
      <StockContainer>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControlLabel
            label="Stok Bahan Mentah"
            disabled={isWaiting}
            control={(
              <Checkbox
                color="primary"
                checked={filter.isRaw}
                onChange={(e) => onFilter('isRaw', e.target.checked)}
              />
            )}
          />
          <FormControlLabel
            label="Stok Separa Siap"
            disabled={isWaiting}
            control={(
              <Checkbox
                checked={filter.isPartial}
                onChange={(e) => onFilter('isPartial', e.target.checked)}
              />
            )}
          />
          <FormControlLabel
            label="Stok Siap"
            disabled={isWaiting}
            control={(
              <Checkbox
                checked={filter.isFinished}
                onChange={(e) => onFilter('isFinished', e.target.checked)}
              />
            )}
          />
        </Box>
      </StockContainer>
      <StockSummaryList
        data={summaryData}
        filter={filter}
        isLoading={isWaiting}
      />
    </Stack>
  );
};

export default StockSummaryPage;
