import * as React from 'react';
import classnames from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Avatar, Grid, Toolbar, Typography } from '@mui/material';

import { useAuthentication } from 'hooks';

import LogoImg from 'logo/logoHoriz.png';

import { ColorScrolledAppBar, WorkingModalProvider } from 'components/surfaces';

import ProfileAction from 'stux/actions/ProfileAction';
import ProfileStore from 'stux/stores/ProfileStore';
import { resetStockGroupData } from 'stux/data-managers/stockGroup';
import { resetStockListData } from 'stux/data-managers/stockList';
import { resetStockDetailData } from 'stux/data-managers/stockDetail';
import { resetStockCardData } from 'stux/data-managers/stockCard';

import ProcessingOverlay from './ProcessingOverlay';
import AccountMenu from './menu/AccountMenu';
import styles from './MainPage.module.css';
import { RootDiv, Content } from './styles';

const MainPage = () => {
  const location = useLocation();
  const isAuthenticated = useAuthentication(location.pathname);
  const queryClient = useQueryClient();
  const [isWide, setIsWide] = React.useState(true);

  React.useEffect(() => {
    ProfileStore.init();
  }, []);

  React.useEffect(() => {
    setIsWide(location.pathname === '/');
  }, [location]);

  React.useEffect(() => {
    if (!isAuthenticated) {
      ProfileAction.reset();
      resetStockGroupData(queryClient);
      resetStockListData(queryClient);
      resetStockDetailData(queryClient);
      resetStockCardData(queryClient);
    }
  }, [isAuthenticated]);

  return (
    <RootDiv>
      <ColorScrolledAppBar
        scrollTrigger
        fontColor="#fff"
        scrolledFontColor="#fff"
        backgroundColor="rgba(0, 0, 0, 0)"
        scrolledBackgroundColor="rgba(0, 0, 0, 0)"
        elevation={4}
        sx={{
          alignItems: 'center'
        }}
      >
        <Toolbar sx={{ width: 1, maxWidth: '1280px' }}>
          <div className={styles.middleContainer}>
            <Avatar
              src={LogoImg}
              sx={{
                mr: 2,
                width: 114,
                height: 30,
                borderRadius: 0
              }}
            />
            <Typography
              variant="h5"
              component="div"
              fontWeight="bold"
              sx={{
                display: { xs: 'none', sm: 'block' },
                flexGrow: 1,
                textAlign: { xs: 'left', sm: 'left' }
              }}
            >
              Sistem Pengurusan Stok
            </Typography>
          </div>
          <div className={classnames(styles.absoluteContainer, styles.rightContainer)}>
            <AccountMenu />
          </div>
        </Toolbar>
      </ColorScrolledAppBar>
      <Content>
        <Toolbar />
        {/* need to put the ProcessingOverlay before the Outlet so that ProcessingOverlay
            will ll be mounted first to let it listen to the Profile store changes first
         */}
        {isAuthenticated && <ProcessingOverlay />}
        <Grid
          container
          justifyContent="center"
          sx={{ p: 0, pt: isWide ? 0 : 1.5, height: '100%' }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
        >
          <Grid item xs={4} sm={8} md={isWide ? 12 : 10} lg={isWide ? 12 : 8}>
            <WorkingModalProvider>
              <Outlet />
            </WorkingModalProvider>
          </Grid>
        </Grid>
      </Content>
    </RootDiv>
  );
};

export default MainPage;
