import * as React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu } from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreIcon
} from '@mui/icons-material';

import { IconMenuItem } from 'components/inputs/buttons';

const StockListItemMenu = ({ onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onCloseMenu = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreIcon />
      </IconButton>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onCloseMenu}>
        <IconMenuItem
          label="Pinda Maklumat Stok"
          icon={EditIcon}
          sx={{ py: 1 }}
          onClick={(e) => {
            e.stopPropagation();
            onCloseMenu();
            onEdit();
          }}
        />
        <IconMenuItem
          noDivider
          label="Hapus Stok"
          icon={DeleteIcon}
          onClick={(e) => {
            e.stopPropagation();
            onCloseMenu();
            onDelete();
          }}
        />
      </Menu>
    </>
  );
};

StockListItemMenu.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default StockListItemMenu;
