import * as React from 'react';
import PropTypes from 'prop-types';
import { Clear } from '@mui/icons-material';
import { IconButton, Stack, TextField } from '@mui/material';

const DeletableTextField = ({ onDelete, disableDeletion, ...props }) => (
  <Stack direction="row" alignItems="end" spacing={1}>
    <TextField {...props} />
    <IconButton size="small" disabled={disableDeletion} onClick={onDelete}>
      <Clear fontSize="small" />
    </IconButton>
  </Stack>
);

DeletableTextField.propTypes = {
  onDelete: PropTypes.func.isRequired,
  disableDeletion: PropTypes.bool
};

DeletableTextField.defaultProps = {
  disableDeletion: false
};

export default DeletableTextField;
