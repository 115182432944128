import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { Edit as EditIcon, ExpandMore as ExpandIcon } from '@mui/icons-material';

const CollapsibleBox = ({
  sx,
  title,
  isExpand,
  titleProps,
  contentProps,
  children,
  onEdit,
  ...props
}) => {
  const [expand, setExpand] = React.useState(isExpand);

  return (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'column' }} {...props}>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        onClick={() => setExpand((prev) => !prev)}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography {...titleProps} sx={{ pointerEvents: 'none', ...titleProps.sx }}>
            {title}
          </Typography>
          {
            !!onEdit && (
              <IconButton
                size="small"
                sx={{ p: 0.5, border: '1px solid grey' }}
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit();
                }}
              >
                <EditIcon fontSize="1rem" />
              </IconButton>
            )
          }
        </Stack>
        <IconButton
          size="small"
          sx={{
            transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: (theme) => (
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest
              })
            )
          }}
        >
          <ExpandIcon />
        </IconButton>
      </Box>
      <Collapse unmountOnExit timeout="auto" in={expand}>
        <Box {...contentProps} sx={{ pt: 1, ...contentProps.sx }}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

CollapsibleBox.propTypes = {
  title: PropTypes.string,
  isExpand: PropTypes.bool,
  sx: PropTypes.shape({}),
  titleProps: PropTypes.shape({ sx: PropTypes.shape({}) }),
  contentProps: PropTypes.shape({ sx: PropTypes.shape({}) }),
  children: PropTypes.node.isRequired,
  onEdit: PropTypes.func
};

CollapsibleBox.defaultProps = {
  title: '',
  isExpand: false,
  sx: {},
  titleProps: { sx: { } },
  contentProps: { sx: { } },
  onEdit: undefined
};

export default CollapsibleBox;
