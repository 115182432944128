import {
  doc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
  addDoc
} from 'firebase/firestore';

import { getDB, initFirestore } from 'firebaseUtil/db';

import forEachAsync from 'utils/forEachAsync';

let __userId;

export function initStockGroupData(userId) {
  initFirestore();
  __userId = userId;
}

export function resetStockGroupData(queryClient) {
  queryClient.resetQueries(['stockGroup', __userId], { exact: true });
  __userId = undefined;
}

export async function getStockGroupList() {
  const db = getDB();
  const q = query(
    collection(db, 'stockGroup'),
    where('userId', 'array-contains', __userId)
  );
  const querySnapshot = await getDocs(q);
  const stockGroups = [];
  querySnapshot.forEach((docData) => {
    // docData.data() is never undefined for query doc snapshots
    const data = docData.data();
    stockGroups.push({
      id: docData.id,
      label: data.label
    });
  });

  return stockGroups;
}

export async function addStockGroup(data) {
  const db = getDB();
  const ref = await addDoc(collection(db, 'stockGroup'), {
    ...data,
    userId: [__userId, 'superuser']
  });

  return {
    data: {
      ...data,
      id: ref.id
    },
    userId: __userId
  };
}

export async function changeStockGroupName(data) {
  const db = getDB();
  const stockRef = doc(db, 'stockGroup', data.id);
  await updateDoc(stockRef, {
    label: data.label
  });

  return {
    ...data,
    userId: __userId
  };
}

export async function deleteStockGroup(data) {
  const db = getDB();
  const ref = doc(db, 'stockGroup', data.id);
  await deleteDoc(ref);

  const stockListQuery = query(
    collection(db, 'stockList'),
    where('userId', 'array-contains', __userId),
    where('stockGroupId', '==', data.id)
  );
  const stockLists = [];
  const stockListDoc = await getDocs(stockListQuery);
  stockListDoc.forEach((docData) => {
    stockLists.push(docData.id);
  });

  if (stockLists.length) {
    await forEachAsync(stockLists, async (stockListId) => {
      const docRef = doc(db, 'stockList', stockListId);
      await updateDoc(docRef, {
        stockGroupId: ''
      });
    });
  }

  return {
    ...data,
    userId: __userId
  };
}
