import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Add, MoreHoriz, Summarize } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import { useFeedbackMutation } from 'hooks';

import { NumberTextField } from 'components/inputs/textfields';
import { IconMenuItem } from 'components/inputs/buttons';

import { CommonDialog } from 'pages/commons';

import { addStockList } from 'stux/data-managers/stockList';

import { StockContainer } from '../common';

const StockListHeader = React.forwardRef(({ stockGroups }, ref) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const queryClient = useQueryClient();
  const mutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika menambah stok baharu. Sila cuba sekali lagi.',
    mutationFn: addStockList,
    onSuccessOrError: () => {
      setIsSubmitting(false);
      setOpen(false);
    },
    onSuccess: (newData) => {
      queryClient.setQueryData(
        ['stockList', newData.userId],
        (prev) => [...prev, newData.data]
      );
    }
  });

  React.useImperativeHandle(ref, () => ({
    openAddStockDialog: () => {
      setOpen(true);
    }
  }), []);

  const onCloseMenu = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setAnchorEl(null);
  };

  const onSave = (e) => {
    e.preventDefault();
    if (isSubmitting) return false;

    const data = new FormData(e.target);
    const stockName = data.get('stockName');
    const stockType = data.get('stockType');
    const daysBeforeExpired = data.get('daysBeforeExpired');
    const unitBeforeRestock = data.get('unitBeforeRestock');
    const stockGroupId = data.get('stockGroupId');
    const newStockGroupId = stockGroupId === 'no-group' ? '' : stockGroupId;

    setIsSubmitting(true);
    mutation.mutate({
      stockType,
      stockName,
      stockGroupId: newStockGroupId,
      daysBeforeExpired: Number(daysBeforeExpired ?? 5),
      unitBeforeRestock: Number(unitBeforeRestock ?? 50)
    });
    return true;
  };

  return (
    <StockContainer sx={{ py: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography color="primary" fontWeight="bold">Senarai Stok</Typography>
        <IconButton color="primary" onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MoreHoriz />
        </IconButton>
      </Stack>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onCloseMenu}>
        <IconMenuItem
          label="Tambah Stok"
          icon={Add}
          sx={{ py: 1 }}
          onClick={(e) => {
            e.stopPropagation();
            onCloseMenu();
            setOpen(true);
          }}
        />
        <IconMenuItem
          noDivider
          label="Papar Ringkasn Stok"
          icon={Summarize}
          onClick={(e) => {
            e.stopPropagation();
            onCloseMenu();
            navigate('/stocks/summary');
          }}
        />
      </Menu>
      <CommonDialog
        fullWidth
        open={open}
        title="Tambah Stok"
        formId="form"
        isSubmitting={isSubmitting}
        onClose={() => setOpen(false)}
      >
        <Stack id="form" component="form" spacing={2} onSubmit={onSave}>
          <TextField
            required
            fullWidth
            label="Nama Stok"
            name="stockName"
          />
          <TextField
            select
            required
            label="Kategori"
            name="stockGroupId"
            defaultValue="no-group"
          >
            <MenuItem value="no-group">Tiada Kategori</MenuItem>
            {
              stockGroups.map(({ id, label }) => (
                <MenuItem key={id} value={id}>{label}</MenuItem>
              ))
            }
          </TextField>
          <FormControl style={{ marginTop: '1.5rem' }}>
            <FormLabel>Jenis Stok</FormLabel>
            <RadioGroup row name="stockType" defaultValue="R">
              <FormControlLabel value="R" label="Stok Bahan Mentah" control={<Radio />} />
              <FormControlLabel value="W" label="Stok Separa Siap" control={<Radio />} />
              <FormControlLabel value="F" label="Stok Siap" control={<Radio />} />
            </RadioGroup>
          </FormControl>
          <Stack>
            <Typography variant="subtitle1" color="rgba(0, 0, 0, 0.6)">
              Bilangan hari sebelum luput:
            </Typography>
            <NumberTextField
              onlyInteger
              onlyPositive
              name="daysBeforeExpired"
              defaultValue={5}
              sx={{ maxWidth: 200 }}
              inputProps={{
                style: { textAlign: 'center' }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    hari dan ke bawah
                  </InputAdornment>
                ),
                sx: {
                  textAlign: 'center'
                }
              }}
            />
            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)" fontStyle="italic">
              Bilangan hari untuk stok dikira sebagai menghampiri tarikh luput.
              Abaikan jika stok tiada tarikh luput.
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1" color="rgba(0, 0, 0, 0.6)">
              Bilangan baki stok sebelum perlu ditambah kembali:
            </Typography>
            <NumberTextField
              onlyInteger
              onlyPositive
              name="unitBeforeRestock"
              defaultValue={50}
              sx={{ maxWidth: 200 }}
              inputProps={{
                style: { textAlign: 'center' }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    unit dan ke bawah
                  </InputAdornment>
                ),
                sx: {
                  textAlign: 'center'
                }
              }}
            />
            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)" fontStyle="italic">
              Jika baki stok kurang dari jumlah yang dinyatakan, kad stok tersebut akan
              ditonjolkan dalam senarai kad stok.
            </Typography>
          </Stack>
        </Stack>
      </CommonDialog>
    </StockContainer>
  );
});

StockListHeader.displayName = 'StockListHeader';
StockListHeader.propTypes = {
  stockGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string
  })).isRequired
};

export default StockListHeader;
