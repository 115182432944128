import * as React from 'react';
import PropTypes from 'prop-types';
import { Search } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material';

import { StockContainer } from '../common';

const StockListFilter = ({
  searchName,
  isRaw,
  isPartial,
  isFinished,
  onFilter
}) => {
  const [searchValue, setSearchValue] = React.useState(searchName);

  React.useEffect(() => {
    setSearchValue(searchName);
  }, [searchName]);

  const onSearch = () => {
    const newSearchName = searchValue.trim();
    if (searchName !== newSearchName) {
      onFilter('searchName', newSearchName);
    }
  };

  return (
    <StockContainer>
      <TextField
        fullWidth
        variant="outlined"
        value={searchValue}
        sx={{ mt: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                color="primary"
                size="small"
                onClick={onSearch}
              >
                <Search />
              </IconButton>
            </InputAdornment>
          )
        }}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyUp={(e) => {
          if (e.code === 'Enter') {
            onSearch();
          }
        }}
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <FormControlLabel
          label="Stok Bahan Mentah"
          control={(
            <Checkbox
              color="primary"
              checked={isRaw}
              onChange={(e) => onFilter('isRaw', e.target.checked)}
            />
          )}
        />
        <FormControlLabel
          label="Stok Separa Siap"
          control={(
            <Checkbox
              checked={isPartial}
              onChange={(e) => onFilter('isPartial', e.target.checked)}
            />
          )}
        />
        <FormControlLabel
          label="Stok Siap"
          control={(
            <Checkbox
              checked={isFinished}
              onChange={(e) => onFilter('isFinished', e.target.checked)}
            />
          )}
        />
      </Box>
    </StockContainer>
  );
};

StockListFilter.propTypes = {
  searchName: PropTypes.string,
  isRaw: PropTypes.bool,
  isPartial: PropTypes.bool,
  isFinished: PropTypes.bool,
  onFilter: PropTypes.func
};

StockListFilter.defaultProps = {
  searchName: '',
  isRaw: false,
  isPartial: false,
  isFinished: false,
  onFilter: () => undefined
};

export default StockListFilter;
