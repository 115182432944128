import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Stack } from '@mui/material';

import UserStore from 'stux/stores/UserStore';
import { initStockDetail } from 'stux/data-managers/stockDetail';
import { getStockCardDetail, initStockCard } from 'stux/data-managers/stockCard';
import { initStockSummaryData } from 'stux/data-managers/stockSummary';
import { getStockGroupList, initStockGroupData } from 'stux/data-managers/stockGroup';

import { documentTypes, transactionTypes } from 'pages/constants/transaction';

import StockCardHeader from './StockCardHeader';
import StockCardData from './StockCardData';
import { ErrorPanel } from '../common';
import PrintSummary from './PrintSummary';

const StockCardPage = () => {
  const navigate = useNavigate();
  const [isReady, setIsReady] = React.useState(false);
  const [transactions, setTransactions] = React.useState([]);
  const [isPrint, setIsprint] = React.useState(false);
  const { stockListId, stockCardId } = useParams();

  const { data: stockGroups } = useQuery(
    ['stockGroup', UserStore.state.userId],
    getStockGroupList,
    { enabled: isReady }
  );

  const { data, error, isLoading, isError } = useQuery(
    ['stockCard', UserStore.state.userId, stockListId, stockCardId],
    getStockCardDetail,
    { enabled: isReady }
  );

  React.useEffect(() => {
    if (UserStore.state.isAuthenticated) {
      initStockGroupData(UserStore.state.userId);
      initStockDetail(UserStore.state.userId, stockListId);
      initStockCard(UserStore.state.userId, stockListId, stockCardId);
      initStockSummaryData(UserStore.state.userId);
      setIsReady(true);
    }
  }, []);

  React.useEffect(() => {
    if (!data) return;

    let balance = 0;
    let totalQuantity = 0;
    const newTransactions = data.stockCardData.transactions
      .map((d, index) => {
        const transactionType = transactionTypes.find((item) => item.id === d.transactionType);
        const docType = documentTypes[transactionType.id];
        const docTypeObj = docType.find((item) => item.id === d.documentType) ?? '';
        const document = {
          primary: docTypeObj && docTypeObj.id !== 'noDoc' ? docTypeObj.name : '',
          secondary: d.documentRef
        };
        const total = d.quantity * data.stockCardData.unitPrice;
        balance += total;
        totalQuantity += d.quantity;
        return {
          id: d.id ?? index,
          transactionDate: d.transactionDate,
          transaction: transactionType.name,
          document,
          quantity: d.quantity,
          unitPrice: data.stockCardData.unitPrice,
          total,
          balance,
          realData: d
        };
      });

    newTransactions.push({
      id: 'last-index',
      transactionDate: '',
      transaction: '',
      document: 'Baki',
      quantity: totalQuantity,
      unitPrice: data.stockCardData.unitPrice,
      total: balance,
      balance: '',
      disableAction: true
    });

    setTransactions(newTransactions);
  }, [data]);

  return (
    <Stack spacing={1.5} sx={{ height: '100%' }}>
      {
        isError ? (
          <ErrorPanel
            message={
              error?.message === 'No such record' ? 'Stok kad tidak wujud' : 'Ralat ketika mengakses stok kad'
            }
          />
        ) : (
          <>
            <StockCardHeader
              title={data?.stockData?.stockName ?? ''}
              stockGroup={
                stockGroups?.find((val) => val?.id === data?.stockData?.stockGroupId)?.label
              }
              stockCardId={stockCardId}
              daysBeforeExpired={data?.stockData.daysBeforeExpired ?? 0}
              unitPrice={data?.stockCardData.unitPrice ?? 0}
              selectedVariants={data?.selectedVariants ?? []}
              onClickBack={() => navigate(`/stocks/${stockListId}`)}
              onPrint={() => setIsprint(true)}
            />
            <StockCardData
              isLoading={isLoading}
              variantData={data?.variantionData}
              transactionData={transactions}
              unitPrice={data?.stockCardData.unitPrice ?? 0}
              selectedVariants={data?.selectedVariants ?? []}
              daysBeforeExpired={data?.stockData.daysBeforeExpired}
            />
          </>
        )
      }
      {
        isPrint && (
          <PrintSummary
            data={data?.stockCardData.transactions ?? []}
            unitPrice={data?.stockCardData.unitPrice ?? 0}
            stockName={data?.stockData.stockName ?? ''}
            variations={data?.variantionData ?? {}}
            onClose={() => setIsprint(false)}
          />
        )
      }
    </Stack>
  );
};

export default StockCardPage;
