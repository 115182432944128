export const DELETED_FILE = '---deleted-from-transaction---';

export const transactionTypes = [
  { id: 'co', name: 'Penggunaan' },
  { id: 'sb', name: 'Baki Stok Awalan' },
  { id: 'ri', name: 'Pemulangan Masuk' },
  { id: 'ro', name: 'Pemulangan Keluar' },
  { id: 'cp', name: 'Belian Tunai' },
  { id: 'cs', name: 'Jualan Tunai' },
  { id: 'pi', name: 'Inbois Belian' },
  { id: 'si', name: 'Inbois Jualan' },
  { id: 'sg', name: 'Kecurian' },
  { id: 'df', name: 'Lupus' }
];

export const transactionInTypes = [
  { id: 'sb', name: 'Baki Stok Awalan' },
  { id: 'ri', name: 'Pemulangan Masuk' },
  { id: 'cp', name: 'Belian Tunai' },
  { id: 'pi', name: 'Inbois Belian' }
];

export const transactionOutTypes = [
  { id: 'co', name: 'Penggunaan' },
  { id: 'ro', name: 'Pemulangan Keluar' },
  { id: 'cs', name: 'Jualan Tunai' },
  { id: 'si', name: 'Inbois Jualan' },
  { id: 'sg', name: 'Kecurian' },
  { id: 'df', name: 'Lupus (rosak, luput tarikh, bencana alam, kebakaran)' }
];

export const transactionIns = ['sb', 'ri', 'cp', 'pi'];
export const transactionOuts = ['co', 'ro', 'cs', 'si', 'sg', 'df'];

export const documentTypes = {
  co: [
    { id: 'noDoc', name: 'Tiada Dokumen' },
    { id: 'consumptionNote', name: 'Nota penggunaan' }
  ],
  sb: [
    { id: 'noDoc', name: 'Tiada Dokumen' }
  ],
  ri: [
    { id: 'noDoc', name: 'Tiada Dokumen' },
    { id: 'creditNote', name: 'Nota kredit' }
  ],
  ro: [
    { id: 'noDoc', name: 'Tiada Dokumen' },
    { id: 'creditNote', name: 'Nota kredit' }
  ],
  cp: [
    { id: 'noDoc', name: 'Tiada Dokumen' },
    { id: 'cashBill', name: 'Bil tunai' }
  ],
  cs: [
    { id: 'noDoc', name: 'Tiada Dokumen' },
    { id: 'cashBill', name: 'Bil tunai' }
  ],
  pi: [
    { id: 'noDoc', name: 'Tiada Dokumen' },
    { id: 'invoice', name: 'Inbois' },
    { id: 'debitNote', name: 'Nota debit' },
    { id: 'credit Note', name: 'Nota kredit' }
  ],
  si: [
    { id: 'noDoc', name: 'Tiada Dokumen' },
    { id: 'invoice', name: 'Inbois' },
    { id: 'debitNote', name: 'Nota debit' },
    { id: 'credit Note', name: 'Nota kredit' }
  ],
  sg: [
    { id: 'noDoc', name: 'Tiada Dokumen' }
  ],
  df: [
    { id: 'noDoc', name: 'Tiada Dokumen' }
  ]
};

export const stockTypes = {
  R: 'Stok Bahan Mentah',
  W: 'Stok Separa Siap',
  F: 'Stok Siap'
};
