import StatelessStore from './StatelessStore';

export default class StatefulStore extends StatelessStore {
  STATE_UPDATE = Symbol('stateUpdate');

  _state;

  constructor(state, initOnAddListener = true) {
    super(initOnAddListener);
    this._state = state;
  }

  /**
   * Update the store state value with a new state. Store will emit STATE_UPDATE
   * when this function is called
   * @param state  State object to be merged with current store state
   */
  _updateState(state) {
    this._state = {
      ...this._state,
      ...state
    };
    this._emitUpdate();
  }

  /**
   * Emit STATE_UPDATE event. This can be used to emit the STATE_UPDATE event without
   * actually update the state
   */
  _emitUpdate() {
    this.emit(this.STATE_UPDATE);
  }

  /**
   * The store state. This will always return the shallow copy of the store state
   */
  get state() {
    return { ...this._state };
  }
}
