/**
 * Class to standardized the passing of flux dispatcher's payload.
 */
export default class Payload {
  __actionType;
  __data;

  /**
   * Constructor to create payload
   * @param actionType Use Symbol('action') to avoid naming conflict
   * @param data       Object that contains the payload data.
   */
  constructor(actionType, data) {
    if (typeof actionType === 'symbol') {
      this.__actionType = actionType;
      this.__data = data;
    } else {
      throw new Error('Action type must be a Symbol.');
    }
  }

  /**
   * The action type that trigger the dispatch.
   */
  get actionType() {
    return this.__actionType;
  }

  /**
   * The payload's data to be processed by dispatcher's registered callback
   */
  get data() {
    return this.__data;
  }
}
