import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';

import { PointNumber } from './styles';

const PointCard = ({ pointNumber, title, main, sub }) => (
  <Box
    lang="en-MY"
    xmlLang="en-MY"
    sx={{
      p: 2,
      pt: 4,
      width: { lg: '30%', xs: '100%' },
      position: 'relative',
      border: 'solid 1px rgba(255, 255, 255, 0.5)',
      borderRadius: '0.5rem',
      backgroundColor: 'rgba(255, 255, 255, 0.15)'
    }}
  >
    <PointNumber>
      {pointNumber}
    </PointNumber>
    <Stack spacing={0.5}>
      <Typography variant="subtitle1" fontWeight="bold">{title}</Typography>
      <Typography variant="subtitle2">
        <div>{main}</div>
        <div style={{ fontStyle: 'italic', color: 'yellow' }}>{sub}</div>
      </Typography>
    </Stack>
  </Box>
);

PointCard.propTypes = {
  title: PropTypes.string.isRequired,
  main: PropTypes.string.isRequired,
  sub: PropTypes.string.isRequired,
  pointNumber: PropTypes.number.isRequired
};

export default PointCard;
