import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MoreVert } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';

function descendingComparator(a, b, sortBy) {
  if (b[sortBy] < a[sortBy]) return -1;
  if (b[sortBy] > a[sortBy]) return 1;
  return 0;
}

function getComparator(sortType, sortBy) {
  if (!sortBy) return () => 0;
  if (sortType === 'desc') return (a, b) => descendingComparator(a, b, sortBy);
  return (a, b) => -descendingComparator(a, b, sortBy);
}

const EnhancedTable = ({
  rows,
  columns,
  pageNumber,
  totalRow,
  hoverOnRows,
  stickyHeader,
  noWrapHeaderText,
  noWrapCellText,
  headerColor,
  headerFontColor,
  bodyColor,
  alternateColor,
  primaryValueVariant,
  secondaryValueVariant,
  getRowClassNameFromData,
  tableProps,
  tableContainerProps,
  onClickStartAction,
  defaultSortType,
  defaultSortBy,
  rowsPerPageOptions,
  totalExcludedRowFromPagination,
  rowRefPropName,
  pinStart,
  pinEnd,
  pinBackgroundColor
}) => {
  const [page, setPage] = React.useState(pageNumber);
  const [rowsPerPage, setRowsPerPage] = React.useState(totalRow);
  const [sortType, setSortType] = React.useState(defaultSortType);
  const [sortBy, setSortBy] = React.useState(defaultSortBy);

  const workingRows = !sortBy ? rows : rows.slice().sort(getComparator(sortType, sortBy));
  const lastColumnIndex = columns.length - 1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <>
      <TableContainer {...tableContainerProps}>
        <Table
          stickyHeader={stickyHeader}
          {...tableProps}
          sx={{
            ...tableProps.sx,
            '& .pinStart': {
              zIndex: (theme) => theme.zIndex.stickyTable ?? 3,
              left: 0,
              position: 'sticky',
              width: 42,
              backgroundColor: pinBackgroundColor ?? 'inherit',
              boxShadow: '1px 0px 3px 0px rgb(0 0 0 / 20%)'
            },
            '& .pinEnd': {
              zIndex: (theme) => theme.zIndex.stickyTable ?? 3,
              right: 0,
              position: 'sticky',
              backgroundColor: pinBackgroundColor ?? 'inherit',
              boxShadow: '-1px 0px 3px 0px rgb(0 0 0 / 20%)'
            },
            '& th': {
              whiteSpace: noWrapHeaderText ? 'nowrap' : undefined
            },
            '& td': {
              whiteSpace: noWrapCellText ? 'nowrap' : undefined
            },
            '& thead > tr, thead th': {
              py: 1,
              fontWeight: 'bold',
              backgroundColor: headerColor ?? 'primary.light',
              color: headerFontColor ?? 'common.white'
            },
            '& tbody > tr:nth-of-type(odd)': {
              backgroundColor: bodyColor ?? 'common.white'
            },
            '& tbody > tr:nth-of-type(even)': {
              backgroundColor: alternateColor ?? 'common.white'
            },
            '& .MuiTableSortLabel-root.Mui-active': {
              color: headerFontColor ?? 'common.white'
            },
            '& .MuiTableSortLabel-icon': {
              color: `${headerFontColor ?? 'white'} !important`
            }
          }}
        >
          <TableHead>
            <TableRow>
              {!!onClickStartAction && (
                <TableCell padding="checkbox" className={classnames({ pinStart })} />
              )}
              {columns.map((column, index) => (
                <TableCell
                  key={column.key || `${column.prop}-${index}`}
                  align={column.align ?? 'center'}
                  padding={column.disablePadding ? 'none' : 'normal'}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    width: column.width
                  }}
                  sortDirection={sortBy === column.prop ? sortType : false}
                  className={classnames(
                    { pinStart: pinStart && !onClickStartAction && index === 0 },
                    { pinEnd: pinEnd && index === lastColumnIndex }
                  )}
                >
                  {
                    column.sortable ? (
                      <TableSortLabel
                        active={sortBy === column.prop}
                        direction={sortBy === column.prop ? sortType : 'asc'}
                        onClick={() => {
                          const isAsc = sortBy === column.prop && sortType === 'asc';
                          setSortType(isAsc ? 'desc' : 'asc');
                          setSortBy(column.prop);
                        }}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {workingRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  hover={hoverOnRows}
                  role="checkbox"
                  tabIndex={-1}
                  key={row[rowRefPropName]}
                  className={getRowClassNameFromData ? getRowClassNameFromData(row) : undefined}
                >
                  {!!onClickStartAction && (
                    <TableCell
                      padding="checkbox"
                      className={classnames({ pinStart })}
                    >
                      {
                        !row.disableAction && (
                          <IconButton
                            size="small"
                            color="inherit"
                            onClick={(e) => onClickStartAction(e, row)}
                          >
                            <MoreVert />
                          </IconButton>
                        )
                      }
                    </TableCell>
                  )}
                  {columns.map((column, i) => {
                    const value = row[column.prop];
                    const cellClassName = column.getClassnameFromValue ? column.getClassnameFromValue(value) : '';
                    return (
                      <TableCell
                        key={column.key || `${column.prop}-${i}`}
                        align={column.align ?? 'center'}
                        className={classnames(
                          cellClassName,
                          { pinStart: pinStart && !onClickStartAction && i === 0 },
                          { pinEnd: pinEnd && i === lastColumnIndex }
                        )}
                      >
                        {
                          // eslint-disable-next-line no-nested-ternary
                          (value?.primary !== undefined && value?.secondary !== undefined) ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography variant={primaryValueVariant}>
                                {value.primary}
                              </Typography>
                              <Typography variant={secondaryValueVariant}>
                                {value.secondary}
                              </Typography>
                            </Box>
                          ) : (
                            column.getValue ? column.getValue(value) : value
                          )
                        }
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={columns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        labelRowsPerPage="Had Baris"
        page={page}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        labelDisplayedRows={({ from, to, count }) => (
          `${from}–${to - totalExcludedRowFromPagination} `
          + `drpd ${count !== -1 ? count - totalExcludedRowFromPagination : `lebih dari ${to}`}`
        )}
        onPageChange={setPage}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(Number(e.target.value));
          setPage(0);
        }}
      />
    </>
  );
};

EnhancedTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tableContainerProps: PropTypes.shape({}),
  tableProps: PropTypes.shape({ sx: PropTypes.shape({}) }),
  rowRefPropName: PropTypes.string,
  pageNumber: PropTypes.number,
  totalRow: PropTypes.number,
  headerColor: PropTypes.string,
  headerFontColor: PropTypes.string,
  bodyColor: PropTypes.string,
  alternateColor: PropTypes.string,
  primaryValueVariant: PropTypes.string,
  secondaryValueVariant: PropTypes.string,
  getRowClassNameFromData: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  totalExcludedRowFromPagination: PropTypes.number,
  defaultSortType: PropTypes.oneOf(['asc', 'dec']),
  defaultSortBy: PropTypes.string,
  pinBackgroundColor: PropTypes.string,
  pinStart: PropTypes.bool,
  pinEnd: PropTypes.bool,
  hoverOnRows: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  noWrapHeaderText: PropTypes.bool,
  noWrapCellText: PropTypes.bool,
  onClickStartAction: PropTypes.func
};

EnhancedTable.defaultProps = {
  pinStart: false,
  pinEnd: false,
  hoverOnRows: false,
  stickyHeader: false,
  noWrapCellText: false,
  noWrapHeaderText: false,
  pinBackgroundColor: undefined,
  rowRefPropName: 'id',
  pageNumber: 0,
  totalRow: 10,
  headerColor: undefined,
  headerFontColor: undefined,
  bodyColor: undefined,
  alternateColor: undefined,
  getRowClassNameFromData: undefined,
  primaryValueVariant: 'caption',
  secondaryValueVariant: 'subtitle2',
  rowsPerPageOptions: [10, 25, 50, 100],
  totalExcludedRowFromPagination: 0,
  defaultSortType: 'asc',
  defaultSortBy: '',
  tableContainerProps: {},
  tableProps: { sx: {} },
  onClickStartAction: undefined
};

export default React.memo(EnhancedTable);
