import ProfileAction from 'stux/actions/ProfileAction';
import ProfileStore from 'stux/stores/ProfileStore';
import UserStore from 'stux/stores/UserStore';

function updateProfileStore() {
  return new Promise((resolve) => {
    ProfileStore.once(ProfileStore.STATE_UPDATE, resolve);
    ProfileAction.initProfile(UserStore.state.userId);
  });
}

export async function getBusinessProfile() {
  if (!ProfileStore.state.businessProfileId) {
    await updateProfileStore();
  }

  const { businessName, registrationNumber } = ProfileStore.state;
  return { businessName, registrationNumber };
}
