import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Typography } from '@mui/material';

import LogoImg from 'logo/i-asnaf-act-logo.png';

import { TopContainer, Header } from './styles';

const SellingPointSummary = ({ children }) => (
  <TopContainer>
    <Header spacing={3} justifyContent="center">
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'primary.main'
        }}
      >
        <img
          alt="i-asnaf-ACT"
          src={LogoImg}
          style={{
            maxWidth: '100%',
            width: '18rem',
            height: 'auto',
            objectFit: 'cover',
            objectPosition: 'center'
          }}
        />
      </Box>
      <Typography
        variant="h2"
        fontFamily="Epilogue"
        sx={{ textAlign: 'center' }}
      >
        <span>3-in-1 training tool.</span>
        <br />
        <span>That&apos;s what you need.</span>
      </Typography>
      <Typography
        variant="h6"
        sx={{ textAlign: 'center' }}
      >
        <span>
          i-asnaf-ACT 2.0 is a 3-in-1 traning tool that integrates STOM web-based application,
          Recorded Lectures, Stock Management e-Guidebook. Tested and validated
          by accounting, legal and industry experts, this hybrid educational and training tool
          has been used as the teaching and learning materials in training courses for Asnaf
          entrepreneurs in Selangor, organised in collaboration with&nbsp;
        </span>
        {/* <span>
          A web-based accounting and costing application integrated with online
          training modules comprising pre-recorded lectures and e-Guidebook. Tested and validated
          by accounting, legal and industry experts, this hybrid educational and training tool
          has been used as the teaching and learning materials in training courses for Asnaf
          entrepreneurs in Selangor, organised in collaboration with&nbsp;
        </span> */}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://myteras.com/"
          color="inherit"
          underline="always"
        >
          Teraju Ekonomi Asnaf Sdn Bhd
        </Link>
        <span>
          &nbsp;(TERAS), a non-profit making company wholly owned by&nbsp;
        </span>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.mais.gov.my/"
          color="inherit"
          underline="always"
        >
          Majlis Agama Islam Selangor
        </Link>
        <span>
          &nbsp;(MAIS).
        </span>
      </Typography>
      {children}
    </Header>
  </TopContainer>
);

SellingPointSummary.propTypes = {
  children: PropTypes.node
};

SellingPointSummary.defaultProps = {
  children: undefined
};

export default SellingPointSummary;
