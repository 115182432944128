import * as React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Slide, Stack } from '@mui/material';
import {
  Edit as EditIcon,
  Close as CancelIcon,
  Done as SaveIcon
} from '@mui/icons-material';

import { CircledButton } from './styles';

const Editbar = ({ editMode, onToggleEdit, onSave, anchorSx, ...props }) => {
  const slideAnchor = React.useRef(null);

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" {...props}>
      <Slide direction="left" in={editMode} container={slideAnchor.current}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <CircledButton color="primary" onClick={onSave}>
            <SaveIcon />
          </CircledButton>
          <CircledButton sx={{ ml: 1, color: 'error.light' }} onClick={onToggleEdit}>
            <CancelIcon />
          </CircledButton>
        </Stack>
      </Slide>
      <Stack
        ref={slideAnchor}
        sx={{
          zIndex: 1,
          bgcolor: 'common.white',
          overflow: 'hidden',
          borderRadius: '50% 0 0 50%',
          ...anchorSx
        }}
      >
        <IconButton disabled={editMode} onClick={onToggleEdit}>
          <EditIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

Editbar.propTypes = {
  ...Stack.propTypes,
  editMode: PropTypes.bool,
  anchorSx: Stack.propTypes.sx,
  onToggleEdit: PropTypes.func,
  onSave: PropTypes.func
};

Editbar.defaultProps = {
  editMode: false,
  anchorSx: {},
  onToggleEdit: () => undefined,
  onSave: () => undefined
};

export default Editbar;
