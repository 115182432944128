import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  ListItemText
} from '@mui/material';
import {
  AccountCircle as AccountIcon,
  AppRegistration as RegisterIcon,
  Login as LoginIcon,
  Logout as LogoutIcon,
  Inventory as StockListIcon,
  Person as PersonalIcon
} from '@mui/icons-material';

import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

import { colorFromString, getReadablColor } from 'utils/colors';
import UserAction from 'stux/actions/UserAction';

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { isAuthenticated, username, displayName } = useStux(UserStore, 'isAuthenticated', 'displayName');
  const open = Boolean(anchorEl);

  const onClickAvatar = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  const onClickLogout = (e) => {
    e.preventDefault();
    UserAction.signOut();
  };

  const onClickLogin = (e) => {
    e.preventDefault();
    navigate('/login');
  };

  const onClickRegister = () => {
    navigate('/sign-up');
  };

  const onClickPersonalProfile = () => {
    navigate('/profile');
  };

  const onClickManageStock = () => {
    navigate('/stocks');
  };

  let color;
  let bgcolor;
  const name = displayName || username?.split('@')[0] || null;
  if (name) {
    bgcolor = colorFromString(name);
    color = getReadablColor(bgcolor);
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          size="small"
          aria-haspopup="true"
          aria-controls={open ? 'account-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          sx={{ ml: 2 }}
          onClick={onClickAvatar}
        >
          <Avatar sx={{ border: '2px solid white', width: 32, height: 32, bgcolor, color }}>
            {name?.[0].toLocaleUpperCase() ?? <AccountIcon />}
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        id="account-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        onClick={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isAuthenticated && [
          <MenuItem key="menu1" onClick={onClickPersonalProfile}>
            <ListItemIcon>
              <PersonalIcon />
            </ListItemIcon>
            <ListItemText>Profil saya</ListItemText>
          </MenuItem>,
          <MenuItem key="menu2" onClick={onClickManageStock}>
            <ListItemIcon>
              <StockListIcon />
            </ListItemIcon>
            <ListItemText>Urus Stok</ListItemText>
          </MenuItem>,
          <Divider key="divider1" />,
          <MenuItem key="menu4" onClick={onClickLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Log keluar</ListItemText>
          </MenuItem>
        ]}
        {!isAuthenticated && [
          <MenuItem key="menu5" onClick={onClickLogin}>
            <ListItemIcon>
              <LoginIcon />
            </ListItemIcon>
            <ListItemText>Log masuk</ListItemText>
          </MenuItem>,
          <MenuItem key="menu6" onClick={onClickRegister}>
            <ListItemIcon>
              <RegisterIcon />
            </ListItemIcon>
            <ListItemText>Daftar akaun baharu</ListItemText>
          </MenuItem>
        ]}
      </Menu>
    </>
  );
};

export default AccountMenu;
