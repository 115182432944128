import { initFirestore, resetFirestore } from 'firebaseUtil/db';

import ProfileAction from 'stux/actions/ProfileAction';
import { initProfile, reset, updateBusinessProfile, updatePersonalProfile } from 'stux/data-managers/profiles';

import StatefulStore from './StatefulStore';
import AppDispatcher from '../dispatchers/AppDispatcher';

// TODO: currently CRA not allows decorator
//       see: https://create-react-app.dev/docs/can-i-use-decorators/
// import { storeName } from '../utils/decorators';
// @storeName('ProfileStore')
class ProfileStore extends StatefulStore {
  constructor() {
    super({
      userId: undefined,
      userProfileId: undefined,
      businessProfileId: undefined,
      fullName: undefined,
      sex: undefined,
      personalPhoneNo: undefined,
      businessName: undefined,
      businessPhoneNo: undefined,
      registrationNumber: undefined,
      website: undefined,
      isProcessing: false,
      profileError: 0
    }, false);
    // TODO: remove the below line and use decorator when CRA allows it
    this.constructor.prototype.storeName = 'ProfileStore';
    this._subscribe(AppDispatcher);
  }

  _init() {
    this._registerAction(ProfileAction.INIT_PROFILE, this.__onInitProfile);
    this._registerAction(ProfileAction.RESET, this.__onReset);
    this._registerAction(ProfileAction.UPDATE_PERSONAL, this.__onUpdatePersonal);
    this._registerAction(ProfileAction.UPDATE_BUSINESS, this.__onUpdateBusiness);
  }

  __onReset = () => {
    resetFirestore();
    reset();
    this._updateState({
      userId: undefined,
      userProfileId: undefined,
      businessProfileId: undefined,
      fullName: undefined,
      sex: undefined,
      personalPhoneNo: undefined,
      businessName: undefined,
      businessPhoneNo: undefined,
      registrationNumber: undefined,
      website: undefined,
      isProcessing: false,
      profileError: 0
    });
  };

  __onInitProfile = ({ userId }) => {
    const state = this._state;
    if (!state.userId || !state.userProfileId || !state.businessProfileId) {
      this._updateState({ isProcessing: true });
      initFirestore();
      initProfile(userId)
        .then((data) => {
          this._updateState({ isProcessing: false, ...data });
        });
    }
  };

  __onUpdatePersonal = ({ fullName, nickname, sex, tel, callback }) => {
    this._updateState({ isProcessing: true });
    updatePersonalProfile(this._state.userProfileId, fullName, sex, tel, nickname, callback)
      .then((data) => {
        this._updateState({ isProcessing: false, ...data });
      });
  };

  __onUpdateBusiness = ({ businessName, registrationNumber, businessPhoneNo, website }) => {
    this._updateState({ isProcessing: true });
    updateBusinessProfile(
      this._state.businessProfileId,
      businessName,
      registrationNumber,
      businessPhoneNo,
      website
    ).then((data) => {
      this._updateState({ isProcessing: false, ...data, businessPhoneNo: data.phoneNumber });
    });
  };
}

const profileStore = new ProfileStore();
export default profileStore;
