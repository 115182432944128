import * as React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { Box, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { useFeedbackMutation } from 'hooks';

import { changeStockName } from 'stux/data-managers/stockList';

import { CommonDialog } from 'pages/commons';

import { NumberTextField } from 'components/inputs/textfields';

const ChangeStocNamekDialog = ({ stockData, stockGroups, onClose, onChangeStockGroup }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [currentStockGroupId, setCurrentStockGroupId] = React.useState(stockData?.stockGroupId || 'no-group');

  React.useEffect(() => {
    setCurrentStockGroupId(stockData?.stockGroupId || 'no-group');
  }, [stockData?.stockGroupId]);

  const queryClient = useQueryClient();
  const mutation = useFeedbackMutation({
    errorMessage: 'Ralat ketika mengubah maklumat stok. Sila cuba lagi.',
    mutationFn: changeStockName,
    onSuccessOrError: () => {
      setIsSubmitting(false);
      onClose();
    },
    onSuccess: (newData) => {
      queryClient.setQueryData(
        ['stockList', newData.userId],
        (prev) => {
          const data = prev.find((item) => item.stockId === newData.stockId);

          const prevStockGroupId = data.stockGroupId ?? '';
          data.stockName = newData.stockName;
          data.stockGroupId = newData.stockGroupId;
          data.daysBeforeExpired = newData.daysBeforeExpired;
          data.unitBeforeRestock = newData.unitBeforeRestock;

          if (prevStockGroupId !== newData.stockGroupId) {
            onChangeStockGroup();
          }
          return prev;
        }
      );

      queryClient.invalidateQueries({
        queryKey: ['stockCard', newData.userId, newData.stockId]
      });

      queryClient.setQueryData(
        ['stockDetail', newData.userId, newData.stockId],
        (prev) => {
          if (!prev) return prev;

          const data = { ...prev.stockData };
          data.stockName = newData.stockName;
          data.stockGroupId = newData.stockGroupId;
          data.daysBeforeExpired = newData.daysBeforeExpired;
          data.unitBeforeRestock = newData.unitBeforeRestock;
          return { ...prev, stockData: data };
        }
      );
    }
  });

  return (
    <CommonDialog
      fullWidth
      open={!!stockData}
      title="Pinda Maklumat Stok"
      formId="form"
      isSubmitting={isSubmitting}
      onClose={onClose}
    >
      <Box
        id="form"
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          if (isSubmitting) return false;

          const data = new FormData(e.target);
          const stockName = data.get('stockName');
          const stockGroupId = data.get('stockGroupId');
          const daysBeforeExpired = Number(data.get('daysBeforeExpired') ?? 5);
          const unitBeforeRestock = Number(data.get('unitBeforeRestock') ?? 50);

          const newStockGroupId = stockGroupId === 'no-group' ? '' : stockGroupId;
          if (stockName === stockData.stockName
            && newStockGroupId === stockData.stockGroupId
            && daysBeforeExpired === stockData.daysBeforeExpired
            && unitBeforeRestock === stockData.unitBeforeRestock
          ) {
            return false;
          }

          setIsSubmitting(true);
          mutation.mutate({
            ...stockData,
            stockName,
            daysBeforeExpired,
            unitBeforeRestock,
            stockGroupId: newStockGroupId
          });
          return true;
        }}
      >
        <TextField
          required
          fullWidth
          label="Nama Stok"
          name="stockName"
          defaultValue={stockData?.stockName ?? ''}
        />
        <TextField
          select
          required
          fullWidth
          label="Kategori"
          name="stockGroupId"
          value={currentStockGroupId}
          onChange={(e) => setCurrentStockGroupId(e.target.value)}
          sx={{ mt: 2 }}
        >
          <MenuItem value="no-group">Tiada Kategori</MenuItem>
          {
            stockGroups.map(({ id, label }) => (
              <MenuItem key={id} value={id}>{label}</MenuItem>
            ))
          }
        </TextField>
        <Stack sx={{ mt: 2 }}>
          <Typography variant="subtitle1" color="rgba(0, 0, 0, 0.6)">
            Bilangan hari sebelum luput:
          </Typography>
          <NumberTextField
            onlyInteger
            onlyPositive
            name="daysBeforeExpired"
            defaultValue={stockData?.daysBeforeExpired ?? 5}
            sx={{ maxWidth: 200 }}
            inputProps={{
              style: { textAlign: 'center' }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  hari dan ke bawah
                </InputAdornment>
              ),
              sx: {
                textAlign: 'center'
              }
            }}
          />
          <Typography variant="caption" color="rgba(0, 0, 0, 0.6)" fontStyle="italic">
            Bilangan hari untuk stok dikira sebagai menghampiri tarikh luput.
            Abaikan jika stok tiada tarikh luput.
          </Typography>
        </Stack>
        <Stack sx={{ mt: 2 }}>
          <Typography variant="subtitle1" color="rgba(0, 0, 0, 0.6)">
            Bilangan baki stok sebelum perlu ditambah kembali:
          </Typography>
          <NumberTextField
            onlyInteger
            onlyPositive
            name="unitBeforeRestock"
            defaultValue={stockData?.unitBeforeRestock ?? 50}
            sx={{ maxWidth: 200 }}
            inputProps={{
              style: { textAlign: 'center' }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  unit dan ke bawah
                </InputAdornment>
              ),
              sx: {
                textAlign: 'center'
              }
            }}
          />
          <Typography variant="caption" color="rgba(0, 0, 0, 0.6)" fontStyle="italic">
            Jika baki stok kurang dari jumlah yang dinyatakan, kad stok tersebut akan
            ditonjolkan dalam senarai kad stok.
          </Typography>
        </Stack>
      </Box>
    </CommonDialog>
  );
};

ChangeStocNamekDialog.propTypes = {
  stockGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string
  })).isRequired,
  stockData: PropTypes.shape({
    stockId: PropTypes.string,
    stockGroupId: PropTypes.string,
    stockName: PropTypes.string,
    daysBeforeExpired: PropTypes.number,
    unitBeforeRestock: PropTypes.number
  }),
  onClose: PropTypes.func.isRequired,
  onChangeStockGroup: PropTypes.func.isRequired
};

ChangeStocNamekDialog.defaultProps = {
  stockData: undefined
};

export default ChangeStocNamekDialog;
