import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { SentimentVeryDissatisfied as EmptyIcon } from '@mui/icons-material';

const EmptyList = ({ messages, children, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      flex: '1 1 100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      p: 4
    }}
    {...props}
  >
    <EmptyIcon sx={{ opacity: 0.3, fontSize: '8rem', color: 'primary.main' }} />
    {
      messages.map((message) => (
        <Typography key={message} variant="subtitle2">{message}</Typography>
      ))
    }
    {children}
  </Box>
);

EmptyList.propTypes = {
  ...Box.propTypes,
  messages: PropTypes.arrayOf(PropTypes.string)
};

EmptyList.defaultProps = {
  messages: []
};

export default EmptyList;
