export function updateTransactionQueries(queryClient, newData) {
  queryClient.setQueryData(
    ['stockSummary', newData.userId, newData.stockListId],
    (prev) => {
      if (!prev) return prev;
      return { ...prev, balance: prev.balance + newData.data.newTransaction.quantity };
    }
  );

  queryClient.setQueryData(
    ['stockCard', newData.userId, newData.stockListId, newData.stockCardId],
    (prev) => {
      if (!prev) return prev;

      const stockCardData = { ...prev.stockCardData };
      stockCardData.transactions = [...stockCardData.transactions, newData.data.newTransaction];
      stockCardData.transactions.sort((a, b) => (
        a.transactionDate - b.transactionDate
      ));
      return { ...prev, stockCardData };
    }
  );

  queryClient.setQueryData(
    ['stockDetail', newData.userId, newData.stockListId],
    (prev) => {
      if (!prev) return prev;

      const { newExpired, newNearExpiry, newTransaction } = newData.data;
      const newStockCards = prev.stockCards.map((s) => {
        if (s.stockCardId !== newData.stockCardId) return s;
        return {
          ...s,
          balance: s.balance + newTransaction.quantity,
          expired: s.expired + newExpired,
          nearExpiry: s.nearExpiry + newNearExpiry,
          transactions: [...s.transactions, newTransaction]
        };
      });

      return { ...prev, stockCards: newStockCards };
    }
  );
}

export function deleteTransactionFromQueries(queryClient, newData) {
  queryClient.setQueryData(
    ['stockSummary', newData.userId, newData.stockListId],
    (prev) => {
      if (!prev) return prev;
      return { ...prev, balance: prev.balance - newData.data.quantity };
    }
  );

  queryClient.setQueryData(
    ['stockCard', newData.userId, newData.stockListId, newData.stockCardId],
    (prev) => {
      if (!prev) return prev;

      const { id } = newData.data;
      const stockCardData = { ...prev.stockCardData };
      const newTransactions = stockCardData.transactions.filter((item) => item.id !== id);
      stockCardData.transactions = newTransactions;
      return { ...prev, stockCardData };
    }
  );

  queryClient.setQueryData(
    ['stockDetail', newData.userId, newData.stockListId],
    (prev) => {
      if (!prev) return prev;

      const { quantity, expired, nearExpiry, id } = newData.data;
      const newStockCards = prev.stockCards.map((s) => {
        if (s.stockCardId !== newData.stockCardId) return s;

        const newTransactions = s.transactions.filter((item) => item.id !== id);
        return {
          ...s,
          balance: s.balance - quantity,
          expired: s.expired - expired,
          nearExpiry: s.nearExpiry - nearExpiry,
          transactions: newTransactions
        };
      });

      return { ...prev, stockCards: newStockCards };
    }
  );
}

export function editTransactionFromQueries(queryClient, newData) {
  queryClient.setQueryData(
    ['stockSummary', newData.userId, newData.stockListId],
    (prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        balance: prev.balance - newData.data.oldQuantity + newData.data.newQuantity
      };
    }
  );

  queryClient.setQueryData(
    ['stockCard', newData.userId, newData.stockListId, newData.stockCardId],
    (prev) => {
      if (!prev) return prev;

      const stockCardData = { ...prev.stockCardData };
      const { transactionData } = newData.data;
      const { id } = transactionData;
      const newTransactions = stockCardData.transactions.map((t) => (
        (t.id !== id) ? t : transactionData
      ));
      newTransactions.sort((a, b) => (
        a.transactionDate - b.transactionDate
      ));
      stockCardData.transactions = newTransactions;
      return { ...prev, stockCardData };
    }
  );

  queryClient.setQueryData(
    ['stockDetail', newData.userId, newData.stockListId],
    (prev) => {
      if (!prev) return prev;

      const {
        oldQuantity,
        oldExpired,
        oldNearExpiry,
        newQuantity,
        newExpired,
        newNearExpiry,
        transactionData
      } = newData.data;
      const { id } = transactionData;
      const newStockCards = prev.stockCards.map((s) => {
        if (s.stockCardId !== newData.stockCardId) return s;

        const newTransactions = s.transactions.filter((item) => item.id !== id);
        newTransactions.push(transactionData);
        return {
          ...s,
          balance: s.balance - oldQuantity + newQuantity,
          expired: s.expired - oldExpired + newExpired,
          nearExpiry: s.nearExpiry - oldNearExpiry + newNearExpiry,
          transactions: newTransactions
        };
      });

      return { ...prev, stockCards: newStockCards };
    }
  );
}
