import * as React from 'react';

/**
 * Force a React functional component to re-render. This must be used
 * carefully because most time than not, we don't need force update
 * at all
 */
export default function useForceUpdate() {
  // https://stackoverflow.com/questions/46240647/react-how-to-force-a-function-component-to-render
  const [, setValue] = React.useState(0);
  return () => setValue((p) => p + 1);
}
