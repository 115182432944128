import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';

import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

const RequireAuth = ({ unauthenticatedPath, children }) => {
  const location = useLocation();
  const { isIdentified, isAuthenticated } = useStux(UserStore, 'isIdentified', 'isAuthenticated');

  if (!isIdentified) {
    return (
      <Backdrop
        open
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  if (isIdentified && !isAuthenticated) {
    return (
      <Navigate
        replace
        to={unauthenticatedPath}
        state={{ from: location }}
      />
    );
  }

  return children;
};

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
  unauthenticatedPath: PropTypes.string
};

RequireAuth.defaultProps = {
  unauthenticatedPath: '/login'
};

export default RequireAuth;
